import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  wrapper: {
    width: '100%',
    backgroundColor: ({ palette }) => (palette.mode === 'light' ? 'white' : palette.grey[900]),
    height: '100%',
    padding: '25px 15px',
  } as SxProps<Theme>,
  modalText: {
    color: ({ palette }) => (palette.mode === 'light' ? 'text.primary' : 'text.secondary'),
  } as SxProps<Theme>,
  tableRow: {
    boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.1)',
    margin: '0 20px',
  },

  textField: {
    label: {
      '&[data-shrink="false"]': {
        transform: 'translate(14px,10px) scale(1)',
      },
    },
    '& .MuiFormHelperText-contained': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      transform: 'translate(0, 100%)',
    },
    width: '100%',
    marginTop: '10px',
  },
  button: {
    width: '154px',
    height: '31px',
    whiteSpace: 'nowrap',
    marginTop: 0,
    padding: '9px 37px',
  },
  input: {
    pr: '7px',
    py: '3px',
    input: { padding: 1, width: '100%' },
  },
  error: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#BA1212',
    textAlign: 'center',
    height: '19px',
    margin: 'auto',
  },

  colorText: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    color: ({ palette }) => (palette.mode === 'light' ? '#787878' : 'text.secondary'),
  } as SxProps<Theme>,
};

export default styles as IStyles<typeof styles>;
