import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  button: {
    borderRadius: '5px 0 0 5px',
    fontSize: 13,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    mr: '2px',
    maxHeight: '30px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    minHeight: '350px',
  },
};

export default styles as IStyles<typeof styles>;
