import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  button: {
    mt: 3,
    pt: 1,
    pb: 1,
    pr: 3,
    pl: 3,
    textTransform: 'unset',
    lineHeight: 1,
    fontWeight: 'bold',
  },
};

export default styles as IStyles<typeof styles>;
