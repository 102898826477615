import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const defaultStyles = {
  placeholder: {
    position: 'absolute',
    zIndex: 11,
    top: 40,
    left: 0,
    right: 0,
    marginTop: 4,
  } as SxProps<Theme>,
};

const styles = {
  container: {
    mt: '-18px',
    flex: '1 1 auto',
    position: 'relative',
    backgroundColor: 'transparent',
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0',
    },

    '+ .MuiTablePagination-root': {
      minHeight: '68px',
    },
  },
  head: {
    mt: 0,
    th: {
      pt: 1,
      pb: 1,
      pl: 3,
      fontWeight: 'bold',
      borderWidth: 0,
      whiteSpace: 'nowrap',
      backgroundColor: ({ palette }) =>
        `${palette.mode === 'light' ? '#fff' : '#1F1E1E'}!important`,
      borderBottom: '1.5px solid #DDDDDD',
      color: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),
    },
  } as SxProps<Theme>,
  body: {
    borderSpacing: '0 0px',
    td: {
      pl: 3,
      backgroundColor: 'transparent',
      '&:first-child': { borderRadius: '0' },
      '&:last-child': {
        borderRadius: '0',
        position: 'relative',
        padding: 0,
        pl: 3,
      },
    },
  } as SxProps<Theme>,
  hiddenHeaderBody: {
    td: {
      position: 'relative',
      minWidth: 33,
      pt: 3,
      pb: 0,
      borderWidth: 0,
      backgroundColor: 'transparent',
      '&:first-child': { borderRadius: '0' },
      '&:last-child': { borderRadius: '0', position: 'relative', padding: 0 },
    },
  } as SxProps<Theme>,
  row: {
    position: 'relative',
    cursor: 'pointer',

    td: {
      py: 0,
      height: '43px',
    },

    '&:hover': {
      td: {
        backgroundColor: ({ palette }) => (palette.mode === 'light' ? '#F9F9F9' : '#424242'),
      },
    },
  } as SxProps<Theme>,
  active: {
    td: {
      py: 0,
      backgroundColor: ({ palette }) => (palette.mode === 'light' ? '#EDEDED' : palette.grey[800]),
    },
    '&:hover': {
      td: {
        backgroundColor: ({ palette }) =>
          palette.mode === 'light' ? '#EDEDED' : palette.grey[800],
      },
    },
  } as SxProps<Theme>,

  creditPendingWeeks: {
    color: '#FF5C01',
  },

  creditPendingMonth: {
    color: '#A50000',
  },

  input: {
    marginTop: 1,
    input: {
      padding: 1,
      fontSize: 14,
    },
  },
  select: {
    marginTop: 1,
    padding: '7px',
    maxHeight: '36.13px',
    div: {
      padding: '0 32px 0',
    },
    input: {
      padding: 1,
      fontSize: 14,
    },
  },
  loader: {
    position: 'absolute !important',
    right: 0,
    bottom: 2,
    left: 0,
    py: '0 !important',
    mx: '0 !important',
    backgroundColor: 'unset !important',
  },
  blocker: {
    position: 'absolute',
    zIndex: 10,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    cursor: 'wait',
  },
  loaderBody: {
    height: 150,
    width: '100%',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  loaderBox: {
    ...defaultStyles.placeholder,
    transform: 'translate(50%)',
  },
  noDataBox: {
    ...defaultStyles.placeholder,
  },
};

export default styles as IStyles<typeof styles>;
