/* eslint-disable camelcase */
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from '@components/checkout-form/styles';
import { SmallButton } from '@components/index';
import { clearClientSecret, confirmPayment } from '@store/payments/action-creators';

type CheckoutFormProps = {
  close?: any;
  setDialogType?: any;
  setIsLoadingPay?: any;
  setToPayValue?: any;
  useDefaultPaymentMethod: boolean;
  toPayValue: string | number;
  discount: string | number | null | undefined;
};

const CheckoutForm = ({
  close,
  setDialogType,
  setIsLoadingPay,
  setToPayValue,
  useDefaultPaymentMethod,
  toPayValue,
  discount,
}: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null | undefined>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const paymentMethod = useSelector((state) => state.paymentMethod.item.result);
  const [isSavePaymentMethod, setIsSavePaymentMethod] = useState<boolean>(!paymentMethod);
  const creditIntegrationData = useSelector((state) => state.payments.creditIntegrationData.result);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (isLoading && stripe && elements) {
      dispatch(
        confirmPayment(
          useDefaultPaymentMethod,
          stripe,
          elements,
          isSavePaymentMethod,
          setDialogType,
          toPayValue,
          discount,
          setToPayValue,
          setMessage,
        ),
      );

      setIsLoadingPay(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!creditIntegrationData || !creditIntegrationData.clientSecret) {
      return;
    }

    void stripe
      .retrievePaymentIntent(creditIntegrationData.clientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent?.status) {
          case 'succeeded':
            setMessage('Payment succeeded!');
            break;
          case 'processing':
            setMessage('Your payment is processing.');
            break;
          case 'requires_payment_method':
            // setMessage('Your payment was not successful, please try again.');
            setMessage('');
            break;
          default:
            setMessage('Something went wrong.');
            break;
        }
      });

    return () => {
      dispatch(clearClientSecret());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditIntegrationData, stripe]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!stripe || !elements || !creditIntegrationData?.clientSecret) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoadingPay(true);
    setIsLoading(true);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      style={{
        width: '100%',
        height: '100%',
      }}>
      <Box
        sx={{
          ...styles.formContainer,
        }}>
        {Boolean(stripe) && Boolean(creditIntegrationData?.clientSecret) && (
          <>
            <Box
              sx={{
                pointerEvents: useDefaultPaymentMethod ? 'none' : 'auto',
                opacity: useDefaultPaymentMethod ? 0.4 : 1,
              }}>
              <Box sx={styles.paymentElementWrapper}>
                <PaymentElement id="payment-element" options={paymentElementOptions} />
              </Box>
              <Box sx={styles.savePaymentMethodWrapper}>
                <Typography fontSize={{ xs: '14px' }} color="#5C77A7" noWrap>
                  {t('checkoutDialog.savePaymentMethod')}
                </Typography>
                <Switch
                  disabled={useDefaultPaymentMethod}
                  style={{
                    color: useDefaultPaymentMethod ? '#f5f5f5' : '',
                    background: 'transparent',
                  }}
                  checked={isSavePaymentMethod}
                  onChange={(_, checked) => {
                    setIsSavePaymentMethod(checked);
                  }}
                />
              </Box>
            </Box>
            {/* Show any error or success messages */}
            {message && (
              <Typography mt={2.5} color="#BA1212" id="payment-message">
                {message}
              </Typography>
            )}
            <Box sx={styles.buttonsWrapper}>
              <SmallButton
                disabled={isLoading}
                sx={styles.button}
                variant="outlined"
                color="primary"
                onClick={close}>
                {t('cancel')}
              </SmallButton>
              <SmallButton
                sx={styles.button}
                color="primary"
                type="submit"
                isFetching={isLoading}
                disabled={isLoading || !stripe || !elements}>
                {t('pay')}
              </SmallButton>
            </Box>
          </>
        )}
      </Box>
    </form>
  );
};

export default CheckoutForm;
