import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CheckoutForm from '@components/checkout-form';
import StripeElements from '@components/stripe-elements/index.store';
import formatMoney from '@helpers/format-money';
import IState from '@interfaces/redux/i-state';
import PlanType from '../../../../../../../enums/plan-type';
import styles from './styles';

type IProps = {
  discount?: string | number | null;
  toPayValue: string | number;
  isFetchingTax?: any;
  deposit?: any;
  bonus?: any;
  isAccountPlanCustom?: any;
  isPoolPlan?: boolean;
  calculatingResult?: any;
  taxRateName?: string;
  taxRatePercent?: number;
  createCreditIntegration?: any;
  accountPlanName?: PlanType;
  setDialogType?: any;
  close?: any;
  setIsLoadingPay?: any;
  setToPayValue?: any;
};

/**
 * CheckoutDialog
 * @constructor
 */
const CreditCardModal: FC<IProps> = ({
  isFetchingTax,
  discount,
  toPayValue,
  deposit,
  bonus,
  taxRateName,
  taxRatePercent,
  accountPlanName,
  setDialogType,
  setIsLoadingPay,
  createCreditIntegration,
  isAccountPlanCustom,
  isPoolPlan,
  calculatingResult,
  close,
  setToPayValue,
}) => {
  const { t } = useTranslation();
  const isFetching: boolean = useSelector(
    (state: IState) => state.payments.creditIntegrationData.isFetching,
  );

  const paymentMethod = useSelector((state) => state.paymentMethod.item.result);
  const [isUsePaymentMethod, setIsUsePaymentMethod] = useState<boolean>(Boolean(paymentMethod));

  const handleCancel: any = () => {
    close();
  };

  useEffect(() => {
    if (accountPlanName) {
      createCreditIntegration(accountPlanName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountPlanName]);

  return (
    <Box sx={styles.wrapper}>
      {isFetching ? (
        <Box sx={styles.loaderBox}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={styles.container}>
            <Box sx={styles.body}>
              <Typography color="#5C77A7" textAlign="left" fontSize={16}>
                {t('checkoutDialog.funds')}:
                <Typography
                  component="span"
                  color="text.primary"
                  fontSize={18}
                  fontWeight="bold"
                  marginLeft={0.5}>
                  {isAccountPlanCustom ? (
                    <>{formatMoney(deposit)}</>
                  ) : (
                    <>{formatMoney(Number(toPayValue))}</>
                  )}
                </Typography>
              </Typography>
              <Divider sx={styles.divider} />

              <Grid container fontSize={16} marginTop={1} marginBottom={2} spacing={1}>
                {isFetchingTax ? (
                  <Box sx={styles.loaderBox}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Grid item xs={6}>
                      <Typography color="action.active">Subtotal</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="action.active" textAlign="right">
                        {formatMoney(calculatingResult?.subtotal)}
                      </Typography>
                    </Grid>

                    {Boolean(taxRatePercent) && (
                      <>
                        <Grid item xs={6}>
                          <Typography color="action.active">
                            {t('tax')} ({taxRateName}) {taxRatePercent}%
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="action.active" textAlign="right">
                            {formatMoney(calculatingResult?.tax)}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={6}>
                      <Typography color="text.primary" fontWeight="bold">
                        {t('total')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="text.primary" fontWeight="bold" textAlign="right">
                        {formatMoney(calculatingResult.total)}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
              <Divider sx={styles.divider} />
            </Box>

            {isAccountPlanCustom ? (
              Boolean(bonus) && (
                <Typography
                  color="#5C77A7"
                  marginTop={{ xs: 1.5, sm: 3 }}
                  fontSize={{ xs: '14px', sm: '16px' }}>
                  {formatMoney(bonus)} {t('checkoutDialog.creditCardBonusAfterPayment')}
                </Typography>
              )
            ) : (
              <Box>
                {!isPoolPlan && discount !== '0.00' && (
                  <Typography color="text.secondary" marginTop={3}>
                    {formatMoney(Number(discount))} {t('checkoutDialog.discountApplied')}
                  </Typography>
                )}
              </Box>
            )}
            <Box
              sx={{
                marginTop: { xs: 1.5, sm: 3 },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Typography fontSize={{ xs: '14px' }} color="#5C77A7" noWrap>
                {t('checkoutDialog.useDefaultPaymentMethod')}
              </Typography>
              <Switch
                disabled={!paymentMethod}
                style={{
                  color: !paymentMethod ? '#f5f5f5' : '',
                  background: 'transparent',
                }}
                checked={isUsePaymentMethod}
                onChange={(_, checked) => {
                  setIsUsePaymentMethod(checked);
                }}
              />
            </Box>
          </Box>

          <Box sx={styles.containerPay}>
            {isFetching ? (
              <Box sx={styles.loaderBox}>
                <CircularProgress />
              </Box>
            ) : (
              <StripeElements
                useGlobalStripe={false}
                children={
                  <CheckoutForm
                    close={handleCancel}
                    setDialogType={setDialogType}
                    setIsLoadingPay={setIsLoadingPay}
                    setToPayValue={setToPayValue}
                    useDefaultPaymentMethod={isUsePaymentMethod}
                    discount={discount}
                    toPayValue={toPayValue}
                  />
                }
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default CreditCardModal;
