import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  title: {
    fontSize: '20px',
    backgroundColor: (theme) => theme.palette.primary.main,
    color: (theme) => (theme.palette.mode === 'light' ? 'white' : theme.palette.background.default),
    borderRadius: '0',
    pt: '7px',
    pb: '7px',
    pl: 3,
    pr: 3,
    mb: 0,
  } as SxProps<Theme>,
  box: {
    p: 2,
    width: '100%',
    borderRadius: 0,
  },
  margin: { mt: 4 },
};

export default styles as IStyles<typeof styles>;
