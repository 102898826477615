enum PAYMENT_ACTION_TYPES {
  GET_PAYMENTS_LIST = 'GET_PAYMENTS_LIST',
  GET_PAYMENTS_LIST_SUCCESS = 'GET_PAYMENTS_LIST_SUCCESS',
  GET_PAYMENTS_LIST_ERROR = 'GET_PAYMENTS_LIST_ERROR',
  CLEAR_PAYMENTS_LIST = 'CLEAR_PAYMENTS_LIST',
  UPDATE_PAYMENTS_LIST_PAGINATION = 'UPDATE_PAYMENTS_LIST_PAGINATION',
  UPDATE_PAYMENTS_LIST_SORTING = 'UPDATE_PAYMENTS_LIST_SORTING',
  UPDATE_PAYMENTS_LIST_DATES = 'UPDATE_PAYMENTS_LIST_DATES',

  UPDATE_PAYMENT = 'UPDATE_PAYMENT',
  UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS',
  UPDATE_PAYMENT_ERROR = 'UPDATE_PAYMENT_ERROR',

  DELETE_PAYMENT = 'DELETE_PAYMENT',
  DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS',
  DELETE_PAYMENT_ERROR = 'DELETE_PAYMENT_ERROR',

  ADD_PAYMENTS = 'ADD_PAYMENTS',
  ADD_PAYMENTS_SUCCESS = 'ADD_PAYMENTS_SUCCESS',
  ADD_PAYMENTS_ERROR = 'ADD_PAYMENTS_ERROR',

  CONFIRM_PAYMENT = 'CONFIRM_PAYMENT',

  CREATE_CREDIT_INTEGRATION = 'CREATE_CREDIT_INTEGRATION',
  CREATE_CREDIT_INTEGRATION_SUCCESS = 'CREATE_CREDIT_INTEGRATION_SUCCESS',

  LISTEN_LAST_PAYMENT_START = 'LISTEN_LAST_PAYMENT_START',
  LISTEN_LAST_PAYMENT_SUCCESS = 'LISTEN_LAST_PAYMENT_SUCCESS',
  LISTEN_LAST_PAYMENT_ERROR = 'LISTEN_LAST_PAYMENT_ERROR',
  LISTEN_LAST_PAYMENT_STOP = 'LISTEN_LAST_PAYMENT_STOP',
  CALL_LISTEN_LAST_PAYMENT = 'CALL_LISTEN_LAST_PAYMENT',

  GET_TAX_INFO = 'GET_TAX_INFO',
  GET_TAX_INFO_SUCCESS = 'GET_TAX_INFO_SUCCESS',
  GET_TAX_INFO_ERROR = 'GET_TAX_INFO_ERROR',

  UPDATE_STATUS_PAY_CREDIT = 'UPDATE_STATUS_PAY_CREDIT',
  SET_TYPE_LISTENER = 'SET_TYPE_LISTENER',

  CLEAR_CLIENT_SECRET = 'CLEAR_CLIENT_SECRET',
}

export default PAYMENT_ACTION_TYPES;
