import type { SagaIterator } from '@redux-saga/core';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import { onUpdateEssenceSuccess, onUpdateListFork } from '@helpers/sagas';
import type { IAction } from '@interfaces/redux/i-action';
import notistack from '@services/notistack-service';
import { setPaymentTypeSuccess } from '@store/cards/action-creators';
import { forgotPassword } from '@store/forgot/api';
import { getSubscriptionPrices } from '@store/subscriptions/action-creators';
import PlanType from '../../enums/plan-type';
import RoleType from '../../enums/role-type';
import i18n from '../../i18n';
import { awaitUserToken } from '../tenant/sagas';
import {
  addAccountError,
  addAccountSuccess,
  deleteAccountError,
  deleteAccountSuccess,
  getAccountError,
  getAccountsList,
  getAccountsListError,
  getAccountsListSuccess,
  getAccountSuccess,
  updateAccountError,
  updateAccountSuccess,
  updateAutoChargeSuccess,
} from './action-creators';
import TYPES from './action-types';
import {
  createAccountApi,
  deleteAccountApi,
  getAccountApi,
  getAccountsListApi,
  updateAccountApi,
  updateAutoChargeApi,
} from './api';
import type { IAccountsState } from './reducer';

/**
 * Get accounts list
 */
function* getAccounts(): SagaIterator {
  try {
    yield call(awaitUserToken);
    const userRoles: RoleType[] = yield select((state) => state.tenant.claims?.roles);
    const tenantId = yield select((state) => state.tenant.tenantId);
    const isAllowedForAdmin = isAllowedForUser([RoleType.Admin], userRoles);

    if (isAllowedForAdmin) {
      const pagination: IAccountsState['list']['pagination'] = yield select(
        (state) => state.accounts.list.pagination,
      );
      const locationId: string = yield select((state) => state.accounts.list.locationId);
      const search: string = yield select((state) => state.accounts.list.searchValue);
      const sorting = yield select((state) => state.accounts.list?.sorting);
      const [sort, isDesc] = Object.entries(sorting ?? {})?.[0] ?? [];

      const result = yield call(getAccountsListApi, tenantId, {
        page: pagination?.page ?? 1,
        size: pagination.size,
        relations: ['location'],
        lastPage: 1,
        currentPage: 1,
        ...(sort !== 'undefined' && { sort, order: isDesc ? 'Descending' : 'Ascending' }),
        ...(search && { search: `%${search}%` }),
        ...(locationId && { locationId }),
      });

      const isCurrentPageMoreThenLastPage =
        Number(result.lastPage) && Number(pagination.page) > Number(result.lastPage);

      yield put(
        getAccountsListSuccess(result.data, {
          page: isCurrentPageMoreThenLastPage ? 1 : Number(result.currentPage),
          total: result.count,
          size: pagination.size,
        }),
      );

      if (isCurrentPageMoreThenLastPage) {
        yield put(getAccountsList());
      }
    }
  } catch (e) {
    yield put(getAccountsListError(e.message));
  }
}

/**
 * Get account
 */
function* getAccount({ payload: { id } }: IAction<TYPES>): SagaIterator {
  try {
    yield call(awaitUserToken);
    const tenantId = yield select((state) => state.tenant.tenantId);
    const account = yield call(getAccountApi, tenantId, id);

    yield put(getAccountSuccess(account));

    if (account.accountPlan.plan.name === PlanType.Pool) {
      yield put(getSubscriptionPrices());
    }
  } catch (e) {
    yield put(getAccountError(e.message));
  }
}

/**
 * Update account
 */
function* updateAccount({
  payload: { id, newData, successCallback },
}: IAction<TYPES>): SagaIterator {
  try {
    const account: IAccountsState['item']['result'] = yield select(
      (state) => state.accounts.item.result,
    );
    const tenantId = yield select((state) => state.tenant.tenantId);
    const isEnableUserPortal: boolean | undefined = yield select(
      (state) => state.settings.result?.settings.enableUserPortal,
    );

    yield call(updateAccountApi, tenantId, id, newData);

    if (!account?.email && newData.email && isEnableUserPortal) {
      yield call(forgotPassword, newData.email);
    }

    yield put(updateAccountSuccess({ ...account, ...newData }));
    successCallback?.();
    yield put(setPaymentTypeSuccess()); // set to default
    notistack.success(`${i18n.t('account')} #${String(id)} ${i18n.t('successfullyUpdated')}`);
  } catch (e) {
    yield put(updateAccountError(e.message));
  }
}

/**
 * Delete account
 */
function* deleteAccount({ payload: { id, successCallback } }: IAction<TYPES>): SagaIterator {
  try {
    const tenantId = yield select((state) => state.tenant.tenantId);

    yield call(deleteAccountApi, tenantId, id);
    yield put(deleteAccountSuccess());
    yield put(getAccountsList());
    successCallback?.();
  } catch (e) {
    yield put(deleteAccountError(e.message));
  }
}

/**
 * Add account
 */
function* addAccount({
  payload: { item, successCallback, isSendPasswordEmail },
}: IAction<TYPES>): SagaIterator {
  try {
    const tenantId = yield select((state) => state.tenant.tenantId);
    const account = yield call(createAccountApi, tenantId, { ...item, tenantId });
    const isUserPortalEnable: boolean | undefined = yield select(
      (state) => state.settings.result?.settings.enableUserPortal,
    );

    if (account.email && isUserPortalEnable && isSendPasswordEmail) {
      yield call(forgotPassword, account.email);
    }

    if (account.id) {
      yield put(addAccountSuccess());
      successCallback?.(account.id);
    }
  } catch (e) {
    yield put(addAccountError(e.message));
  }
}

function* updateAutoCharge({ payload: { accountId, autoCharge } }: IAction<TYPES>): SagaIterator {
  try {
    yield call(updateAutoChargeApi, accountId, autoCharge);
    yield put(updateAutoChargeSuccess(autoCharge));
  } catch (e) {
    notistack.error(e.message);
  }
}

/**
 * On update account success
 */
function* onUpdateAccount(): SagaIterator {
  yield call(onUpdateEssenceSuccess, 'accounts', TYPES.UPDATE_ACCOUNT_SUCCESS, getAccountsList);
}

/**
 * On update pagination
 */
function* onUpdatePagination(): SagaIterator {
  yield call(
    onUpdateListFork,
    'accounts',
    'pagination',
    TYPES.UPDATE_ACCOUNTS_LIST_PAGINATION,
    getAccountsList,
  );
}

/**
 * On update search
 */
function* onUpdateSearch(): SagaIterator {
  yield call(
    onUpdateListFork,
    'accounts',
    'searchValue',
    TYPES.UPDATE_ACCOUNTS_SEARCH,
    getAccountsList,
  );
}

function* onUpdateSearchByLocation(): SagaIterator {
  yield call(
    onUpdateListFork,
    'accounts',
    'locationId',
    TYPES.UPDATE_ACCOUNTS_SEARCH_BY_LOCATION,
    getAccountsList,
  );
}

/**
 * On update sorting
 */
function* onUpdateSorting(): SagaIterator {
  yield call(
    onUpdateListFork,
    'accounts',
    'sorting',
    TYPES.UPDATE_ACCOUNTS_SORTING,
    getAccountsList,
  );
}

export default [
  takeLatest(TYPES.GET_ACCOUNTS_LIST, getAccounts),
  takeLatest(TYPES.GET_ACCOUNT, getAccount),
  takeLatest(TYPES.UPDATE_ACCOUNT, updateAccount),
  takeLatest(TYPES.DELETE_ACCOUNT, deleteAccount),
  takeLatest(TYPES.ADD_ACCOUNT, addAccount),
  takeLatest(TYPES.UPDATE_AUTO_CHARGE, updateAutoCharge),
  fork(onUpdateAccount),
  fork(onUpdatePagination),
  fork(onUpdateSearch),
  fork(onUpdateSorting),
  fork(onUpdateSearchByLocation),
];
