import type { SagaIterator } from '@redux-saga/core';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { IAction } from '@interfaces/redux/i-action';
import notistack from '@services/notistack-service';
import {
  getTenantBillingDataSuccess,
  updateTenantBillingDataSuccess,
} from '@store/tenant-billing/action-creators';
import { getTenantBillingDataApi, updateTenantBillingDataApi } from '@store/tenant-billing/api';
import TYPES from './action-types';

/**
 * Authenticate user
 */
function* getTenantBillingDataSaga(): SagaIterator {
  try {
    const data = yield call(getTenantBillingDataApi);

    yield put(getTenantBillingDataSuccess(data));
  } catch (e) {
    notistack.error(e.message);
  }
}

function* updateTenantBillingDataSaga({ payload: { email } }: IAction<TYPES>): SagaIterator {
  try {
    yield call(updateTenantBillingDataApi, email);
    yield put(updateTenantBillingDataSuccess(email));
    notistack.success('Email successfully updated');
  } catch (e) {
    notistack.error(e.message);
  }
}

export default [
  takeLatest(TYPES.GET_TENANT_BILLING_DATA, getTenantBillingDataSaga),
  takeLatest(TYPES.UPDATE_TENANT_BILLING_DATA, updateTenantBillingDataSaga),
];
