import { ICard } from '@interfaces/cards/i-card';
import PAYMENT_TYPE from '@interfaces/cards/payment-type';
import { IAction } from '@interfaces/redux/i-action';
import { IPagination } from '@interfaces/redux/i-pagination';
import TYPES from './action-types';
import { ICardsState } from './reducer';

/**
 * Get cards list
 */
export const getCardsList = (): IAction<TYPES> => ({
  type: TYPES.GET_CARDS_LIST,
  payload: {},
});

/**
 * Get cards list success
 */
export const getCardsListSuccess = (
  list: ICardsState['list']['result'],
  pagination?: IPagination,
): IAction<TYPES> => ({
  type: TYPES.GET_CARDS_LIST_SUCCESS,
  payload: { list, pagination },
});

/**
 * Get cards list error
 */
export const getCardsListError = (message: string): IAction<TYPES> => ({
  type: TYPES.GET_CARDS_LIST_ERROR,
  payload: { message },
});

/**
 * Update cards list pagination
 */
export const updateCardsListPagination = (pagination: Partial<IPagination>): IAction<TYPES> => ({
  type: TYPES.UPDATE_CARDS_LIST_PAGINATION,
  payload: { pagination },
});

/**
 * Update cards search value
 */
export const updateCardsSearch = (value: string): IAction<TYPES> => ({
  type: TYPES.UPDATE_CARDS_SEARCH,
  payload: { value },
});

/**
 * Update cards sorting
 */
export const updateCardsSorting = (name?: string, desc?: boolean): IAction<TYPES> => ({
  type: TYPES.UPDATE_CARDS_SORTING,
  payload: { name, desc },
});

/**
 * Clear cards list
 */
export const clearCardsList = (): IAction<TYPES> => ({
  type: TYPES.CLEAR_CARDS_LIST,
  payload: {},
});

/**
 * Update card
 */
export const updateCard = (
  id: string,
  newData: Partial<ICard>,
  successCallback?: () => void,
): IAction<TYPES> => ({
  type: TYPES.UPDATE_CARD,
  payload: { id, newData, successCallback },
});

/**
 * Update card success
 */
export const updateCardSuccess = (item: ICard): IAction<TYPES> => ({
  type: TYPES.UPDATE_CARD_SUCCESS,
  payload: { item },
});

/**
 * Update card error
 */
export const updateCardError = (message: string): IAction<TYPES> => ({
  type: TYPES.UPDATE_CARD_ERROR,
  payload: { message },
});

/**
 * Delete card
 */
export const deleteCard = (id: string, successCallback?: () => void): IAction<TYPES> => ({
  type: TYPES.DELETE_CARD,
  payload: { id, successCallback },
});

/**
 * Delete card success
 */
export const deleteCardSuccess = (): IAction<TYPES> => ({
  type: TYPES.DELETE_CARD_SUCCESS,
  payload: {},
});

/**
 * Delete card error
 */
export const deleteCardError = (message: string): IAction<TYPES> => ({
  type: TYPES.DELETE_CARD_ERROR,
  payload: { message },
});

/**
 * Add card
 */
export const addCard = (id: string, successCallback?: () => void): IAction<TYPES> => ({
  type: TYPES.ADD_CARD,
  payload: { id, successCallback },
});

/**
 * Add card success
 */
export const addCardSuccess = (): IAction<TYPES> => ({
  type: TYPES.ADD_CARD_SUCCESS,
  payload: {},
});

/**
 * Add card error
 */
export const addCardError = (message: string): IAction<TYPES> => ({
  type: TYPES.ADD_CARD_ERROR,
  payload: { message },
});

/**
 * Add cards
 */
export const addCards = (ids: string[], successCallback?: () => void): IAction<TYPES> => ({
  type: TYPES.ADD_CARDS,
  payload: { ids, successCallback },
});

/**
 * Add cards success
 */
export const addCardsSuccess = (): IAction<TYPES> => ({
  type: TYPES.ADD_CARDS_SUCCESS,
  payload: {},
});

/**
 * Add cards error
 */
export const addCardsError = (message: string): IAction<TYPES> => ({
  type: TYPES.ADD_CARDS_ERROR,
  payload: { message },
});

/**
 * Nayax cards sync
 */
export const nayaxCardsSync = (): IAction<TYPES> => ({
  type: TYPES.NAYAX_CARDS_SYNC,
  payload: {},
});

/**
 * Nayax cards sync success
 */
export const nayaxCardsSyncSuccess = (): IAction<TYPES> => ({
  type: TYPES.NAYAX_CARDS_SYNC_SUCCESS,
  payload: {},
});

/**
 * Nayax cards sync error
 */
export const nayaxCardsSyncError = (message: string): IAction<TYPES> => ({
  type: TYPES.NAYAX_CARDS_SYNC_ERROR,
  payload: { message },
});

/**
 * Toggle show inactive
 */
export const toggleShowInactive = (): IAction<TYPES> => ({
  type: TYPES.TOGGLE_SHOW_INACTIVE,
  payload: {},
});

/**
 * Set payment type
 */
export const setPaymentType = (type: PAYMENT_TYPE): IAction<TYPES> => ({
  type: TYPES.SET_PAYMENT_TYPE,
  payload: { type },
});

/**
 * Set payment type success
 */
export const setPaymentTypeSuccess = (type = PAYMENT_TYPE.AT_COUNTER): IAction<TYPES> => ({
  type: TYPES.SET_PAYMENT_TYPE_SUCCESS,
  payload: { type },
});

/**
 * Set transfer another card
 */
export const transferAnotherCard = (payload: {
  accountId: string | undefined;
  toCardId: string;
  fromCardId: string;
}): IAction<TYPES> => ({
  type: TYPES.TRANSFER_ANOTHER_CARD,
  payload,
});

export const transferAnotherCardResponse = (type: TYPES): IAction<TYPES> => ({
  type: TYPES.TRANSFER_ANOTHER_CARD_RESPONSE,
  payload: { type },
});
