import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { addCards } from '@store/cards/action-creators';
import {
  getNewCardsList,
  updateNewCardsSearch,
  handleClearSearch,
} from '@store/new-cards/action-creators';

export const mapStateToProps = (state: IState) =>
  ({
    cards: state.newCards.list.result,
    isFetchingList: state.newCards.list.isFetching,
    isFetchingAdd: state.cards.add.isFetching,
    isWaitingPayment: state.payments.listen.result,
  } as const);

export const mapDispatchToProps = {
  addCards,
  getNewCardsList,
  updateNewCardsSearch,
  handleClearSearch,
};

export type ICardsAutocompleteStateToProps = ReturnType<typeof mapStateToProps>;

export type ICardsAutocompleteDispatchToProps = ReplacePropertiesReturnType<
  typeof mapDispatchToProps,
  void
>;
