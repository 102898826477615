import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TitledBlock, SmallButton } from '@components/index';
import type { ICardTransferStateToProps, ICardTransferDispatchToProps } from './index.props';
import styles from './styles';

type IProps = ICardTransferStateToProps & ICardTransferDispatchToProps & { cardName: string };

const ModalTransferBonusCard: FC<IProps> = ({
  getBonusCardBalance,
  balance,
  applyCard,
  cardName,
  applyBonusCard,
  bonusCardClear,
}) => {
  const { t } = useTranslation();
  const [bonusCard, setBonusCard] = useState('');

  useEffect(() => {
    bonusCardClear();

    return () => {
      bonusCardClear();
    };
    // eslint-disable-next-line
  }, []);

  const handleChangeCard = (e: any) => {
    setBonusCard(e.target.value);
    bonusCardClear();
  };

  const handleApplyCard = () => {
    applyBonusCard({ cardName, bonusCard });
  };

  return (
    <Box>
      <TitledBlock title={`${t('modalTransferBonusCard.title')}`}>
        <Box
          width={'100%'}
          height={'163px'}
          alignItems="center"
          flexDirection="column"
          display="flex">
          <TextField
            sx={styles.textField}
            onChange={(e) => handleChangeCard(e)}
            value={bonusCard}
            InputProps={{
              sx: styles.input,
              endAdornment: (
                <SmallButton
                  sx={styles.button}
                  onClick={() => getBonusCardBalance(bonusCard)}
                  disabled={!bonusCard}
                  isFetching={applyCard.isFetching}>
                  {t('search')}
                </SmallButton>
              ),
            }}
            label={`${t('modalTransferBonusCard.externalCardNumber')}`}
          />
          <Typography sx={styles.error}>{applyCard.error ? applyCard.error : ' '}</Typography>
          {!applyCard.result.isUsed && balance.result.credit && (
            <Box alignItems="center" width={'100%'}>
              <Typography sx={styles.colorText}>
                {t('balance')}:{' '}
                <span style={{ fontWeight: 'bold' }}>${balance?.result.credit}</span>
              </Typography>
              <Box sx={{ justifyContent: 'space-between', display: 'flex', mt: 2.7 }}>
                <SmallButton
                  variant="outlined"
                  color="primary"
                  sx={styles.button}
                  onClick={close}
                  disabled={!bonusCard}
                  isFetching={applyCard.isFetching}>
                  {t('cancel')}
                </SmallButton>

                <SmallButton
                  sx={styles.button}
                  onClick={handleApplyCard}
                  disabled={!bonusCard}
                  isFetching={applyCard.isFetching}>
                  {t('modalTransferBonusCard.transfer')}
                </SmallButton>
              </Box>
            </Box>
          )}
          {applyCard.result.isUsed && (
            <Box width={'100%'} display="flex" justifyContent="center" textAlign={'center'}>
              <Typography sx={styles.colorText}>
                {t('modalTransferBonusCard.balanceSuccessfullyTransferred', {
                  credit: balance?.result.credit,
                })}
              </Typography>
            </Box>
          )}
        </Box>
      </TitledBlock>
    </Box>
  );
};

export default ModalTransferBonusCard;
