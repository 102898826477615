import { Box } from '@mui/material';
import React, { FC } from 'react';
import { TransferFundsBlock } from '@components/index';
import styles from '@pages/transfer-funds/styles';

const TransferFunds: FC = () => (
  <Box sx={styles.wrapper}>
    <TransferFundsBlock />
  </Box>
);

export default TransferFunds;
