import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  container: {
    position: 'relative',
    display: 'inline-flex',
  },
  box: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default styles as IStyles<typeof styles>;
