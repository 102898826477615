import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { endOfDay, isValid, startOfDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

interface IBasicDateRangePicker {
  onChange: (startDate: string | null, endDate: string | null, cardId?: string) => void;
  cardIdForTransactions?: string;
  titleFrom?: string;
  titleTo?: string;
  sxRow?: any;
  sxContainer?: any;
  isClearDates?: boolean;
}

/**
 * BasicDateRangePicker
 * @constructor
 */
const BasicDateRangePicker: FC<IBasicDateRangePicker> = ({
  onChange,
  cardIdForTransactions,
  titleFrom = 'basicDateRangePicker.periodFrom',
  titleTo = 'basicDateRangePicker.to',
  sxRow,
  sxContainer,
  isClearDates,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (isClearDates) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [isClearDates]);

  /**
   * Render date input
   */
  const renderInput = useCallback((params: TextFieldProps) => <TextField {...params} />, []);

  /**
   * Format date to return from component
   */
  const formatDate = (date: Date | null) => {
    if (!date || !isValid(date) || `${date.getFullYear()}`.length < 4) {
      return null;
    }

    return formatInTimeZone(date, 'UTC', 'yyyy-MM-dd HH:mm:ss');
  };

  /**
   * On change
   */
  useEffect(
    () =>
      onChange(
        formatDate(startOfDay(startDate as Date)),
        formatDate(endOfDay(endDate as Date)),
        cardIdForTransactions,
      ),
    [startDate, onChange, endDate, cardIdForTransactions],
  );

  return (
    <Box sx={{ ...styles.container, ...(sxContainer ?? ({} as any)) }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ ...styles.row, ...(sxRow ?? ({} as any)) }} style={{ marginLeft: 0 }}>
          <Typography sx={styles.text}>{t(titleFrom)}</Typography>
          <DatePicker value={startDate} onChange={setStartDate} renderInput={renderInput} />
        </Box>
        <Box sx={{ ...styles.row, ...(sxRow ?? ({} as any)) }}>
          <Typography sx={styles.text}>{t(titleTo)}</Typography>
          <DatePicker
            value={endDate}
            minDate={startDate ?? undefined}
            onChange={setEndDate}
            renderInput={renderInput}
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
};

export default BasicDateRangePicker;
