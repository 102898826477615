import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import ExportToExcel from '@components/create-excel/create-excel';
import BasicDateRangePicker from '@components/date-range-picker';
import { Modal } from '@components/index';
import generateExcelOptions from '@helpers/generate-excel-options';
import SaveMode from '../../../enums/save-mode';
import ReportType from '../../../enums/type-reports';
import { IGetReportModalDispatchToProps, IGetReportModalToProps } from './index.props';
import styles from './styles';

type IProps = IGetReportModalToProps &
  IGetReportModalDispatchToProps & {
    modalRef: any;
    toggleModal: any;
    title: string;
    reportType: ReportType;
  };

const GetReportModal: FC<IProps> = ({
  account,
  dates,
  modalRef,
  toggleModal,
  title,
  updateTransactionListDatesWithoutRefetch,
  reportType,
}) => {
  const isConditionForExcelBtn =
    dates?.startDate &&
    dates?.endDate &&
    Date.parse(dates?.endDate) >= Date.parse(dates?.startDate);

  const handleCloseModal = () => {
    toggleModal();
  };

  return (
    <Modal ref={modalRef} customStyle={styles.modalCustomStyle} height={'auto'} withoutTitle>
      <Box px={1}>
        <Typography sx={styles.title} variant="h6" component="h2">
          {title}
        </Typography>
        <BasicDateRangePicker onChange={updateTransactionListDatesWithoutRefetch} />
        <Box sx={styles.container}>
          {account && (
            <ExportToExcel
              options={generateExcelOptions(reportType, dates, account)}
              disabled={!isConditionForExcelBtn}
              onFinish={handleCloseModal}
              saveMode={SaveMode.OnClick}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default GetReportModal;
