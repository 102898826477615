enum TransactionType {
  atCounter = 'Credit Change (Counter)',
  creditCard = 'Credit Change (CC)',
  sale = 'Sale',
  manualReload = 'Manual reload',
  bonus = 'Bonus',
  adjustment = 'Adjustment',
}

export default TransactionType;
