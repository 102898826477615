import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import styles from './styles';

interface ITitledBlock {
  title: string;
  withMargin?: boolean;
  fullHeight?: boolean;
  styleChildren?: any;
  styleTitle?: any;
  sxWrapper?: any;
}

/**
 * TitledBlock
 * @constructor
 */
const TitledBlock: FC<ITitledBlock> = ({
  title,
  withMargin,
  fullHeight,
  children,
  styleChildren = '',
  styleTitle = '',
  sxWrapper,
}) => (
  <Box sx={{ height: fullHeight ? '89%' : '', ...sxWrapper }}>
    <Typography
      variant="h6"
      gutterBottom
      sx={{ ...styles.title, ...(withMargin ? styles.margin : ({} as any)), ...styleTitle }}>
      {title}
    </Typography>

    <Box sx={{ ...styles.box, height: fullHeight ? '100%' : '', ...styleChildren }}>{children}</Box>
  </Box>
);

export default TitledBlock;
