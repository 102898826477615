import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  box: {
    py: 0.5,
    minHeight: 282, // @todo mocked height
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
  },

  boxRunReport: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    mt: 3.5,
    justifyContent: 'space-evenly',

    ['@media (min-width:899px) and (max-width:1060px)']: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      marginTop: 0.5,
    },

    ['@media (max-width:560px)']: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      marginTop: 0.5,
    },
    '& > div': {
      whiteSpace: 'nowrap',
      marginRight: 2,
    },
  },

  sxAutocomplete: {
    width: '100%',
    textAlign: 'center',
  },
  sxAutocompleteTextField: {
    '&:placeholder': {
      color: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),
    } as SxProps<Theme>,
  },

  btn: {
    marginTop: 0,
    whiteSpace: 'nowrap',
  },

  sxContainer: {
    ['@media (min-width:899px) and (max-width:1060px)']: {
      marginTop: 0,
      flexDirection: 'column',
      alignItems: 'start',
    },

    ['@media (max-width:560px)']: {
      marginTop: 0,
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  sxRow: {
    ['@media (min-width:899px) and (max-width:1060px)']: {
      marginLeft: 0,
      marginBottom: 2,
    },

    ['@media (max-width:560px)']: {
      marginLeft: 0,
      marginBottom: 2,
    },
  },
};

export default styles as IStyles<typeof styles>;
