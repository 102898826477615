import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: 'background.default',
    color: 'text.primary',
    borderRadius: 50,
    boxShadow: 5,
  },
};

export default styles as IStyles<typeof styles>;
