const getDataIcons = (domain: string): Promise<any> =>
  import(`@assets/icons/${domain}/favicon.svg`)
    .then(async () => {
      const faviconDef = await import(`@assets/icons/${domain}/favicon.svg`);
      const manifestDef = await import(`@assets/icons/${domain}/manifest.json`);

      return {
        faviconPath: faviconDef?.default,
        manifestPath: manifestDef?.default,
      };
    })
    .catch(async () => {
      const faviconDef = await import(`@assets/icons/default/favicon.svg`);
      const manifestDef = await import(`@assets/icons/default/manifest.json`);

      return {
        faviconPath: faviconDef?.default,
        manifestPath: manifestDef?.default,
      };
    });

const funcUpdateDomain = (): Promise<void> => {
  // eslint-disable-next-line
  const domain = document.location.hostname.split('.')[0];
  const favicon: any = document.getElementById('favicon');
  const manifest: any = document.getElementById('manifest');

  return getDataIcons(domain).then((e) => {
    document.title = domain.charAt(0).toUpperCase() + domain.slice(1);
    favicon.href = e.faviconPath;
    manifest.href = e.manifestPath;
  });
};

export default funcUpdateDomain;
