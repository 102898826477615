enum PAYMENT_METHOD_ACTION_TYPES {
  GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD',
  GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS',
  GET_PAYMENT_METHOD_ERROR = 'GET_PAYMENT_METHOD_ERROR',

  UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD',
  UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS',
  UPDATE_PAYMENT_METHOD_ERROR = 'UPDATE_PAYMENT_METHOD_ERROR',

  DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD',
  DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS',
  DELETE_PAYMENT_METHOD_ERROR = 'DELETE_PAYMENT_METHOD_ERROR',

  CLEAR_PAYMENT_METHOD_STATE = 'CLEAR_PAYMENT_METHOD_STATE',
}

export default PAYMENT_METHOD_ACTION_TYPES;
