import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BannerIcon } from '@assets/images/icons/banner-icon.svg';
import { ReactComponent as CheckIcon } from '@assets/images/icons/check-icon.svg';
import { LoginFooter } from '@components/index';
import styles from './styles';

export interface ILoginBanner {
  props?: any;
}

/**
 * Loading Button
 * @constructor
 */
const LoginBanner: FC<ILoginBanner> = () => {
  const { t } = useTranslation();
  // const modalPrivacyRef = useRef<IModalForward | null>(null);
  //
  // const togglePrivacyModal = useCallback(() => modalPrivacyRef.current?.toggleModal(), []);

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.banner} />
        <Box sx={{ height: '20%' }}>
          <BannerIcon />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '80%',
          }}>
          <Box sx={styles.infoManage}>
            <Box sx={styles.infoHeader}>
              <Typography variant="h5">{t('loginBanner.welcome')}</Typography>
              {t('loginBanner.welcome_desc')}
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Typography variant="h6">{t('loginBanner.weManage')}</Typography> <br />
              <Box sx={styles.itemInfo}>
                <CheckIcon />
                {t('loginBanner.accounts')}
              </Box>
              <Box sx={styles.itemInfo}>
                <CheckIcon />
                {t('cards_')}
              </Box>
              <Box sx={styles.itemInfo}>
                <CheckIcon />
                {t('loginBanner.payments_h')}
              </Box>
              <Box sx={styles.itemInfo}>
                <CheckIcon />
                {t('loginBanner.transfer_h')}
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <LoginFooter />
          </Box>

          {/*<Box sx={styles.bottomInfo}>*/}
          {/*  <Box onClick={togglePrivacyModal} sx={{ cursor: 'pointer' }}>*/}
          {/*    {t('loginBanner.privacyPolicy')}*/}
          {/*  </Box>*/}
          {/*  <Box>*/}
          {/*    <Link*/}
          {/*      underline="none"*/}
          {/*      href="https://www.m4cards.com/contact.html"*/}
          {/*      color={'white'}*/}
          {/*      target="_blank">*/}
          {/*      {t('loginBanner.needHelp')}*/}
          {/*    </Link>*/}
          {/*  </Box>*/}
          {/*  <Box>©2023 e-Logic CA</Box>*/}
          {/*</Box>*/}
        </Box>
      </Box>
      {/*<Modal ref={modalPrivacyRef} height={'auto'} customStyle={{ left: '65%' }} width={'780px'}>*/}
      {/*  <PrivacyPolicy cancelBtn={togglePrivacyModal} />*/}
      {/*</Modal>*/}
    </>
  );
};

export default LoginBanner;
