enum NEW_CARDS_ACTION_TYPES {
  GET_CARDS_LIST = 'GET_NEW_CARDS_LIST',
  GET_CARDS_LIST_SUCCESS = 'GET_NEW_CARDS_LIST_SUCCESS',
  GET_CARDS_LIST_ERROR = 'GET_NEW_CARDS_LIST_ERROR',

  UPDATE_CARDS_SEARCH = 'UPDATE_NEW_CARDS_SEARCH',
  CLEAR_CARDS_SEARCH = 'CLEAR_CARDS_SEARCH',
}

export default NEW_CARDS_ACTION_TYPES;
