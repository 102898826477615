import Switch from '@mui/material/Switch';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CellProps } from 'react-table';
import useToggle from '@hooks/use-toggle';

const styleSwitch = {
  marginLeft: '-10px',
};

/**
 * EditableSwitch cell renderer
 * @constructor
 */
const EditableSwitchCell: FC<CellProps<any>> = ({
  value: initialValue,
  row: { original },
  cell: {
    column: { id },
  },
  updateCellData,
  disabled = false,
}) => {
  const isAccountActive = useSelector((state) => state.accounts.item.result?.active);
  const isFetchingAccountUpdate = useSelector((state) => state.accounts.update.isFetching);
  const isFetchingCards = useSelector((state) => state.cards.list.isFetching);
  const [isChecked, toggleChecked, setIsToggled] = useToggle(initialValue);
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    if (!isAccountActive && typeof isAccountActive === 'boolean') {
      setIsToggled(false);
      setIsDisabled(true);
    } else if (isFetchingAccountUpdate || isFetchingCards) {
      setIsDisabled(true);
    } else {
      setIsToggled(initialValue);
      setIsDisabled(false);
    }
  }, [initialValue, isAccountActive, isFetchingAccountUpdate, isFetchingCards, setIsToggled]);

  /**
   * On change cell value
   */
  const onChange = useCallback(
    (_: any, newValue: boolean) => {
      updateCellData(original.id, { [id]: newValue }, toggleChecked);
    },
    [id, original.id, toggleChecked, updateCellData],
  );

  return (
    <div style={styleSwitch}>
      <Switch
        disabled={isDisabled}
        style={{
          color: isDisabled ? '#f5f5f5' : '',
          background: 'transparent',
        }}
        checked={isChecked}
        onChange={onChange}
      />
    </div>
  );
};

export default EditableSwitchCell;
