import React, { FC, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import ExportToExcel, { ExcelReportOptions } from '@components/create-excel/create-excel';
import generateExcelOptions from '@helpers/generate-excel-options';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import { jwtVerify } from '@helpers/jwt';
import { IReportDispatchToProps, IReportStateToProps } from '@pages/report/index.props';
import RoleType from '../../enums/role-type';
import RoutePath from '../../enums/route-path';
import SaveMode from '../../enums/save-mode';
import ReportType from '../../enums/type-reports';

type IProps = IReportStateToProps & IReportDispatchToProps;
type ReportTokenPayload = {
  accountId: string;
  startPeriod: string;
  endPeriod: string;
};
const ReportPage: FC<IProps> = ({
  authAccountId,
  updateTransactionListDatesWithoutRefetch,
  roles,
}) => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const isCustomerRole = isAllowedForUser([RoleType.Customer], roles as RoleType[]);
  const [excelOptions, setExcelOptions] = useState<ExcelReportOptions | null>(null);

  if (!token) {
    return (
      <Redirect
        to={isCustomerRole ? `${RoutePath.AccountsEdit}/${authAccountId}` : RoutePath.Accounts}
      />
    );
  }

  const { accountId, startPeriod, endPeriod } = jwtVerify<ReportTokenPayload>(token);

  if (
    (authAccountId && authAccountId !== accountId && isCustomerRole) ||
    !startPeriod ||
    !endPeriod
  ) {
    return <Redirect to={`${RoutePath.AccountsEdit}/${authAccountId}`} />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (startPeriod && endPeriod) {
      setExcelOptions(
        generateExcelOptions(
          ReportType.Usages,
          { startDate: startPeriod, endDate: endPeriod },
          { id: isCustomerRole ? authAccountId : accountId, name: '' },
        ),
      );
    }
  }, [
    startPeriod,
    endPeriod,
    updateTransactionListDatesWithoutRefetch,
    isCustomerRole,
    authAccountId,
    accountId,
  ]);

  return (
    <div>{excelOptions && <ExportToExcel saveMode={SaveMode.OnInit} options={excelOptions} />}</div>
  );
};

export default ReportPage;
