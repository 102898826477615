import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LoadingButton, LoginBanner, LoginFooter, SmallButton } from '@components/index';
import DynamicIcon from '@helpers/dynamis-icon';
import Domain from 'src/enums/domains';
import RoutePath from '../../enums/route-path';
import type { IForgotDispatchToProps, IForgotStateToProps } from './index.props';
import styles from './styles';

type IProps = IForgotStateToProps & IForgotDispatchToProps;

/**
 * Forgot page
 * @constructor
 */
const Forgot: FC<IProps> = ({ forgotSendEmail, clearStore, error, isSend }) => {
  const { t } = useTranslation();
  const [emailValue, setEmailValue] = useState('');
  const [timer, setTimer] = useState(0);

  const domain = document.location.hostname.split('.')[0] as Domain;

  /**
   * Start timer
   */
  useEffect(() => {
    if (error) {
      setTimer(0);
    }
  }, [error, emailValue]);

  /**
   * Timer
   */
  useEffect(() => {
    if (isSend) {
      const myInterval = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        }

        if (timer === 0) {
          clearInterval(myInterval);
        }
      }, 1000);

      return () => {
        clearInterval(myInterval);
      };
    }
  }, [isSend, timer]);

  /**
   * Submit form
   */
  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);

      forgotSendEmail(data.get('email') || emailValue);
      setTimer(60);
    },
    [emailValue, forgotSendEmail],
  );

  const isConditionEmail = Boolean(timer) || Boolean(!emailValue);

  return (
    <Box component="main" sx={styles.wrapper}>
      <LoginBanner />
      <Box sx={styles.bannerDark} />

      <Container maxWidth="xs" sx={styles.container}>
        <Paper elevation={6}>
          <Box sx={styles.box} padding={2}>
            <DynamicIcon domain={domain} height="150px" />
            {!isSend && <Trans t={t} i18nKey="forgot.description" style={styles.infoDescribe} />}
            <Box component="form" onSubmit={handleSubmit} noValidate marginTop={1} width={1}>
              {isSend ? (
                <Box my={2} fontSize="15px">
                  <Trans
                    t={t}
                    i18nKey="forgetSuccessfullySend.description"
                    values={{
                      deviceLimit: 'forgetSuccessfullySend.description',
                      email: emailValue,
                    }}
                  />
                </Box>
              ) : (
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  label={t('forgot.inputLabel')}
                  name="email"
                  error={Boolean(error)}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  onChange={(e) => {
                    setEmailValue(e.target.value);
                    error && clearStore();
                  }}
                />
              )}
              <Typography sx={styles.errorText}>{error ? t('forgot.invalidEmail') : ''}</Typography>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} my={3}>
                {isSend && (
                  <Box sx={styles.btnBackLogin}>
                    <Link to={RoutePath.Login} onClick={() => clearStore()}>
                      <SmallButton
                        variant="outlined"
                        color="primary"
                        sx={{ ...styles.btnBackLogin, width: '100%' }}>
                        <Typography>{t('forgot.backLogin')}</Typography>
                      </SmallButton>
                    </Link>
                  </Box>
                )}
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isConditionEmail}
                  sx={{ ...styles.submit, width: isSend ? '45%' : '100%' }}>
                  {t(isSend ? 'forgot.resend' : 'forgot.sendLink')}
                  {Boolean(timer) && isSend && <Typography sx={styles.timer}>({timer})</Typography>}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <LoginFooter />
      </Box>
    </Box>
  );
};

export default Forgot;
