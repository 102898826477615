import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 1,
  },
};

export default styles as IStyles<typeof styles>;
