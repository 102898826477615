import { Autocomplete, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { format } from 'date-fns';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreditCardForm from '@components/credit-card-form';
import { TitledBlock } from '@components/index';
import SmallButton from '@components/small-button';
import { ISubPrice } from '@interfaces/sub-price/i-sub-price';
import { SubscriptionDialog } from '@pages/accounts/account/subscription/modal';
import {
  cancelSubscription,
  createSubscription,
  updateSubscription,
} from '@store/subscriptions/api';
import { ISubInfoDispatchToProps, ISubInfoStateToProps } from './index.props';
// import styles from './styles';

type IProps = ISubInfoStateToProps &
  ISubInfoDispatchToProps & {
    setDialogType: (value: SubscriptionDialog) => void;
    isFetchingModal: boolean;
    setIsFetchingModal: (value: boolean) => void;
    close: () => void;
  };

/**
 * CardsPay button
 * @constructor
 */
const SubscriptionInfo: FC<IProps> = ({
  subInfo,
  accountId,
  prices,
  email,
  close,
  setDialogType,
  isFetchingModal,
  setIsFetchingModal,
  cardLast4,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [selectedPrice, setSelectedPrice] = useState<ISubPrice | null>(null);
  const [isEditCard, setIsEditCard] = useState<boolean>(false);
  const [isCardValid, setIsCardValid] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  const { t } = useTranslation();

  const initOptions = [
    { label: `${t('autoRenew')} ${t('off')}`, id: `${t('autoRenew')} ${t('off')}` },
  ];
  const priceOptions = prices
    ?.sort((a, b) => a.unitAmount - b.unitAmount)
    .map(({ id, unitAmountText }: ISubPrice) => ({ label: unitAmountText, id }));
  const currentPrice = prices.find(({ unitAmount }) => unitAmount === subInfo?.amount);

  useEffect(() => {
    if (subInfo?.status === 'active') {
      setIsSubmitDisabled(
        (!isCardValid && isEditCard) || (selectedPrice?.id === currentPrice?.id && !isEditCard),
      );
    } else {
      setIsSubmitDisabled(!isCardValid || !selectedPrice);
    }

    if (subInfo?.cancelAtPeriodEnd) {
      setIsSubmitDisabled(!selectedPrice);
    }
  }, [isCardValid, selectedPrice, subInfo, currentPrice, isEditCard]);

  useEffect(() => {
    if (subInfo?.cancelAtPeriodEnd) {
      setSelectedPrice(null);
    } else if (subInfo?.amount) {
      setSelectedPrice(currentPrice ?? null);
    }
  }, [setSelectedPrice, subInfo, prices, currentPrice]);
  const handleSelectPrice = (value: string) => {
    if (value === `${t('autoRenew')} ${t('off')}`) {
      setSelectedPrice(null);
    }

    const price = prices.find(({ unitAmount }) => unitAmount === Number(value.slice(1)));

    setSelectedPrice(price ?? null);
  };

  const cardEditBlock = useMemo(() => {
    const dt = new Date(subInfo?.currentPeriodEnd ?? 1);
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

    return (
      <Box width="100%">
        {subInfo ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '20px',
            }}>
            {!selectedPrice && (
              <Typography textAlign="center" fontWeight="bold">
                {`${t('subscriptionWillStopped')} ${format(dtDateOnly, 'MM-dd-yyyy') ?? ''}`}
              </Typography>
            )}
            {isEditCard ? (
              <CreditCardForm setIsCardValid={setIsCardValid} />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}>
                <Typography fontWeight="bold">{`**** **** **** ${cardLast4 ?? ''}`}</Typography>
                <SmallButton
                  sx={{ marginTop: 0 }}
                  variant="outlined"
                  color="primary"
                  onClick={() => setIsEditCard(true)}>
                  {t('editCard')}
                </SmallButton>
              </Box>
            )}
          </Box>
        ) : (
          <CreditCardForm setIsCardValid={setIsCardValid} />
        )}
      </Box>
    );
  }, [subInfo, selectedPrice, t, isEditCard, cardLast4]);

  const handleChangeDialogStatus = (status: SubscriptionDialog) => {
    setDialogType(status);
    setIsFetchingModal(false);
  };

  const handleSubmit = async () => {
    setIsFetchingModal(true);
    try {
      if (Boolean(subInfo) && !selectedPrice) {
        await cancelSubscription(accountId as string);
        handleChangeDialogStatus(SubscriptionDialog.SUCCESS);

        return;
      }

      const isUpdateSubscription =
        Boolean(subInfo) && (currentPrice?.id !== selectedPrice?.id || subInfo?.cancelAtPeriodEnd);

      if (isUpdateSubscription && !isEditCard) {
        await updateSubscription(accountId as string, {
          priceId: selectedPrice?.id,
        });
        handleChangeDialogStatus(SubscriptionDialog.SUCCESS);

        return;
      }

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        handleChangeDialogStatus(SubscriptionDialog.ERROR);

        return;
      }

      const card = elements.getElement(CardElement);

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: card !== null ? card : { token: '' },
        // eslint-disable-next-line camelcase
        billing_details: {
          email,
        },
      });

      if (error) {
        handleChangeDialogStatus(SubscriptionDialog.ERROR);

        return;
      }

      if (isUpdateSubscription || (isEditCard && Boolean(subInfo))) {
        await updateSubscription(accountId as string, {
          // eslint-disable-next-line camelcase
          payment_method: paymentMethod?.id,
          priceId: selectedPrice?.id,
        });

        handleChangeDialogStatus(SubscriptionDialog.SUCCESS);

        return;
      }

      // eslint-disable-next-line camelcase
      const { client_secret, status } = (await createSubscription(accountId as string, {
        // eslint-disable-next-line camelcase
        payment_method: paymentMethod?.id as string,
        priceId: selectedPrice?.id as string,
        // eslint-disable-next-line camelcase
      })) as { client_secret: string; status: string };

      if (status === 'requires_confirmation') {
        const { error: confirmError } = await stripe.confirmCardPayment(client_secret, {
          // eslint-disable-next-line camelcase
          payment_method: paymentMethod?.id,
        });

        if (confirmError) {
          handleChangeDialogStatus(SubscriptionDialog.ERROR);

          return;
        }
      }

      handleChangeDialogStatus(SubscriptionDialog.SUCCESS);
    } catch (e) {
      handleChangeDialogStatus(SubscriptionDialog.ERROR);
    }
  };

  return (
    <TitledBlock
      title={`${t('subscriptionInfo')}`}
      sxWrapper={{ height: '100%' }}
      styleChildren={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '87%',
      }}>
      <Box
        sx={{
          padding: '16px',
          width: {
            xs: '70%',
            md: '60%',
          },
          boxShadow: '0px 0px 6px 4px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          height: '80px',
          borderRadius: '9px',
          background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#1F1E1E'),
        }}>
        <Box sx={{ width: '80%' }}>
          <Typography
            align="center"
            sx={{ color: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : '#607EB5') }}
            fontSize={12}
            pr={3}>
            {t('subscriptionAmount')}
          </Typography>
          <Autocomplete
            sx={{ width: '100%', textAlign: 'center' }}
            options={[...initOptions, ...priceOptions]}
            disableClearable
            disabled={isFetchingModal}
            onInputChange={(_, newValue) => handleSelectPrice(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                type="text"
                sx={{
                  paddingLeft: '5px',
                  input: {
                    textAlign: 'center',
                    paddingLeft: '7px',

                    '&::placeholder': {
                      color: 'rgba(0, 0, 0, 0.87)',
                      opacity: 1,
                    },
                  },
                  '&:placeholder': {
                    color: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),
                  },
                }}
                placeholder={selectedPrice?.unitAmountText ?? `${t('autoRenew')} ${t('off')}`}
                variant="standard"
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                  '&:placeholder': {
                    color: '#3C4977',
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
      {cardEditBlock}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',

          '& button': {
            width: '125px',
          },
        }}>
        <SmallButton variant="outlined" color="primary" onClick={close}>
          {t('cancel')}
        </SmallButton>
        <SmallButton
          type="submit"
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
          isFetching={isFetchingModal}>
          {t('submit')}
        </SmallButton>
      </Box>
    </TitledBlock>
  );
};

export default SubscriptionInfo;
