/* eslint-disable @typescript-eslint/default-param-last */
import { IAction } from '@interfaces/redux/i-action';
import TYPES from './action-types';

export interface ISidebarState {
  isOpen: boolean;
}

const initState: ISidebarState = {
  isOpen: true,
};

/**
 * Sidebar reducer
 */
export default (state = initState, { type }: IAction<TYPES>) => {
  switch (type) {
    case TYPES.TOGGLE_SIDEBAR:
      return { ...state, isOpen: !state.isOpen };

    default:
      return state;
  }
};
