const styleTopPanel = {
  '.MuiOutlinedInput-root': {
    borderRadius: '12px !important',
  },

  paper: {
    borderRadius: '12px !important',
    background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#121212'),
  },
};

export default styleTopPanel;
