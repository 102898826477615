import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import IS_MOBILE from '@constants/is-mobile';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#1F1E1E'),
    height: IS_MOBILE ? window.innerHeight : '100vh',
    overflow: 'auto',
  } as SxProps<Theme>,

  bannerDark: {
    background: 'black',
    zIndex: '-2',
    top: '0',
    position: 'absolute',
    bottom: '0',
    rigth: '0',
    right: '0',
    width: '100%',
    display: ({ palette }) => (palette.mode === 'light' ? 'none' : 'block'),
  } as SxProps<Theme>,

  container: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: { xs: 'auto', sm: '400px' },
    background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#000000'),

    '& svg path[data-fill="theme"]': {
      fill: ({ palette }) => palette.primary.main,
    },
  } as SxProps<Theme>,
  company: (({ breakpoints }) => ({
    ml: 1,
    [breakpoints.only('xs')]: {
      svg: {
        maxWidth: 160,
      },
    },
  })) as SxProps<Theme>,
  infoDescribe: {
    fontSize: '14px',
    width: '100%',
    marginTop: '1rem',
    fontWeight: '400',
  } as SxProps<Theme>,
  timer: {
    textAlign: 'center',
    marginTop: '16px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#A2A2A2',
  },
  errorText: {
    color: '#F44336',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '16px',
  },
  submit: {
    mt: 3,
    mb: 2,
  },
  linkWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    a: {
      color: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : '#83BCEA'),
      '&:active': {
        color: ({ palette }) => (palette.mode === 'light' ? '#000' : '#FFF'),
      } as SxProps<Theme>,
    } as SxProps<Theme>,
  },
};

export default styles as IStyles<typeof styles>;
