import { IGlobalSettings, ISettings } from '@interfaces/auth/i-settings';
import { IAction } from '@interfaces/redux/i-action';
import TYPES from './action-types';

/**
 * Get public settings success
 */
export const getPublicSettingsSuccess = (
  settings: ISettings,
  globalSettings: IGlobalSettings,
): IAction<TYPES> => ({
  type: TYPES.GET_PUBLIC_SETTINGS_SUCCESS,
  payload: { settings, globalSettings },
});

/**
 * Get public settings error
 */
export const getPublicSettings = (): IAction<TYPES> => ({
  type: TYPES.GET_PUBLIC_SETTINGS,
  payload: {},
});

/**
 * Get public settings
 */
export const getPublicSettingsError = (message: string): IAction<TYPES> => ({
  type: TYPES.GET_PUBLIC_SETTINGS_ERROR,
  payload: { message },
});
