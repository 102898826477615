import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type { Column } from 'react-table';
import { FixedTableHeight, Table, TableTopPane } from '@components/index';
import formatMoney from '@helpers/format-money';
import { ICard } from '@interfaces/cards/i-card';
import TableType from 'src/enums/table-type';
import RoutePath from '../../../enums/route-path';
import type { ICardsStateToProps, ICardsDispatchToProps } from './index.props';
import styles from './styles';

type IProps = ICardsStateToProps & ICardsDispatchToProps;

/**
 * Cards page
 * @constructor
 */
const Cards: FC<IProps> = ({
  accounts,
  pagination,
  isFetching,
  isFetchingFirebase,
  searchValue,
  getCardsList,
  updateCardsSearch,
  updateCardsListPagination,
  updateCardsSorting,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  /**
   * Get list initially
   */
  useEffect(() => {
    if (!isFetchingFirebase) {
      getCardsList();
    }
  }, [getCardsList, isFetchingFirebase]);

  /**
   * Table columns config
   */
  const columns: Column<any>[] = useMemo(
    () => [
      { Header: () => t('cards.h_ID'), accessor: 'name' },
      { Header: () => t('cards.h_account'), accessor: 'accountName' },
      { Header: () => t('cards.h_contactPhone'), accessor: 'accountPhone' },
      { Header: () => t('cards.h_holderName'), accessor: 'cardHolderName' },
      { Header: () => t('cards.h_holderPhone'), accessor: 'cardHolderPhone' },
      { Header: () => t('cards.h_holderEmail'), accessor: 'cardHolderEmail' },
      {
        Header: () => t('cards.h_balance'),
        accessor: 'finaleBalance',
        Cell: ({ value }) => formatMoney(Number(value)),
      },
      {
        Header: () => t('cards.h_status'),
        accessor: 'active',
        Cell: ({ value }) => (value ? 'Active' : 'Not Active'),
        sortType: 'basic',
      },
    ],
    [t],
  );

  /**
   * Navigate to account page by row account id
   */
  const navigateToAccountPage = useCallback(
    (_, { accountId }: ICard) =>
      () => {
        if (!accountId) {
          return;
        }

        //todo replace account.id to account.code when all old dbs will be migrated
        history.push(`${RoutePath.AccountsEdit}/${String(accountId)}`);
      },
    [history],
  );

  return (
    <FixedTableHeight
      heightPercent={102}
      topPane={<TableTopPane searchValue={searchValue} updateSearch={updateCardsSearch} />}>
      <Table
        columns={columns}
        data={accounts}
        tableHeaderSx={styles.tableHeaderSx}
        isFetching={isFetching || isFetchingFirebase}
        onItemClick={navigateToAccountPage}
        onChangeSorting={updateCardsSorting}
        onChangePagination={updateCardsListPagination}
        pagination={pagination}
        tableType={TableType.MainTable}
      />
    </FixedTableHeight>
  );
};

export default Cards;
