import Grid from '@mui/material/Grid';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FormSection } from '@components/index';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import useForm from '@hooks/use-form';
import { IAccount } from '@interfaces/accounts/i-account';
import { IValidation } from '@interfaces/form/i-validation';
import RoleType from '../../../../enums/role-type';
import RoutePath from '../../../../enums/route-path';
import { userFormContactInfo, userFormData } from '../form-data';
import type { IUserFormDispatchToProps, IUserFormStateToProps } from './index.props';

export interface IUserForm {
  type: 'update' | 'add';
  defaultFields: Record<string, any>;
}

type IProps = IUserForm & IUserFormStateToProps & IUserFormDispatchToProps & { accountId?: string };

/**
 * UserForm
 * @constructor
 */
const UserForm: FC<IProps> = ({
  type,
  error,
  isEmailError,
  defaultFields,
  isFetching,
  isFetchingInit,
  addAccount,
  updateAccount,
  userRoles,
  locationList,
  getLocationsList,
  accountId,
  isLargeAccountForm,
}) => {
  const { t } = useTranslation();
  const [accountValue, setAccountValue] = useState<IAccount | null>();
  const [isSendPasswordEmail, setIsSendPasswordEmail] = useState<boolean>(false);

  const history = useHistory();
  const isAllowedForAdmin = isAllowedForUser([RoleType.Admin], userRoles as RoleType[]);

  useEffect(() => {
    getLocationsList();
    // eslint-disable-next-line
  }, [accountId]);

  const userFormFullData = (isEmail: boolean) => [
    ...userFormData(isLargeAccountForm),
    ...userFormContactInfo(isEmail, isLargeAccountForm),
  ];

  /**
   * Form success callback on submit
   */
  const successCallback = useCallback(
    (account: IAccount) => {
      const updateAccountDataLocation = {
        locationId: locationList.find((elem) => elem.name === account.location)?.id || null,
        location: locationList.find((elem) => elem.name === account.location)?.name || null,
      };

      const updateAccountData = { ...account, ...updateAccountDataLocation };

      if (type === 'add') {
        setAccountValue(updateAccountData);
      }

      if (type === 'update') {
        updateAccount(defaultFields.id, updateAccountData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateAccount, defaultFields, type, locationList],
  );

  const handleSubmitSuccess = (props: any) => {
    if (accountValue) {
      const { billingDay, planId } = props;

      addAccount(
        { ...defaultFields, ...accountValue, billingDay, planId },
        isSendPasswordEmail,
        (id) => history.push(`${RoutePath.AccountsEdit}/${id}`),
      );
    }
  };

  /**
   * Additional form errors
   */
  const additionalErrors: IValidation | undefined =
    isEmailError && error ? { email: { hasError: true, helperText: error } } : undefined;

  /**
   * Form configuration
   */
  const { validation, handleSubmit, clearValidation } = useForm<IAccount>(
    userFormFullData(defaultFields?.email),
    successCallback,
    additionalErrors,
  );

  return (
    <Grid component="form" onSubmit={handleSubmit}>
      <FormSection
        type={type}
        title={t('userForm.title')}
        userFormData={userFormData(isLargeAccountForm)}
        userFormContactInfo={userFormContactInfo(defaultFields?.email, isLargeAccountForm)}
        defaultFields={defaultFields}
        isFetching={isFetching}
        isFetchingInit={isFetchingInit}
        validation={validation}
        clearValidation={clearValidation}
        changePassword={isAllowedForAdmin && type === 'update'}
        successCallback={handleSubmitSuccess}
        accountId={accountId}
        conditionForUser={Boolean(accountId)}
        isSendPasswordEmail={isSendPasswordEmail}
        setIsSendPasswordEmail={setIsSendPasswordEmail}
        isLargeAccountForm={isLargeAccountForm}
      />
    </Grid>
  );
};

export default UserForm;
