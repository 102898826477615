import type { IFormItem } from '@components/form-item';
import regex from '@constants/regex';
import InputType from '../../../enums/input-type';

export const userFormData = (isLargeAccountForm?: boolean): IFormItem[] => {
  const formData: IFormItem[] = [
    {
      id: 'name',
      label: 'accountName',
      isRequired: true,
    },
  ];

  const largeFormData: IFormItem[] = [
    {
      id: 'location',
      label: 'Location',
      inputType: InputType.Autocomplete,
      optionValueKeyForAutocomplete: 'name',
    },
    {
      id: 'note',
      label: 'note',
      InputProps: { multiline: true, maxRows: 3 },
    },
  ];

  return isLargeAccountForm ? [...formData, ...largeFormData] : formData;
};

export const userFormContactInfo = (
  isEmailExist: boolean,
  isLargeAccountForm?: boolean,
): IFormItem[] => {
  const largeFormData: IFormItem[] = [
    {
      id: 'contact',
      label: 'userName',
      isRequired: true,
    },
  ];

  const formData: IFormItem[] = [
    {
      id: 'phone',
      label: 'userPhone',
      isRequired: false,
      inputType: InputType.Phone,
    },
    {
      id: 'email',
      label: 'email',
      pattern: regex.email,
      isRequired: isEmailExist,
    },
  ];

  return isLargeAccountForm ? [...largeFormData, ...formData] : formData;
};
