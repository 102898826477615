import { all, AllEffect } from 'redux-saga/effects';
import AccountsSagas from './accounts/sagas';
import BonusCardsSagas from './bonus-cards/sagas';
import CardsSagas from './cards/sagas';
import DepositsSagas from './deposits/sagas';
import ForgotSagas from './forgot/sagas';
import LocationSaga from './locations/sagas';
import NewCardsSagas from './new-cards/sagas';
import PaymentMethodsSagas from './payment-methods/sagas';
import PaymentsSagas from './payments/sagas';
import SettingsSaga from './settings/sagas';
import SubscriptionsSagas from './subscriptions/sagas';
import TenantBillingSagas from './tenant-billing/sagas';
import TenantSagas from './tenant/sagas';
import TransactionsSagas from './transactions/sagas';

function* SagaManager(): Generator<AllEffect<any>> {
  yield all(SettingsSaga);
  yield all(TenantSagas);
  yield all(CardsSagas);
  yield all(BonusCardsSagas);
  yield all(NewCardsSagas);
  yield all(AccountsSagas);
  yield all(TransactionsSagas);
  yield all(PaymentsSagas);
  yield all(DepositsSagas);
  yield all(LocationSaga);
  yield all(ForgotSagas);
  yield all(SubscriptionsSagas);
  yield all(PaymentMethodsSagas);
  yield all(TenantBillingSagas);
}

export default SagaManager;
