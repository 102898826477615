import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';
import { EditableSwitchCell, Table, SmallButton, TitledBlock, Modal } from '@components/index';
import { IModalForward } from '@components/modal';
import ErrorModal from '@components/modals-info/error';
import SuccessModal from '@components/modals-info/success';
import { ICard } from '@interfaces/cards/i-card';
import TableType from 'src/enums/table-type';
import type { ICardTransferStateToProps, ICardTransferDispatchToProps } from './index.props';
import styles from './styles';

type IProps = ICardTransferStateToProps & ICardTransferDispatchToProps & { currentCardId: string };

const ModalTransferAnotherCard: FC<IProps> = ({
  cards,
  isShowInactive,
  deleteFetchingRowId,
  updateFetchingRowId,
  isListFetching,
  updateCard,
  listenTransactions,
  currentCardId,
  transferAnotherCard,
  fetchingTransfer,
  getCardsList,
  accountId,
}) => {
  const { t } = useTranslation();
  const [currentRowForTransfer, setCurrentRowForTransfer] = useState<ICard>();
  const [dataTable, setDataTable] = useState<any[]>([]);

  const modalTransferCardInfo = useRef<IModalForward | null>(null);
  const cardToTransfer = cards.find((card) => card.id === currentCardId) as ICard;
  const transactionList = listenTransactions?.map((elem) => ({ id: elem.cardId }));

  useEffect(() => {
    const filterCards = cards
      .filter(({ id, balance }) => id !== currentCardId && balance !== 0)
      .filter(({ active }) => (isShowInactive ? true : active));

    if (transactionList?.length) {
      return setDataTable(_.differenceBy(filterCards, transactionList, 'id'));
    }

    return setDataTable(filterCards);
    // eslint-disable-next-line
  }, [cards, isShowInactive, currentCardId]);

  useEffect(() => {
    const conditionFetch =
      !fetchingTransfer.isFetching && currentRowForTransfer?.name && !fetchingTransfer.error;

    if (conditionFetch) {
      setDataTable(dataTable?.filter(({ name }) => name !== currentRowForTransfer?.name));
    }
    // eslint-disable-next-line
  }, [fetchingTransfer, currentRowForTransfer]);

  const handleCellTransfer = useCallback(
    (props: any) => {
      setCurrentRowForTransfer(props.row.values);
      transferAnotherCard({
        accountId,
        fromCardId: props.row.original.id,
        toCardId: cardToTransfer?.id,
      });

      modalTransferCardInfo.current?.toggleModal();
    },
    [transferAnotherCard, accountId, cardToTransfer],
  );

  const CellTransfer = useCallback(
    (props) => (
      <SmallButton onClick={() => handleCellTransfer(props)} sx={{ margin: '0' }}>
        {t('modalTransferAnotherCard.transferFrom')}
      </SmallButton>
    ),
    [handleCellTransfer, t],
  );

  const columns: Column<any>[] = useMemo(
    () => [
      { Header: `${t('card')}`, accessor: 'name' },
      {
        Header: `${t('cardDescription')}`,
        accessor: 'extra',
        Cell: ({ value }) =>
          value.length ? <div>{value}</div> : <div style={{ width: '50px' }}></div>,
      },
      { Header: `${t('balance')}`, accessor: 'balance', Cell: ({ value }) => value.toFixed(2) },
      {
        Header: `${t('active')}`,
        accessor: 'active',
        Cell: (props: any) => EditableSwitchCell({ ...props, disabled: true }),
        sortType: 'basic',
      },
      {
        accessor: 'transfer',
        Cell: CellTransfer,
        sortType: 'basic',
      },
    ],
    [CellTransfer, t],
  );

  const fetchingRowId = updateFetchingRowId || deleteFetchingRowId;

  const renderModalTransferInfo = () => {
    if (!fetchingTransfer.isFetching && currentRowForTransfer?.name) {
      if (!fetchingTransfer.error) {
        return (
          <SuccessModal
            description={`${t('modalTransferAnotherCard.successDescription', {
              currentRowForTransferBalance: currentRowForTransfer.balance,
              currentRowForTransferName: currentRowForTransfer.name,
              cardToTransferName: cardToTransfer.name,
            })}`}
          />
        );
      }

      return <ErrorModal description={`${t('errorSomething')}`} />;
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '230px' }}>
        <CircularProgress />
      </Box>
    );
  };

  const handleCloseModalInfo = () => {
    getCardsList();
  };

  return (
    <TitledBlock
      title={`${t('modalTransferAnotherCard.title')}`}
      fullHeight
      styleChildren={styles.wrapper}>
      <Box sx={{ textAlign: 'center', my: 1 }}>
        <Typography sx={styles.modalText}>
          {t('modalTransferAnotherCard.transferFromCardDescription')}{' '}
          {`${cardToTransfer?.name} ${cardToTransfer?.extra ? `(${cardToTransfer.extra})` : ''}`}
        </Typography>
      </Box>
      <Box sx={{ height: '90%', mt: 2, overflow: 'auto' }}>
        <Table
          columns={columns}
          data={dataTable}
          isFetching={Boolean(fetchingRowId) || isListFetching}
          fetchingRowId={fetchingRowId}
          updateCellData={updateCard}
          hiddenHeader={Boolean(dataTable.length === 0)}
          withPagination={false}
          listenTransactions={listenTransactions}
          tableRowSx={styles.tableRow}
          tableType={TableType.Transfer}
          tableContainerSx={{ mt: 0 }}
        />
      </Box>

      <Modal ref={modalTransferCardInfo} width={346} height={345} onClose={handleCloseModalInfo}>
        <TitledBlock title="" styleTitle={{ height: '45px' }}>
          <Box display="flex" justifyContent="center" alignItems="center" height={'100%'}>
            {renderModalTransferInfo()}
          </Box>
        </TitledBlock>
      </Modal>
    </TitledBlock>
  );
};

export default ModalTransferAnotherCard;
