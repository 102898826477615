import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { clearCardsList, getCardsList, updateCard } from '@store/cards/action-creators';

export const mapStateToProps = (state: IState) =>
  ({
    cards: state.cards.list.result,
    isListFetching: state.cards.list.isFetching,
    updateFetchingRowId: state.cards.update.result,
    listenTransactions: state.payments.listen.transactions,
    isFetchingAccountUpdate: state.accounts.update.isFetching,
    isAccountActive: state.accounts.item.result?.active,
  } as const);

export const mapDispatchToProps = {
  clearCardsList,
  getCardsList,
  updateCard,
};

export type ICardsListMobileStateToProps = ReturnType<typeof mapStateToProps>;

export type ICardsListMobileDispatchToProps = ReplacePropertiesReturnType<
  typeof mapDispatchToProps,
  void
>;
