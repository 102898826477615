import type { SagaIterator } from '@redux-saga/core';
import { getAuth, signOut } from 'firebase/auth';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { configs } from '@helpers/get-env';
import { jwtSign, jwtVerify } from '@helpers/jwt';
import { ISettings } from '@interfaces/auth/i-settings';
import type { IAction } from '@interfaces/redux/i-action';
import { getTenantBillingData } from '@store/tenant-billing/action-creators';
import RoleType from '../../enums/role-type';
import RoutePath from '../../enums/route-path';
import {
  loginError,
  loginSuccess,
  loginViaPersist,
  logout as logoutAction,
  logoutSuccessAction,
} from './action-creators';
import TYPES from './action-types';
import { getAuthInfo, getTenant, loginUser, runFirebase } from './api';

/**
 * Authenticate user
 */
function* login({ payload }: IAction<TYPES>): SagaIterator {
  try {
    const { username, password } = payload ?? {};
    const [domain] = document.location.hostname.split('.');

    if (domain === configs.REACT_APP_LOGIN_SUBDOMAIN) {
      const tenant = yield call(getTenant, username, {
        relations: ['settings'],
      });

      const { firebaseApiKey, firebaseProjectId } = tenant.settings.reduce(
        (
          acc: { firebaseApiKey: string; firebaseProjectId: string },
          val: { key: string; value: string },
        ) => {
          if (val.key === 'firebaseApiKey' || val.key === 'firebaseProjectId') {
            return {
              ...acc,
              [val.key]: val.value,
            };
          }

          return acc;
        },
        {} as { firebaseApiKey: string; firebaseProjectId: string },
      );

      if (!tenant) {
        throw new Error('Error');
      }

      yield call(runFirebase, firebaseApiKey, firebaseProjectId);

      const user = yield call(loginUser, username, password, false);

      if (!user) {
        throw new Error('Error');
      }

      const encodedJWT = jwtSign(
        { username, password },
        {
          expiresIn: '20s',
        },
      );

      const url = `https://${tenant.frontEndHostname as string}/login?token=${encodeURIComponent(
        encodedJWT,
      )}`;

      window.location.replace(url);

      return;
    }

    const user = yield call(loginUser, username, password);
    const { claims, id, tenantId, initials, plan, accountName, tenantName } = yield call(
      getAuthInfo,
      user.email,
    );

    yield put(loginSuccess(user, claims, id, tenantId, initials, plan, accountName, tenantName));
  } catch (e) {
    const messageLogin = 'Invalid Password or E-mail';

    yield put(loginError(messageLogin));
  }
}

/**
 * Authenticate user
 */
function* logout(): SagaIterator {
  try {
    const auth = getAuth();

    localStorage.removeItem('persist:tenant');
    yield call(signOut, auth);

    yield put(logoutSuccessAction());
  } catch (e) {
    yield put(loginError(e.message));
  }
}

/**
 * Check firebase auth
 */
function onAuthStateChanged() {
  return new Promise((resolve, reject) => {
    const auth = getAuth();

    auth.onAuthStateChanged((user) => {
      if (user) {
        resolve(user);
      } else {
        reject();
      }
    });
  });
}

/**
 * Init firebase
 */
function* initFirebase(): SagaIterator {
  try {
    const { hostname, pathname } = document.location;
    const [domain] = hostname.split('.');

    if (domain === configs.REACT_APP_LOGIN_SUBDOMAIN || pathname === RoutePath.ForgotConfirm) {
      return;
    }

    yield put(loginViaPersist());

    const { firebaseApiKey, firebaseProjectId }: ISettings = yield select(
      (state) => state.settings.result?.settings,
    );

    yield call(runFirebase, firebaseApiKey, firebaseProjectId);

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      try {
        const { username, password } = jwtVerify<{ username: string; password: string }>(token);

        yield call(loginUser, username, password);
      } catch (e) {
        console.warn('Auth error');
      }
    }

    const user = yield call(onAuthStateChanged);

    if (user) {
      const { claims, id, tenantId, initials, plan, accountName, tenantName } = yield call(
        getAuthInfo,
        user.email,
      );

      const enableTenantBilling = yield select(
        (state) => state.settings.result?.settings.enableTenantBilling,
      );

      if (claims.roles.includes(RoleType.Admin) && enableTenantBilling) {
        yield put(getTenantBillingData());
      }

      yield put(loginSuccess(user, claims, id, tenantId, initials, plan, accountName, tenantName));
    } else if (!user && location.pathname !== RoutePath.Login) {
      yield put(logoutAction());
    }
  } catch (e) {
    yield put(logoutAction());
  }
}

/**
 * Await for firebase app loading and user token getting
 */
export function* awaitUserToken(): SagaIterator {
  const auth = getAuth();
  let token;

  if (auth.currentUser?.getIdToken) {
    token = yield call(() => auth.currentUser?.getIdToken());

    if (token) {
      return token;
    }
  }

  if (!token) {
    yield take(TYPES.LOGIN_SUCCESS);
  }
}

export default [
  call(initFirebase),
  takeLatest(TYPES.LOGIN, login),
  takeLatest(TYPES.LOGOUT, logout),
];
