/* eslint-disable @typescript-eslint/default-param-last */
import IPaymentMethod from '@interfaces/payment-methods/i-payment-method';
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import TYPES from './action-types';

export interface IPaymentMethodState {
  item: IReducerState<IPaymentMethod | null>;
  update: IReducerState;
  delete: IReducerState;
}

const initState: IPaymentMethodState = {
  item: {
    result: null,
    isFetching: false,
    error: null,
  },
  update: {
    result: null,
    isFetching: false,
    error: null,
  },
  delete: {
    result: null,
    isFetching: false,
    error: null,
  },
};

/**
 * Payment method reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>): IPaymentMethodState => {
  switch (type) {
    // item
    case TYPES.GET_PAYMENT_METHOD:
      return { ...state, item: { ...state.item, isFetching: true } };

    case TYPES.GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          result: payload.paymentMethod,
          isFetching: false,
        },
      };

    case TYPES.GET_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        item: { ...state.item, isFetching: false, error: payload.message },
      };

    // update
    case TYPES.UPDATE_PAYMENT_METHOD:
      return { ...state, update: { ...state.update, isFetching: true } };

    case TYPES.UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          result: payload.paymentMethod,
        },
        update: {
          ...state.update,
          isFetching: false,
        },
      };

    case TYPES.UPDATE_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        update: { ...state.update, isFetching: false, error: payload.message },
      };

    // delete
    case TYPES.DELETE_PAYMENT_METHOD:
      return { ...state, delete: { ...state.delete, isFetching: true } };

    case TYPES.DELETE_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        delete: { ...state.delete, isFetching: false, error: payload.message },
      };

    case TYPES.DELETE_PAYMENT_METHOD_SUCCESS:
    case TYPES.CLEAR_PAYMENT_METHOD_STATE:
      return initState;

    default:
      return state;
  }
};
