import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import {
  clearPaymentMethodState,
  deletePaymentMethod,
  getPaymentMethod,
  updatePaymentMethod,
} from '@store/payment-methods/action-creators';

export const mapStateToProps = (state: IState) =>
  ({
    accountId: state.accounts.item.result?.id,
    accountIdOfSignInUser: state.tenant.id,
    email: state.accounts.item.result?.email,
    emailOfSignInUser: state.tenant.result?.email,
    tenantEmail: state.tenantBilling.get.result?.email,
    defaultPaymentMethod: state.paymentMethod.item.result,
    isFetchingPaymentMethod: state.paymentMethod.item.isFetching,
    isFetchingUpdatePaymentMethod: state.paymentMethod.update.isFetching,
    isFetchingDeletePaymentMethod: state.paymentMethod.delete.isFetching,
  } as const);

export const mapDispatchToProps = {
  getPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
  clearPaymentMethodState,
};

export type IPaymentDetailsStateToProps = ReturnType<typeof mapStateToProps>;

export type IPaymentDetailsDispatchToProps = ReplacePropertiesReturnType<
  typeof mapDispatchToProps,
  void
>;
