import MUITablePagination from '@mui/material/TablePagination';
import type { TablePaginationTypeMap } from '@mui/material/TablePagination/TablePagination';
import React, { FC, useCallback } from 'react';
import type { UsePaginationInstanceProps, UseTableInstanceProps } from 'react-table';

type PaginationProps = UsePaginationInstanceProps<any>;
type TableProps = UseTableInstanceProps<any>;

interface ITablePagination {
  count: number;
  pageSize: TableProps['state']['pageSize'];
  pageIndex: TableProps['state']['pageIndex'];
  gotoPage: PaginationProps['gotoPage'];
  setPageSize: PaginationProps['setPageSize'];
  onPageChange: TablePaginationTypeMap<any, any>['props']['onPageChange'];
}

/**
 * Rows per page options
 */
const rowsPerPageOptions = [15, 30, 60, 100, 200];

/**
 * Table Pagination
 * @constructor
 */
const TablePagination: FC<ITablePagination> = ({
  count,
  pageSize,
  pageIndex,
  gotoPage,
  setPageSize,
  onPageChange,
}) => {
  /**
   * Rows per page change event
   */
  const onRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
      gotoPage(0);
    },
    [gotoPage, setPageSize],
  );

  return (
    <MUITablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count}
      rowsPerPage={pageSize}
      page={pageIndex}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
};

export default TablePagination;
