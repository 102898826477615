import { request } from '@helpers/request';
import { IPayment } from '@interfaces/payments/i-payment';

/**
 * Get the payment list
 */
export const getPaymentsListApi = (params: Record<string, any>, accountId: string) =>
  request(`payments/${accountId}`, params);

/**
 * Get payment item
 */
export const getPaymentItemApi = (accountId: string, id: string, params?: Record<string, any>) =>
  request(`payments/${accountId}/${id}`, params);

/**
 * Create payment
 */
export const createPaymentsApi = (
  accountId: string,
  body?: Partial<IPayment> | Record<string, any>,
) => request(`payments/${accountId}`, undefined, body, 'POST');

export const createCreditIntegrationApi = (
  accountId: string,
  body: { transactionsSumAmount: number },
) => request(`payments/credit/${accountId}`, undefined, body, 'POST');

/**
 * Update payment
 */
export const updatePaymentApi = (accountId: string, id: string, body?: Record<string, any>) =>
  request(`payments/${accountId}/${id}`, undefined, body, 'PATCH');

/**
 * Delete payment
 */
export const deletePaymentApi = (accountId: string, id: string) =>
  request(`payments/${accountId}/${id}`, undefined, undefined, 'DELETE');

/**
 * Get tax rate info
 */
export const getTaxRateApi = () => request('payments/tax');
