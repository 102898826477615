import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SmallButton } from '@components/index';
import formatMoney from '@helpers/format-money';
import roundNumber from '@helpers/round-number';
import PAYMENT_TYPE from '@interfaces/cards/payment-type';
import { CARDS_PAY_DIALOG } from '@pages/accounts/account/cards-pay';
import PlanType from 'src/enums/plan-type';
import styles from '../styles';
import CreditCardModal from './credit-card';
import { ICheckoutDialogDispatchToProps, ICheckoutDialogStateToProps } from './index.props';

interface ICheckoutDialog {
  type: PAYMENT_TYPE;
  close: () => void;
  setDialogType: Dispatch<SetStateAction<CARDS_PAY_DIALOG>>;
}

type IProps = ICheckoutDialog &
  ICheckoutDialogStateToProps &
  ICheckoutDialogDispatchToProps & {
    discount?: string | number | null;
    toPayValue: string | number;
    setIsLoadingPay?: any;
    setToPayValue?: any;
  };

/**
 * CheckoutDialog
 * @constructor
 */
const CheckoutDialog: FC<IProps> = ({
  type,
  deposit,
  bonus,
  isFetching,
  accountEmail,
  setDialogType,
  close,
  addTransactions,
  addPayments,
  taxRateInfo,
  isFetchingTax,
  taxRateInfoError,
  getTaxRateInfo,
  account,
  discount,
  toPayValue,
  createCreditIntegration,
  setIsLoadingPay,
  setToPayValue,
}) => {
  const { t } = useTranslation();
  const [calculatingResult, setCalculatingResult] = useState({
    subtotal: 0,
    tax: 0,
    total: 0,
  });

  const isCustomPlan: boolean = account?.accountPlan.plan.name === PlanType.Custom;
  const isPoolPlan: boolean = account?.accountPlan.plan.name === PlanType.Pool;
  const isCoopPlan: boolean = account?.accountPlan.plan.name === PlanType.Coop;

  const calculatePayment = (taxRateInclusive: boolean) => {
    const tax = roundNumber((deposit * taxRateInfo.percentage) / 100);

    if (taxRateInclusive) {
      return {
        subtotal: roundNumber(deposit - tax),
        tax,
        total: roundNumber(deposit),
      };
    }

    return {
      subtotal: roundNumber(deposit),
      tax,
      total: roundNumber(Number(deposit) + tax),
    };
  };

  const calculatePaymentCredit = (taxRateInclusive: boolean) => {
    const tax = roundNumber((Number(toPayValue) * taxRateInfo.percentage) / 100);

    if (taxRateInclusive) {
      return {
        subtotal: roundNumber(Number(toPayValue) - tax),
        tax,
        total: roundNumber(toPayValue),
      };
    }

    return {
      subtotal: roundNumber(toPayValue),
      tax,
      total: roundNumber(Number(toPayValue) + tax),
    };
  };

  /**
   * On click submit button
   */
  const onSubmit = useCallback(() => {
    const accountPlanName = account?.accountPlan.plan.name;

    if (type === PAYMENT_TYPE.AT_COUNTER) {
      addTransactions(setDialogType, accountPlanName, toPayValue, discount);
    }

    if (type === PAYMENT_TYPE.SEND_INVOICE) {
      addPayments(PAYMENT_TYPE.SEND_INVOICE, setDialogType, accountPlanName, toPayValue, discount);
    }
  }, [addPayments, addTransactions, setDialogType, type, account, toPayValue, discount]);

  useEffect(() => {
    if (!isFetchingTax) {
      const res = isCustomPlan
        ? calculatePayment(taxRateInfo.inclusive)
        : calculatePaymentCredit(taxRateInfo.inclusive);

      setCalculatingResult((prevState) => ({ ...prevState, ...res }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxRateInfo]);

  useEffect(() => {
    if (!taxRateInfo.displayName) {
      getTaxRateInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTaxRateInfo]);

  const config = useMemo(() => {
    switch (type) {
      case PAYMENT_TYPE.SEND_INVOICE:
        const [name, mail] = accountEmail?.split('@') || [];

        return {
          submitTitle: t('send'),
          description: (
            <Typography component="span" color="rgba(0,0,0,0.54)">
              {t('checkoutDialog.sendTitle')}
              <Typography component="a" sx={styles.email} href={`mailto:${String(accountEmail)}`}>
                {name && mail ? (
                  <>
                    <span>{name}</span>
                    <span>@{mail}</span>
                  </>
                ) : (
                  accountEmail
                )}
              </Typography>
            </Typography>
          ),
        };
      case PAYMENT_TYPE.AT_COUNTER:
      default:
        return {
          submitTitle: `${t('pay')}`,
          description: (
            <Typography color={'#787878'}>{t('checkoutDialog.paymentAtCounter')}</Typography>
          ),
        };
    }
  }, [accountEmail, type, t]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        {taxRateInfoError ? (
          <Box sx={styles.errorBox}>
            <Typography color="text.primary" textAlign="center">
              {taxRateInfoError}
            </Typography>
            <Typography color="text.primary" textAlign="center">
              {t('checkoutDialog.taxRateInfoError')}
            </Typography>
          </Box>
        ) : // eslint-disable-next-line prettier/prettier
        (PAYMENT_TYPE.CREDIT_CARD === type ? (
          <CreditCardModal
            taxRateName={taxRateInfo.displayName}
            taxRatePercent={taxRateInfo?.percentage}
            isFetchingTax={isFetchingTax}
            discount={discount}
            toPayValue={toPayValue}
            deposit={deposit}
            bonus={bonus}
            isAccountPlanCustom={isCustomPlan}
            isPoolPlan={isPoolPlan}
            calculatingResult={calculatingResult}
            accountPlanName={account?.accountPlan.plan.name}
            setDialogType={setDialogType}
            createCreditIntegration={createCreditIntegration}
            close={close}
            setIsLoadingPay={setIsLoadingPay}
            setToPayValue={setToPayValue}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}>
            <Box sx={styles.body}>
              <Typography color="#5C77A7" textAlign="left" fontSize={16} mb={1.2}>
                {t('checkoutDialog.funds')}:
                <Typography
                  component="span"
                  color="text.primary"
                  fontSize={18}
                  fontWeight="bold"
                  marginLeft={0.5}>
                  {isCustomPlan ? (
                    <>{formatMoney(deposit)}</>
                  ) : (
                    <>{formatMoney(Number(toPayValue))}</>
                  )}
                </Typography>
              </Typography>

              <Grid container fontSize={16} marginTop={1} marginBottom={2} spacing={1}>
                <Divider sx={styles.divider} />
                {isFetchingTax ? (
                  <Box sx={styles.loaderBox}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Grid item xs={6}>
                      <Typography color="#787878">Subtotal</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="#787878" textAlign="right">
                        {formatMoney(calculatingResult?.subtotal)}
                      </Typography>
                    </Grid>

                    {Boolean(taxRateInfo.percentage) && (
                      <>
                        <Grid item xs={6}>
                          <Typography color="#787878">
                            {t('tax')} ({`${taxRateInfo.displayName}`}){' '}
                            {`${taxRateInfo.percentage}%`}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="#787878" textAlign="right">
                            {formatMoney(calculatingResult?.tax)}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={6}>
                      <Typography color="text.primary" fontWeight="bold">
                        {t('total')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} mb={1.2}>
                      <Typography color="text.primary" fontWeight="bold" textAlign="right">
                        {formatMoney(calculatingResult?.total)}
                      </Typography>
                    </Grid>
                    <Divider sx={styles.divider} />
                  </>
                )}
              </Grid>

              {isCustomPlan ? (
                Boolean(bonus) && (
                  <Typography color="#5C77A7" marginTop={1.25}>
                    {formatMoney(bonus)} {t('checkoutDialog.bonusAfterPayment')}
                  </Typography>
                )
              ) : (
                <Box>
                  {!isPoolPlan && !isCoopPlan && discount !== '0.00' && (
                    <Typography color="#5C77A7" marginTop={1.25}>
                      ${discount} {t('checkoutDialog.discountApplied')}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>

            <Box>
              <Typography mb={1.75}>{config.description}</Typography>

              <Box sx={styles.buttons}>
                <SmallButton variant="outlined" color="primary" onClick={close} sx={styles.button}>
                  {t('cancel')}
                </SmallButton>
                <SmallButton
                  color="primary"
                  onClick={onSubmit}
                  sx={styles.button}
                  isFetching={isFetching}
                  disabled={isFetchingTax}>
                  {config.submitTitle}
                </SmallButton>
              </Box>
            </Box>
          </Box>
          // eslint-disable-next-line prettier/prettier
        ))}
      </Box>
    </Box>
  );
};

export default CheckoutDialog;
