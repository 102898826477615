import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    height: '100%',
    minHeight: '426px',
    maxHeight: '70vh',
    width: '100%',
    overflowY: {
      xs: 'auto',
      sm: 'hidden',
    },
    background: ({ palette }) => (palette.mode === 'light' ? '#fff' : '#000'),
  } as SxProps<Theme>,
  container: {
    width: {
      xs: '100%',
      sm: '41%',
    },
    py: 2.8,
    px: {
      xs: 2,
      sm: 4,
    },
    background: ({ palette }) => (palette.mode === 'light' ? '#F8F8F8' : '#424242'),
  } as SxProps<Theme>,

  gridContainer: {
    background: 'transparent',
  } as SxProps<Theme>,

  containerPay: {
    width: {
      xs: '100%',
      sm: '59%',
    },
    minHeight: '426px',
    background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#121212'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pt: 0,
  } as SxProps<Theme>,

  body: {
    flex: '1 1 auto',
    background: 'transparent',
  } as SxProps<Theme>,
  button: {
    mt: 1,
    width: 100,
  },
  divider: {
    width: '100%',
    mt: 1,
    backgroundColor: ({ custom }) => custom.simple,
  } as SxProps<Theme>,
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  footerButton: {
    display: 'flex',
    justifyContent: 'center',
    mb: 4,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    mb: 2,
  },
  email: {
    wordBreak: 'break-word',
    fontSize: 'inherit',
    fontWeight: 'bold',
  },
  loaderBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '370px',
  },
  errorBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'center',
    gap: '100px',
  },
};

export default styles as IStyles<typeof styles>;
