import IPaymentMethod from '@interfaces/payment-methods/i-payment-method';
import { IAction } from '@interfaces/redux/i-action';
import TYPES from './action-types';

/**
 * Get payment method
 */
export const getPaymentMethod = (accountId?: string): IAction<TYPES> => ({
  type: TYPES.GET_PAYMENT_METHOD,
  payload: { accountId },
});

/**
 * Get payment method success
 */
export const getPaymentMethodSuccess = (paymentMethod: IPaymentMethod): IAction<TYPES> => ({
  type: TYPES.GET_PAYMENT_METHOD_SUCCESS,
  payload: { paymentMethod },
});

/**
 * Get payment method error
 */
export const getPaymentMethodError = (message: string): IAction<TYPES> => ({
  type: TYPES.GET_PAYMENT_METHOD_ERROR,
  payload: { message },
});

/**
 * Update payment method
 */
export const updatePaymentMethod = (
  paymentMethodId: string,
  accountId?: string,
): IAction<TYPES> => ({
  type: TYPES.UPDATE_PAYMENT_METHOD,
  payload: { accountId, paymentMethodId },
});

/**
 * Update payment method success
 */
export const updatePaymentMethodSuccess = (paymentMethod: IPaymentMethod): IAction<TYPES> => ({
  type: TYPES.UPDATE_PAYMENT_METHOD_SUCCESS,
  payload: { paymentMethod },
});

/**
 * Update payment method error
 */
export const updatePaymentMethodError = (message: string): IAction<TYPES> => ({
  type: TYPES.UPDATE_PAYMENT_METHOD_ERROR,
  payload: { message },
});

/**
 * Delete payment method
 */
export const deletePaymentMethod = (accountId?: string): IAction<TYPES> => ({
  type: TYPES.DELETE_PAYMENT_METHOD,
  payload: { accountId },
});

/**
 * Delete payment method success
 */
export const deletePaymentMethodSuccess = (): IAction<TYPES> => ({
  type: TYPES.DELETE_PAYMENT_METHOD_SUCCESS,
  payload: {},
});

/**
 * Delete payment method error
 */
export const deletePaymentMethodError = (message: string): IAction<TYPES> => ({
  type: TYPES.DELETE_PAYMENT_METHOD_ERROR,
  payload: { message },
});

/**
 * Clear payment method state
 */
export const clearPaymentMethodState = (): IAction<TYPES> => ({
  type: TYPES.CLEAR_PAYMENT_METHOD_STATE,
  payload: {},
});
