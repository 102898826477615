import { IAction } from '@interfaces/redux/i-action';
import { IPagination } from '@interfaces/redux/i-pagination';
import TYPES from './action-types';
import { INewCardsState } from './reducer';

/**
 * Get new cards list
 */
export const getNewCardsList = (payload?: any): IAction<TYPES> => ({
  type: TYPES.GET_CARDS_LIST,
  payload,
});

/**
 * Get new cards list success
 */
export const getNewCardsListSuccess = (
  list: INewCardsState['list']['result'],
  pagination?: IPagination,
): IAction<TYPES> => ({
  type: TYPES.GET_CARDS_LIST_SUCCESS,
  payload: { list, pagination },
});

/**
 * Get new cards list error
 */
export const getNewCardsListError = (message: string): IAction<TYPES> => ({
  type: TYPES.GET_CARDS_LIST_ERROR,
  payload: { message },
});

/**
 * Update cards search value
 */
export const updateNewCardsSearch = (value: string): IAction<TYPES> => ({
  type: TYPES.UPDATE_CARDS_SEARCH,
  payload: { value },
});

export const handleClearSearch = (): IAction<TYPES> => ({
  type: TYPES.CLEAR_CARDS_SEARCH,
  payload: {},
});
