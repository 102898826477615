import React, { FC } from 'react';
import paymentBrand from '@constants/payment-brand';
import PaymentBrand from '../../../enums/payment-brand';

type Props = {
  brand: PaymentBrand;
};

const PaymentBrandIcon: FC<Props> = ({ brand }) => {
  const SVGBrand = paymentBrand[brand];

  return <SVGBrand />;
};

export default PaymentBrandIcon;
