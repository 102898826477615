import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SVGProps } from 'react';
import {
  Mastercard,
  Visa,
  Amex,
  Diners,
  Discover,
  Jcb,
  Unionpay,
} from 'react-payment-logos/dist/flat';
import PaymentBrand from '../enums/payment-brand';

const paymentBrand: Record<
  PaymentBrand,
  | ((props: SVGProps<SVGSVGElement>) => JSX.Element)
  | (OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & { muiName: string })
> = {
  [PaymentBrand.AmericanExpress]: Amex,
  [PaymentBrand.Diners]: Diners,
  [PaymentBrand.Discover]: Discover,
  [PaymentBrand.JCB]: Jcb,
  [PaymentBrand.MasterCard]: Mastercard,
  [PaymentBrand.UnionPay]: Unionpay,
  [PaymentBrand.Visa]: Visa,
  [PaymentBrand.Unknown]: CreditCardOutlinedIcon,
};

export default paymentBrand;
