import { request } from '@helpers/request';

/**
 * Get default payment method for account
 */
const getAccountPaymentMethodApi = (accountId: string) =>
  request(`payment-method/account/${accountId}`);

/**
 * Update default payment method for account
 */
const updateAccountPaymentMethodApi = (accountId: string, paymentMethodId: string) =>
  request(`payment-method/account/${accountId}/${paymentMethodId}`, undefined, undefined, 'PATCH');

/**
 * Delete default payment method for account
 */
const deleteAccountPaymentMethodApi = (accountId: string) =>
  request(`payment-method/account/${accountId}`, undefined, undefined, 'DELETE');

/**
 * Get default payment method for tenant
 */
const getTenantPaymentMethodApi = () => request(`payment-method/tenant`);

/**
 * Update default payment method for tenant
 */
const updateTenantPaymentMethodApi = (paymentMethodId: string) =>
  request(`payment-method/tenant/${paymentMethodId}`, undefined, undefined, 'PATCH');

/**
 * Delete default payment method for tenant
 */
const deleteTenantPaymentMethodApi = () =>
  request(`payment-method/tenant`, undefined, undefined, 'DELETE');

export {
  getAccountPaymentMethodApi,
  updateAccountPaymentMethodApi,
  deleteAccountPaymentMethodApi,
  getTenantPaymentMethodApi,
  updateTenantPaymentMethodApi,
  deleteTenantPaymentMethodApi,
};
