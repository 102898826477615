/* eslint-disable @typescript-eslint/default-param-last */
import { IBonusCard } from '@interfaces/bonus-cards/i-bonus-card';
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import { ITableList } from '@interfaces/redux/i-table-list';
import TYPES from './action-types';

export interface IBonusCardsState {
  balance: IReducerState<Partial<IBonusCard>>;
  list: IReducerState<IBonusCard[]> & ITableList;
  applyCard: IReducerState<IBonusCard>;
}

const initState: IBonusCardsState = {
  balance: {
    result: {
      credit: null,
    },
    isFetching: false,
    error: null,
  },
  list: {
    result: [],
    isFetching: false,
    error: null,
    searchValue: '',
    pagination: { total: 0, page: 1, size: 100 },
    sorting: {},
  },
  applyCard: {
    result: {
      credit: 0,
      isUsed: false,
      account: undefined,
      number: '',
      id: '',
    },
    error: null,
    isFetching: false,
  },
};

/**
 * Cards reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>) => {
  switch (type) {
    case TYPES.GET_BONUS_CARD_BALANCE:
      return { ...state, balance: { ...state.balance, error: null, isFetching: true } };

    case TYPES.GET_BONUS_CARD_BALANCE_SUCCESS:
      return {
        ...state,
        balance: {
          ...state.balance,
          result: payload,
          isFetching: false,
          error: null,
        },
      };

    case TYPES.GET_BONUS_CARD_BALANCE_ERROR:
      return {
        ...state,
        balance: { result: { credit: null }, isFetching: false, error: payload.message },
      };

    case TYPES.APPLY_BONUS_CARD:
      return { ...state, applyCard: { ...state.applyCard, error: null, isFetching: true } };

    case TYPES.APPLY_BONUS_CARD_SUCCESS:
      return {
        ...state,
        applyCard: { ...state.applyCard, result: payload, isFetching: false, error: null },
      };

    case TYPES.APPLY_BONUS_CARD_ERROR:
      return {
        ...state,
        applyCard: { ...state.applyCard, isFetching: false, error: payload.message },
      };

    case TYPES.BONUS_CARD_CLEAR_STATE:
      return {
        ...state,
        balance: { ...initState.balance },
        applyCard: { ...initState.applyCard },
      };

    default:
      return state;
  }
};
