import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React, { useState, FC, useMemo, useEffect } from 'react';
import styles from './styles';

interface ITimer {
  duration: number;
  onFinish?: () => void;
}

/**
 * Division for second
 */
const divisionRatio = 5;

/**
 * Timer progress
 * @constructor
 */
const Timer: FC<ITimer> = ({ duration, onFinish }) => {
  const [percent, setPercent] = useState(0);

  const durationSeconds = duration / 1000;
  const secondStep = durationSeconds * divisionRatio;
  const step = 100 / secondStep;

  /**
   * Timeout seconds
   */
  const timeout = useMemo(() => {
    const value = Math.floor((100 - percent) / step / divisionRatio) + 1;

    return value > durationSeconds ? durationSeconds : value;
  }, [durationSeconds, percent, step]);

  /**
   * Update progress
   */
  useEffect(() => {
    const interval = setInterval(() => setPercent((v) => (v += step)), 1000 / divisionRatio);

    return () => clearInterval(interval);
  }, [step]);

  /**
   * Call finish callback
   */
  useEffect(() => {
    if (percent !== 100) {
      return;
    }

    onFinish?.();
  }, [onFinish, percent]);

  return (
    <Box sx={styles.container}>
      <CircularProgress size={14} color="inherit" variant="determinate" value={percent} />
      <Box sx={styles.box}>
        <Typography
          variant="caption"
          component="div"
          color="inherit"
          fontSize={10}
          sx={{ marginTop: '1.1px' }}>
          {timeout}
        </Typography>
      </Box>
    </Box>
  );
};

export default Timer;
