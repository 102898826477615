import CloseIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import ModalMUI from '@mui/material/Modal';
import type { SystemCssProperties } from '@mui/system/styleFunctionSx/styleFunctionSx';
import React, { forwardRef, ReactNode, useEffect, useImperativeHandle } from 'react';
import usePrevious from '@hooks/use-previous';
import useToggle from '@hooks/use-toggle';
import styles from './styles';

export interface IModalForward {
  toggleModal: () => void;
}

export interface IModal {
  onOpen?: () => void;
  onClose?: () => void;
  width?: SystemCssProperties['width'];
  height?: SystemCssProperties['height'];
  children: ReactNode;
  customStyle?: any;
  isLoad?: boolean;
  withoutTitle?: boolean;
}

/**
 * Modal
 * @constructor
 */
const Modal = forwardRef<IModalForward, IModal>(
  (
    {
      onOpen,
      onClose,
      children,
      width = { md: '90%', xs: '95%' },
      height = { md: '90%', xs: '95%' },
      customStyle,
      isLoad = false,
      withoutTitle = false,
    },
    ref,
  ) => {
    const [isOpen, toggleModal] = useToggle(false);

    /**
     * Previous isOpen state
     */
    const isPrevOpen = usePrevious(isOpen);

    /**
     * Ref methods
     */
    useImperativeHandle(ref, () => ({ toggleModal }));

    /**
     * Callback on open
     */
    useEffect(() => {
      if (isOpen && !isPrevOpen && onOpen) {
        onOpen();
      }
    }, [isOpen, isPrevOpen, onOpen, isLoad]);

    /**
     * Callback on close
     */
    useEffect(() => {
      if (isPrevOpen && !isOpen && onClose && !isLoad) {
        onClose();
      }
    }, [isOpen, isPrevOpen, onClose, isLoad]);

    const sxIconClose = {
      ...styles.close,
      ...(withoutTitle && styles.closeWithoutTitle),
    };

    return (
      <ModalMUI open={isOpen} onClose={() => (!isLoad ? toggleModal() : null)}>
        <Box sx={{ ...styles.container, width, height, ...customStyle }}>
          <CloseIcon sx={sxIconClose} onClick={toggleModal} color="action" />
          {children}
        </Box>
      </ModalMUI>
    );
  },
);

export default Modal;
