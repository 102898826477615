import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { logout } from '@store/tenant/action-creators';

export const mapStateToProps = (state: IState) =>
  ({
    accountId: state.tenant.id,
    userRoles: state.tenant.claims?.roles,
    initials: state.tenant.initials,
    loginAccountName: state.tenant.loginAccountName,
  } as const);

export const mapDispatchToProps = {
  logout,
};

export type ISidebarStateToProps = ReturnType<typeof mapStateToProps>;

export type ISidebarDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
