import { confirmPasswordReset, getAuth, verifyPasswordResetCode } from 'firebase/auth';
import { requestWithoutAuth } from '@helpers/request';

/**
 * Forgot password api request
 *
 * @return {Promise.<void>}
 */
export const forgotPassword = async (email: string) => {
  try {
    await requestWithoutAuth(`reset-password/${email}`, undefined, undefined, 'POST');
  } catch (error) {
    throw new Error(error.message);
  }
};

/**
 * Forgot verify action code api request
 *
 * @return {Promise<string>}
 */
export const forgotVerifyApi = async (token: string) => {
  const auth = getAuth();
  try {
    const { oobCode } = await requestWithoutAuth(`reset-password/${token}`);

    const email = await verifyPasswordResetCode(auth, oobCode);

    return { email, oobCode };
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Forgot confirm password api request
 *
 * @return {Promise.<void>}
 */
export const forgotConfirmApi = async (actionCode: string, newPassword: string) => {
  const auth = getAuth();
  try {
    await confirmPasswordReset(auth, actionCode, newPassword);
  } catch (e) {
    throw new Error(e.message);
  }
};
