enum BONUS_CARDS_ACTION_TYPES {
  GET_BONUS_CARD_BALANCE = 'GET_BONUS_CARD_BALANCE',
  GET_BONUS_CARD_BALANCE_SUCCESS = 'GET_BONUS_CARD_BALANCE_SUCCESS',
  GET_BONUS_CARD_BALANCE_ERROR = 'GET_BONUS_CARD_BALANCE_ERROR',
  APPLY_BONUS_CARD = 'APPLY_BONUS_CARD',
  APPLY_BONUS_CARD_SUCCESS = 'APPLY_BONUS_CARD_SUCCESS',
  APPLY_BONUS_CARD_ERROR = 'APPLY_BONUS_CARD_ERROR',
  BONUS_CARD_CLEAR_STATE = 'BONUS_CARD_CLEAR_STATE',
}

export default BONUS_CARDS_ACTION_TYPES;
