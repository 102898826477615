import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  paper: ({ breakpoints }: Theme) =>
    ({
      my: 3,
      mt: 0,
      borderRadius: '0',
      border: 'none',
      background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#1F1E1E'),

      [breakpoints.up('md')]: {
        mb: 0,
        overflow: 'auto',
        height: '100%',
        maxHeight: 'calc(100vh - 105px)',
      },
    } as SxProps<Theme>),
};

export default styles as IStyles<typeof styles>;
