import Grid from '@mui/material/Grid';
import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSection } from '@components/index';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import useForm from '@hooks/use-form';
import { IAccount } from '@interfaces/accounts/i-account';
import {
  IUserFormDispatchToProps,
  IUserFormStateToProps,
} from '@pages/administration/user-form/index.props';
import RoleType from '../../../enums/role-type';
import { userFormContactInfo, userFormData } from './form-data';

type IProps = IUserFormStateToProps & IUserFormDispatchToProps;

/**
 * UserForm
 * @constructor
 */
const UserForm: FC<IProps> = ({
  userRoles,
  userInfo,
  userId,
  isFetchingUser,
  getAccount,
  updateAccount,
  isFetchingAccountUpdate,
}) => {
  const { t } = useTranslation();
  const isAllowedForAdmin = isAllowedForUser([RoleType.Admin], userRoles as RoleType[]);

  const formDataForAdminData = isAllowedForAdmin
    ? userFormData
    : userFormData.filter((elem) => elem.id !== 'note');

  useEffect(() => {
    getAccount(userId.toString());
  }, [getAccount, userId]);

  const {
    email: defaultEmail,
    name: defaultName,
    phone: defaultPhone,
    note: defaultNote,
    contact: defaultContact,
  } = userInfo ?? {};

  /**
   * Defaults form values
   */
  const defaultFields = {
    email: defaultEmail,
    name: defaultName,
    phone: defaultPhone,
    note: defaultNote,
    contact: defaultContact,
  };

  /**
   * Submit form
   */
  const successCallback = useCallback(
    (data: IAccount) => {
      updateAccount(userId, data);
    },
    [updateAccount, userId],
  );

  /**
   * Form configuration
   */
  const { validation, handleSubmit } = useForm<IAccount>(
    [...formDataForAdminData, ...userFormContactInfo],
    successCallback,
  );

  return (
    <Grid component="form" onSubmit={handleSubmit}>
      <FormSection
        title={t('userForm.title')}
        userFormData={formDataForAdminData}
        defaultFields={defaultFields}
        userFormContactInfo={userFormContactInfo}
        validation={validation}
        isFetching={isFetchingAccountUpdate}
        isFetchingInit={isFetchingUser}
        changePassword={!isAllowedForAdmin}
        accountId={userId}
      />
    </Grid>
  );
};

export default UserForm;
