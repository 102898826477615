import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import React, { FC } from 'react';
import IS_MOBILE from '@constants/is-mobile';
import { useColorModeContext } from '@context/color-mode-context';
import styles from './styles';

/**
 * Color mode switcher
 * @constructor
 */
const ColorModeSwitcher: FC = () => {
  const theme = useTheme();
  const { toggleColorMode } = useColorModeContext();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!IS_MOBILE && (
        <Box sx={styles.container}>
          <IconButton onClick={toggleColorMode} color="inherit">
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default React.memo(ColorModeSwitcher);
