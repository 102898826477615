import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  boxWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
  },

  icons: {
    ml: -2,
    mr: -2,
    display: 'flex',
    '& .MuiSvgIcon-root': { m: 1 },
  },
  icon: {
    width: '100%',

    '& svg': { width: 20 },
    '& [data-fill="theme"]': {
      fill: ({ palette }) => palette.primary.main,
    },
    '& [data-stroke="theme"]': {
      stroke: ({ palette }) => palette.primary.main,
    },
    '& [data-is-disabled="true"] path': { fill: ({ palette }) => palette.action.disabled },
  } as SxProps<Theme>,

  modalCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },

  textPopup: {
    padding: '7px 15px',
    margin: '0',
    fontSize: '13px',
    textTransaction: '0.2s ease all',
    cursor: 'pointer',
    textAlign: 'left',
    color: ({ palette }: any) => (palette.mode === 'light' ? '#3C4977' : 'FFFF00'),

    '&:hover': {
      background: ({ palette }: any) => (palette.mode === 'light' ? '#F5F5F5' : palette.grey[800]),
    },
  } as SxProps<Theme>,

  disabled: {
    color: '#C4C4C4',
    cursor: 'not-allowed',
    '&:hover': {
      background: 'transparent',
    },
  } as SxProps<Theme>,
};

export default styles as IStyles<typeof styles>;
