import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import backDark from '@assets/images/icons/sidebar-background-dark.svg';
import backWhite from '@assets/images/icons/sidebar-background-white.svg';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  sidebar: {
    maxHeight: '72px',
    overflowY: 'auto',
    position: 'relative',
  },
  backSidebar: {
    position: 'absolute',
    zIndex: 1,
    backgroundImage: ({ palette }) =>
      palette.mode === 'light' ? `url(${backWhite})` : `url(${backDark})`,
    top: 0,
    bottom: 0,
    right: 0,
    width: '100vw',
  } as SxProps<Theme>,
  frontSidebar: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 2,
    height: '100%',
    backgroundColor: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : '#121212'),
  } as SxProps<Theme>,

  logo: {
    position: 'relative',
    mx: { xs: 2, sm: 4 },
    width: '51px',
    height: '51px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 2,
  },
  version: {
    position: 'absolute',
    left: 0,
    bottom: -10,
    right: 0,
    textAlign: 'center',
    fontSize: 10,
  },
  list: {
    display: 'flex',
  },
  btnAddAccount: {
    my: 0,
    mr: 3,
    color: 'white',
    borderColor: 'white',
    '&:hover': {
      borderColor: 'white',
    },
  } as SxProps<Theme>,

  footer: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    cursor: 'pointer',
    zIndex: 2,
    mr: 4,
  },
};

export default styles as IStyles<typeof styles>;
