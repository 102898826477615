import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  tableHeaderSx: {
    '& th': {
      backgroundColor: ({ palette }) =>
        palette.mode === 'light' ? palette.grey[100] : palette.grey[900],
    },
  } as SxProps<Theme>,
};

export default styles as IStyles<typeof styles>;
