/* eslint-disable jsx-a11y/no-autofocus */
import { TextField } from '@mui/material';
import MuiPhoneNumber, { MuiPhoneNumberProps } from 'material-ui-phone-number-2';
import React, { ChangeEvent, ChangeEventHandler, FC, useState } from 'react';
import CountyCode from '../../../enums/county-code';

type Props = Pick<
  MuiPhoneNumberProps,
  | 'id'
  | 'name'
  | 'label'
  | 'fullWidth'
  | 'error'
  | 'helperText'
  | 'disabled'
  | 'sx'
  | 'variant'
  | 'value'
  | 'defaultValue'
> & {
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> &
    ((e: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void);
};

const CustomPhoneNumberInput: FC<Props> = ({
  id,
  name,
  label,
  onChange,
  fullWidth,
  error,
  helperText,
  disabled,
  sx,
  variant,
  value,
}) => {
  const isConditionalValue = (phoneValue: unknown) =>
    typeof phoneValue === 'string' &&
    Boolean(phoneValue) &&
    !(phoneValue === '+1' || phoneValue === '+' || phoneValue === '');

  const [isFocused, setIsFocused] = useState(isConditionalValue(value));

  const handleBlur = ({
    target,
  }: {
    target: (EventTarget & HTMLTextAreaElement) | (EventTarget & HTMLInputElement);
  }) => {
    if (!isConditionalValue(target.value)) {
      setIsFocused(false);
      onChange('');
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return isFocused ? (
    <MuiPhoneNumber
      id={id}
      name={name}
      label={label}
      onChange={onChange}
      defaultCountry={CountyCode.Canada}
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      autoComplete={id}
      disabled={disabled}
      sx={sx}
      variant={variant}
      onlyCountries={Object.values(CountyCode)}
      value={value}
      dropdownClass="phone-dropdown"
      onBlur={handleBlur}
      autoFocus={!isConditionalValue(value)}
      defaultValue={value}
    />
  ) : (
    <TextField
      variant={variant}
      label={label}
      onFocus={handleFocus}
      fullWidth={fullWidth}
      disabled={disabled}
      autoComplete={id}
      id={id}
      name={name}
    />
  );
};

export default CustomPhoneNumberInput;
