/* eslint-disable @typescript-eslint/default-param-last */
import { IGlobalSettings, ISettings } from '@interfaces/auth/i-settings';
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import TYPES from './action-types';

export interface ISettingsState
  extends IReducerState<{
    settings: ISettings;
    globalSettings: IGlobalSettings;
  } | null> {}

const initState: ISettingsState = {
  error: null,
  isFetching: false,
  result: null,
};

/**
 * User reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>) => {
  switch (type) {
    case TYPES.GET_PUBLIC_SETTINGS:
      return { ...state, error: null, isFetching: true };

    case TYPES.GET_PUBLIC_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        result: { settings: payload.settings, globalSettings: payload.globalSettings },
      };

    case TYPES.GET_PUBLIC_SETTINGS_ERROR:
      return { ...state, isFetching: false, error: payload.message };

    default:
      return state;
  }
};
