import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  wrapper: {
    background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#1F1E1E'),
    height: '78px',
    my: '0 !important',
    ml: '0 !important',
    color: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : 'white'),
    px: 4,
    boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.25)',
    flexWrap: 'nowrap',
  } as SxProps<Theme>,

  textPopup: {
    padding: '7px 15px',
    margin: '0',
    fontSize: '13px',
    textTransaction: '0.2s ease all',
    cursor: 'pointer',
    textAlign: 'right',
    borderRadius: '4px',
    marginTop: '5px',
    color: '#000',

    '&:hover': {
      background: '#F5F5F5',
    },
  } as SxProps<Theme>,

  pointer: {
    cursor: 'pointer',
    position: 'relative',

    '& p': {
      color: ({ palette }: any) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),
    } as SxProps<Theme>,
  },

  paper: {
    background: '#fff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(0, 0, 0, 0.55)',
  },

  icon: {
    background: '#ffffff',
    borderRadius: '50%',
    height: '51px',
    width: '51px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& p': {
      color: '#3C4977',
    },
  } as SxProps<Theme>,
};

export default styles as IStyles<typeof styles>;
