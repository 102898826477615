import { request } from '@helpers/request';

/**
 * Get the account list
 */
export const getAccountsListApi = (
  tenantId: string,
  params: Record<string, string | number | undefined | any>,
) => request(`accounts/${tenantId}`, params);

/**
 * Get account
 */
export const getAccountApi = (tenantId: string, id: string) =>
  request(`accounts/${tenantId}/${id}`);

/**
 * Create account
 */
export const createAccountApi = (tenantId: string, body?: Record<string, any>) =>
  request(`accounts/${tenantId}`, undefined, body, 'POST');

/**
 * Update account
 */
export const updateAccountApi = (tenantId: string, id: string, body?: Record<string, any>) =>
  request(`accounts/${tenantId}/${id}`, undefined, body, 'PATCH');

/**
 * Delete account
 */
export const deleteAccountApi = (tenantId: string, id: string) =>
  request(`accounts/${tenantId}/${id}`, undefined, undefined, 'DELETE');

/**
 * Update auto-charge
 */
export const updateAutoChargeApi = (accountId: string, autoCharge: boolean) =>
  request(`accounts-plan/update-auto-charge/${accountId}`, undefined, { autoCharge }, 'PATCH');
