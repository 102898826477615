import { Autocomplete, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlanIcon } from '@assets/images/icons/plan.svg';
import { TitledBlock } from '@components/index';
import PlanType from '../../../enums/plan-type';
import SmallButton from '../small-button';
import styles from './styles';

interface ICreateCustomPlanUser {
  handleClose: any;
  handleCancel: any;
  planList: any[];
  accountInfo: Record<string, string>;
}

/**
 * @constructor
 */
const CreateCustomPlanUser: FC<ICreateCustomPlanUser> = ({
  handleClose,
  handleCancel,
  planList,
  accountInfo,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string | null>();
  const [billingDay, setBillingDay] = useState<null | number>(null);
  const dateOptions = new Array(31).fill(1).map((a, i) => i + 1);

  const options = planList?.map((elem: any) => ({ label: elem.name, id: elem.id }));
  const isEmailMandatory =
    (value === PlanType.Fleet || value === PlanType.Pool) && !accountInfo?.email;

  const handleCloseModal = () => {
    const currentPlan = options.find((e: any) => e.label === value);

    handleClose({ billingDay, planId: currentPlan?.id });
  };

  return (
    <TitledBlock
      title={t('createCustomPlan.title')}
      sxWrapper={styles.wrapper}
      styleTitle={{ width: '100%' }}
      // fullHeight
      styleChildren={styles.wrapper}>
      <Box
        sx={{
          ...styles.choosePlan,
          background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#1F1E1E'),
        }}>
        <Box sx={styles.boxIcon}>
          <PlanIcon />
        </Box>
        <Box sx={styles.selectBox}>
          <Typography align="center" sx={styles.title} fontSize={12} pr={3}>
            {t('createCustomPlan.plan')}
          </Typography>
          <Autocomplete
            sx={styles.selectMenu}
            options={options}
            disableClearable
            onInputChange={(_, newValue) => setValue(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                type="text"
                sx={{
                  ...styles.inputSelect,
                  '&:placeholder': {
                    color: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),
                  },
                }}
                placeholder={`${t('createCustomPlan.choosePlan')}`}
                variant="standard"
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                  '&:placeholder': {
                    color: '#3C4977',
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>

      {(value === PlanType.Unlimited || value === PlanType.Fleet) && (
        <Box sx={styles.infoBilling}>
          <Typography fontSize={16} color="#3C4977">
            {t('createCustomPlan.billingDay')}
          </Typography>
          <Autocomplete
            options={dateOptions}
            defaultValue={1}
            disableClearable
            onInputChange={(_, newValue) => setBillingDay(Number(newValue))}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={styles.inputSelect}
                style={{
                  width: '65px',
                }}
                variant="standard"
              />
            )}
          />
          <Typography fontSize={16}>{t('createCustomPlan.dayOfEachMonth')}</Typography>
        </Box>
      )}

      {isEmailMandatory && <Typography sx={styles.error}>{t('createCustomPlan.error')}</Typography>}

      <Box sx={styles.btnBox}>
        <SmallButton variant="outlined" color="primary" onClick={handleCancel}>
          {t('cancel')}
        </SmallButton>
        <SmallButton type="submit" disabled={!value || isEmailMandatory} onClick={handleCloseModal}>
          {t('create')}
        </SmallButton>
      </Box>
    </TitledBlock>
  );
};

export default CreateCustomPlanUser;
