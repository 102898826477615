import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { addAccount, updateAccount } from '@store/accounts/action-creators';
import { getLocationsList } from '@store/locations/action-creators';
import type { IUserForm } from './index';

export const mapStateToProps = (state: IState, { type }: IUserForm) =>
  ({
    isFetching: state.accounts[type].isFetching,
    isFetchingInit: state.accounts.item.isFetching,
    error: state.accounts.item.error,
    isEmailError: state.accounts.item.error?.includes('email'),
    userRoles: state.tenant.claims?.roles,
    locationList: state.locations.list.result,
    isLargeAccountForm: state.settings.result?.settings.isLargeAccountForm,
  } as const);

export const mapDispatchToProps = {
  addAccount,
  updateAccount,
  getLocationsList,
};

export type IUserFormStateToProps = ReturnType<typeof mapStateToProps>;

export type IUserFormDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
