import { IAction } from '@interfaces/redux/i-action';
import { ISubPrice } from '@interfaces/sub-price/i-sub-price';
import TYPES from './action-types';

/**
 * Get subscription prices
 */
export const getSubscriptionPrices = (): IAction<TYPES> => ({
  type: TYPES.GET_SUBSCRIPTION_PRICES,
  payload: {},
});

/**
 * Get subscription prices success
 */
export const getSubscriptionPricesSuccess = (payload: ISubPrice[]): IAction<TYPES> => ({
  type: TYPES.GET_SUBSCRIPTION_PRICES_SUCCESS,
  payload,
});

/**
 * Get subscription prices error
 */
export const getSubscriptionPricesError = (message: string): IAction<TYPES> => ({
  type: TYPES.GET_SUBSCRIPTION_PRICES_ERROR,
  payload: { message },
});
