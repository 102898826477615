const styles = {
  // '.MuiOutlinedInput-root': {
  //   borderRadius: '12px !important',
  // },

  // paper: {
  //   borderRadius: '12px !important',
  //   background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#121212'),
  // },

  width: '100%',
  backgroundImage: 'unset',

  input: {
    '&::placeholder': {
      color: ({ palette }) => (palette.mode === 'light' ? 'text.primary' : '#828eb9'),
    },

    fieldset: {
      border: 'unset !important',
    },

    input: {
      '&::placeholder': {
        color: '#828EB9',
      },
    },
  },
  icon: {
    color: '#828EB9',
    my: 0.5,
    minWidth: '10px !important',
  },
};

export default styles;
