import type { SagaIterator } from '@redux-saga/core';
import { call, put, takeLatest } from 'redux-saga/effects';
import getDepositsListApi from '@store/deposits/api';
import { awaitUserToken } from '@store/tenant/sagas';
import { getDepositsListError, getDepositsListSuccess } from './action-creators';
import TYPES from './action-types';

/**
 * Get deposits list
 */
function* getDeposits(): SagaIterator {
  try {
    yield call(awaitUserToken);

    const result = yield call(getDepositsListApi);

    yield put(getDepositsListSuccess(result));
  } catch (e) {
    yield put(getDepositsListError(e.message));
  }
}

export default [takeLatest(TYPES.GET_DEPOSITS_LIST, getDeposits)];
