import { useCallback, useState } from 'react';

/**
 * Use toggle
 */
const useToggle = (initialState: boolean) => {
  const [isToggled, setIsToggled] = useState(initialState);

  const toggle = useCallback(() => setIsToggled((prevIsToggled) => !prevIsToggled), []);

  return [isToggled, toggle, setIsToggled] as const;
};

export default useToggle;
