import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SmallButton from '@components/small-button';

// eslint-disable-next-line
export const ModalRemoveCard = (props: any) => {
  const { t } = useTranslation();
  const { styles, name, deleteCard, deleteFetchingRowId, id, toggleModal } = props;

  return (
    <Box sx={styles.modalCard}>
      <Typography variant="body1" color="text.secondary" textAlign="center">
        {t('modalRemoveCard.description')} #{name} ?
      </Typography>
      <Box display="flex" justifyContent="space-evenly" width={'100%'} marginTop={1.5}>
        <SmallButton
          color="primary"
          onClick={() => deleteCard(id)}
          sx={{ mt: 1 }}
          isFetching={id === deleteFetchingRowId}>
          {t('confirm')}
        </SmallButton>
        <SmallButton variant="outlined" color="primary" onClick={toggleModal} sx={{ mt: 1 }}>
          {t('cancel')}
        </SmallButton>
      </Box>
    </Box>
  );
};
