import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC, ReactNode, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SmallButton } from '@components/index';
import TransitionsPopper, { ITransitionsPopperForward } from '@components/transitions-popper';
import styles from './styles';

interface IConfirmationPopper {
  message?: string;
  onSubmit: (close: () => void) => void;
  isDisabled?: boolean;
  isFetching?: boolean;
  body?: ReactNode;
  rest?: any;
  sxBox?: any;
}

/**
 * ConfirmationPopper
 * @constructor
 */
const ConfirmationPopper: FC<IConfirmationPopper> = ({
  onSubmit,
  isDisabled,
  isFetching,
  children,
  body,
  sxBox,
  ...rest
}) => {
  const { t } = useTranslation();
  const popper = useRef<ITransitionsPopperForward | null>(null);

  const { message = `${t('confirmationPopper.defaultMessage')}` } = rest;
  /**
   * Close popper
   */
  const close = useCallback(() => popper.current?.close(), []);

  /**
   * On click submit button
   */
  const submitAndClose = useCallback(() => onSubmit(close), [close, onSubmit]);

  if (isDisabled) {
    return <div>{children || null}</div>;
  }

  return (
    <TransitionsPopper ref={popper} button={children} sxBox={sxBox}>
      {body ?? (
        <Typography variant="body1" color="text.secondary" textAlign="center">
          {message}
        </Typography>
      )}
      <Box display="flex" justifyContent="space-between" mx={2}>
        <SmallButton
          color="primary"
          onClick={submitAndClose}
          sx={styles.button}
          isFetching={isFetching}>
          {t('submit')}
        </SmallButton>
        <SmallButton variant="outlined" color="primary" onClick={close} sx={styles.button}>
          {t('cancel')}
        </SmallButton>
      </Box>
    </TransitionsPopper>
  );
};

export default ConfirmationPopper;
