import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { clearAccount, getAccount } from '@store/accounts/action-creators';
import { clearCardsList, toggleShowInactive } from '@store/cards/action-creators';
import { clearNewDeposit, getDepositsList } from '@store/deposits/action-creators';
import { deletePayment } from '@store/payments/action-creators';

export const mapStateToProps = (state: IState) =>
  ({
    account: state.accounts.item.result,
    transactions: state.payments.listen.transactions,
    isDeleteFetching: state.payments.delete.isFetching,
    isShowInactive: state.cards.isShowInactive,
    tenantLastPaymentDay: state.tenantBilling.get.result?.lastPaymentDay,
    userRoles: state.tenant.claims?.roles,
    id: state.tenant.id,
    depositList: state.deposits.list.result,
  } as const);

export const mapDispatchToProps = {
  getAccount,
  clearAccount,
  clearCardsList,
  clearNewDeposit,
  getDepositsList,
  deletePayment,
  toggleShowInactive,
};

export type IAccountStateToProps = ReturnType<typeof mapStateToProps>;

export type IAccountDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
