import type { SagaIterator } from '@redux-saga/core';
import { call, put, takeLatest } from 'redux-saga/effects';
import getLocationsListApi from '@store/locations/api';
import { awaitUserToken } from '@store/tenant/sagas';
import { getLocationsListError, getLocationsListSuccess } from './action-creators';
import TYPES from './action-types';

/**
 * Get locations list
 */
function* getLocations(): SagaIterator {
  try {
    yield call(awaitUserToken);
    const result = yield call(getLocationsListApi);

    yield put(getLocationsListSuccess(result));
  } catch (e) {
    yield put(getLocationsListError(e.message));
  }
}

export default [takeLatest(TYPES.GET_LOCATIONS_LIST, getLocations)];
