import { ClickAwayListener, Fade, Paper, Popper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DotsIcon } from '@assets/images/icons/dots.svg';
import CustomLoadingSwitch from '@components/custom-loading-switch';
import GetReportModal from '@components/get-report-modal/index.store';
import { SmallButton, TransactionsModal } from '@components/index';
import type { IModalForward } from '@components/modal';
import ToDeposite from '@components/to-deposite/index.store';
import dateFormat from '@constants/date-format';
import IS_MOBILE from '@constants/is-mobile';
import isConditionDotsInString from '@helpers/condition-payment-dots';
import formatMoney from '@helpers/format-money';
import insertElement from '@helpers/insert-element';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import roundNumber from '@helpers/round-number';
import SubscriptionModal from '@pages/accounts/account/subscription/modal/index.store';
import PlanType from 'src/enums/plan-type';
import RoleType from '../../../../enums/role-type';
import ReportType from '../../../../enums/type-reports';
import CardsPay from '../cards-pay/index.store';
import BlockItem, { IBlockItem } from './block-item';
import type {
  IAdditionalBlocksDispatchToProps,
  IAdditionalBlocksStateToProps,
} from './index.props';
import styles from './styles';

interface IAdditionalBlocks {
  active?: boolean;
  isWaitingPayment?: boolean;
}

type IProps = IAdditionalBlocks & IAdditionalBlocksStateToProps & IAdditionalBlocksDispatchToProps;

/**
 * AdditionalBlocks
 * @constructor
 */
const AdditionalBlocks: FC<IProps> = ({
  deposit,
  customBonus,
  totalBalance,
  updateAutoCharge,
  isFetchingAutoCharge,
  account,
  isUpdateFetching,
  updateAccount,
  userRoles,
  isWaitingPayment,
  isDeleteFetching,
  deletePayment,
  listenTransaction,
  paymentMethod,
  updateNewDeposit,
}) => {
  const { t } = useTranslation();
  const [toPayDeposit, setToPayDeposit] = useState<number>(0);
  const transactionsModal = useRef<IModalForward | null>(null);
  const salesByCardModal = useRef<IModalForward | null>(null);
  const usageReportsModal = useRef<IModalForward | null>(null);
  const [isBillingDay, setIsBillingDay] = useState<number>(0);
  const [discountValue, setDiscountValue] = useState<number | string>('0.00');
  const [toPayValue, setToPayValue] = useState<number>(0);
  const [isOpenReports, setIsOpenReports] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const isAllowedForAdmin = isAllowedForUser([RoleType.Admin], userRoles as RoleType[]);
  const isCustomerRole = isAllowedForUser([RoleType.Customer], userRoles as RoleType[]);
  const isCustomPlan: boolean = account?.accountPlan.plan.name === PlanType.Custom;
  const isFleetPlan: boolean = account?.accountPlan.plan.name === PlanType.Fleet;
  const isUnlimitedPlan: boolean = account?.accountPlan.plan.name === PlanType.Unlimited;
  const isPoolPlan: boolean = account?.accountPlan.plan.name === PlanType.Pool;
  const isCoopPlan: boolean = account?.accountPlan.plan.name === PlanType.Coop;
  const isMonthlyPlan = isUnlimitedPlan || isFleetPlan;

  const transactionCount = listenTransaction?.reduce(
    (obj, elem) => ({ amount: (obj.amount += elem.amount), bonus: (obj.bonus += elem.bonus) }),
    { amount: 0, bonus: 0 },
  );

  useEffect(() => {
    const billingDay = account?.accountPlan.toPay;

    setIsBillingDay(billingDay ?? 0);
  }, [account?.accountPlan.toPay]);

  useEffect(() => {
    setToPayDeposit(totalBalance);

    if (isMonthlyPlan) {
      if (isBillingDay) {
        setToPayValue(account?.accountPlan.toPay ?? 0);
      }

      setDiscountValue('0.00');
    }

    if (isPoolPlan || isCoopPlan) {
      setToPayDeposit(account?.accountPlan.availableBalance ?? 0);
      setToPayValue(deposit);
    }
  }, [
    isBillingDay,
    totalBalance,
    isMonthlyPlan,
    isPoolPlan,
    isCoopPlan,
    account?.accountPlan,
    deposit,
  ]);

  /**
   * Summary balance
   */
  const balance = useMemo(
    () => (
      <Typography
        variant="body1"
        fontWeight="bold"
        fontSize={{ xs: '21px', sm: '16px' }}
        sx={{
          color: ({ palette }) =>
            palette.mode === 'light' ? '#787878' : 'rgba(255, 255, 255, 0.83)',
        }}>
        {formatMoney(toPayDeposit)}
      </Typography>
    ),
    [toPayDeposit],
  );

  /**
   * History
   */

  const handleClickReports: any = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setIsOpenReports(!isOpenReports);
    },
    [isOpenReports],
  );

  const toggleUsageReportsModal = useCallback(() => usageReportsModal.current?.toggleModal(), []);
  const toggleHistoryModal = useCallback(() => transactionsModal.current?.toggleModal(), []);
  const toggleSalesByCardModal = useCallback(() => salesByCardModal.current?.toggleModal(), []);

  const getReports = useMemo(
    () => (
      <ClickAwayListener onClickAway={() => setIsOpenReports(false)}>
        <Box>
          <Popper
            open={isOpenReports}
            anchorEl={anchorEl}
            transition
            placement="bottom-end"
            sx={{ width: '160px', zIndex: 2 }}>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={200}>
                <Paper>
                  <Typography sx={styles.textPopup} onClick={toggleHistoryModal}>
                    {t('history')}
                  </Typography>
                  {isAllowedForAdmin && (
                    <Typography sx={styles.textPopup} onClick={toggleUsageReportsModal}>
                      {t('usages')}
                    </Typography>
                  )}
                  {isAllowedForAdmin && (
                    <Typography sx={styles.textPopup} onClick={toggleSalesByCardModal}>
                      {t('additionalBlocks.salesByCard')}
                    </Typography>
                  )}
                </Paper>
              </Fade>
            )}
          </Popper>
          <Box
            display={'flex'}
            sx={styles.pointer}
            alignItems={'center'}
            onClick={handleClickReports}>
            <Typography variant="body1" whiteSpace={'nowrap'}>
              {t('reports')}
            </Typography>
            <Box sx={styles.icon}>
              <DotsIcon height={27} width={7} />
            </Box>
          </Box>
        </Box>
      </ClickAwayListener>
    ),
    [
      isOpenReports,
      handleClickReports,
      anchorEl,
      toggleUsageReportsModal,
      toggleHistoryModal,
      toggleSalesByCardModal,
      t,
      isAllowedForAdmin,
    ],
  );

  /**
   * Plan
   */
  const plan = useMemo(() => {
    let result = account?.accountPlan.plan.name as string;

    if (isPoolPlan) {
      if (account?.subscriptionInfo) {
        result = `${result} (${formatMoney(roundNumber(account.subscriptionInfo.amount))} - ${
          account.subscriptionInfo.cancelAtPeriodEnd
            ? `${t('autoRenew')} ${t('off')}`
            : `${t('autoRenew')}`
        })`;
      } else {
        result = `${result} (${t('notActivated')})`;
      }
    }

    return (
      <Typography variant="body1" marginTop={0.5} noWrap sx={styles.plan}>
        {result}
      </Typography>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  /**
   * Status
   */
  const Status = useMemo(
    () => (
      <CustomLoadingSwitch
        isFetching={isUpdateFetching}
        checked={account?.active}
        onChange={(_, active) => updateAccount(account?.id as string, { active })}
        titleTranslationKey="additionalBlocks.accountStatus"
        data={account}
      />
    ),
    [account, isUpdateFetching, updateAccount],
  );

  const handleCountCreditPlan = useCallback(
    (e) => {
      const value = Number(roundNumber(e.target.value));

      if (isConditionDotsInString(e.target.value)) {
        setToPayValue(e.target.value);
      } else {
        const isConditionValue = value <= Number(account?.accountPlan.toPay) && value >= 0;

        if (isConditionValue || isPoolPlan) {
          updateNewDeposit(account?.id as string, 'account', value);
          setToPayValue(value);
          isConditionValue &&
            setDiscountValue(roundNumber(roundNumber(account?.accountPlan.toPay ?? 0) - value));
        }
      }
    },
    [account?.accountPlan.toPay, account?.id, isPoolPlan, updateNewDeposit],
  );

  const cardPayButton = useMemo(
    () => (
      <CardsPay toPayValue={toPayValue} discount={discountValue} setToPayValue={setToPayValue} />
    ),
    // eslint-disable-next-line
    [toPayValue, discountValue, toPayDeposit, setToPayValue]);

  /**
   * Pay
   */
  const pay = useMemo(
    () => (
      <Box sx={styles.boxPay}>
        <Box
          display="flex"
          alignItems="center"
          margin={isCustomerRole ? { xs: 0, sm: '0 auto' } : '0'}
          paddingX={{ xs: 0, sm: 1 }}
          height="100%">
          {isWaitingPayment && !isDeleteFetching ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'space-between',
              }}>
              <Typography sx={{ ...styles.title, color: '#FF6107' }}>{t('pending')}</Typography>
              <Typography sx={styles.value} fontWeight="bold">
                ${transactionCount?.amount ? roundNumber(transactionCount?.amount) : ''}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'space-between',
              }}>
              <Typography variant="body1" fontWeight="medium" sx={styles.title}>
                {t('pay')}
              </Typography>

              <Typography variant="body1" fontWeight="bold" sx={styles.value}>
                {isBillingDay || isPoolPlan ? (
                  <TextField
                    InputProps={{ sx: styles.input }}
                    value={toPayValue}
                    onChange={handleCountCreditPlan}
                    onBlur={handleCountCreditPlan}
                  />
                ) : (
                  <>{formatMoney(deposit)}</>
                )}
              </Typography>
            </Box>
          )}
        </Box>

        {isWaitingPayment && (
          <Box mt={isDeleteFetching ? 1 : 0}>
            <SmallButton
              color="primary"
              sx={{ ...styles.button, ml: 1 }}
              onClick={deletePayment}
              isFetching={isDeleteFetching}>
              {t('cancel')}
            </SmallButton>
          </Box>
        )}
      </Box>
    ),

    [
      isCustomerRole,
      isWaitingPayment,
      isDeleteFetching,
      t,
      transactionCount?.amount,
      isBillingDay,
      isPoolPlan,
      toPayValue,
      handleCountCreditPlan,
      deposit,
      deletePayment,
    ],
  );

  /**
   * Bonus
   */
  const bonus = useMemo(
    () => (
      <Box sx={styles.bonusDiscount}>
        <Typography variant="body1" sx={styles.title} fontWeight="medium">
          {t('bonus')}
        </Typography>
        <Typography variant="body1" sx={styles.value}>
          {listenTransaction
            ? formatMoney(Number(transactionCount?.bonus))
            : formatMoney(Number(customBonus))}
        </Typography>
      </Box>
    ),
    // eslint-disable-next-line
    [customBonus, transactionCount],
  );

  const discount = useMemo(
    () => (
      <Box display="flex" justifyContent="space-between" flexDirection="column" height={'100%'}>
        <Typography variant="body1" sx={styles.title} fontWeight="medium">
          {t('discount')}
        </Typography>
        <Typography variant="body1" sx={styles.value}>
          ${discountValue}
        </Typography>
      </Box>
    ),
    [discountValue, t],
  );

  const billingDate = useMemo(() => {
    let isRendered = false;
    let dt = new Date();
    let title = t('billingDate');

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (isMonthlyPlan) {
      isRendered = Boolean(account?.accountPlan.nextBillingDate);
      dt = new Date(account?.accountPlan.nextBillingDate ?? 0);
    } else if (isPoolPlan) {
      if (account?.subscriptionInfo?.cancelAtPeriodEnd) {
        title = t('subscriptionEnds');
      }

      isRendered = Boolean(account?.subscriptionInfo?.currentPeriodEnd);
      dt = new Date(account?.subscriptionInfo?.currentPeriodEnd ?? 1);
    }

    const dtDateOnly = dt && zonedTimeToUtc(dt, timezone);

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {isRendered && (
          <Box
            display="flex"
            alignItems="center"
            // justifyContent="space-between"
            paddingRight={1}
            width="100%">
            <Typography variant="body1" color="text.main" fontWeight="bold" textAlign={'left'}>
              {title}:
            </Typography>
            <Typography variant="body1" textAlign={'right'} ml={0.55}>
              {' '}
              {format(dtDateOnly, dateFormat)}
            </Typography>
          </Box>
        )}
      </>
    );
  }, [account, t, isMonthlyPlan, isPoolPlan]);

  const autoChargeBlock = useMemo(
    () => (
      <CustomLoadingSwitch
        isFetching={isFetchingAutoCharge}
        checked={account?.accountPlan.autoCharge}
        onChange={(_, autoCharge) => updateAutoCharge(account?.id as string, autoCharge)}
        titleTranslationKey="additionalBlocks.autoCharge"
        disabled={!paymentMethod?.cardLast4}
        data={account}
      />
    ),
    [account, isFetchingAutoCharge, updateAutoCharge, paymentMethod],
  );

  const SpecialPay = useMemo(
    () => (
      <Box sx={styles.boxPay}>
        <Box sx={{ height: '100%' }}>{pay}</Box>
        {!isWaitingPayment && <Box sx={{ alignSelf: 'flex-end' }}>{cardPayButton}</Box>}
      </Box>
    ),
    [cardPayButton, pay, isWaitingPayment],
  );

  const LastPayment = useMemo(
    () =>
      account?.accountPlan.lastPaymentDay && account?.accountPlan.lastPaymentAmount ? (
        <Typography variant="body1">
          ${account?.accountPlan.lastPaymentAmount.toFixed(2)} -{' '}
          {format(new Date(account?.accountPlan.lastPaymentDay), dateFormat)}
        </Typography>
      ) : (
        // eslint-disable-next-line prettier/prettier
          <Box sx={{ height: '24px' }} />
      ),
    [account],
  );

  const subInfo = useMemo(() => <SubscriptionModal />, []);

  /**
   * First blocks row
   */
  const topBlocks = useMemo(() => {
    const customPlanBar = [
      {
        label: `${t('additionalBlocks.totalBalance')}`,
        value: balance,
      },
      { children: SpecialPay },
      { children: bonus },
    ] as IBlockItem[];

    const monthlyPlanBar = [
      {
        label: `${t('additionalBlocks.totalUsage')}`,
        value: balance,
      },
      { children: SpecialPay },
      { children: discount },
      { label: `${t('additionalBlocks.lastPayment')}`, value: LastPayment },
    ] as IBlockItem[];

    const coopPlanBar = [
      {
        label: `${t('additionalBlocks.totalBalance')}`,
        value: balance,
      },
      {
        label: `${t('additionalBlocks.toDeposit')}`,
        value: <ToDeposite id={account?.id as string} />,
      },
      { children: SpecialPay },
      { children: bonus },
      { label: `${t('additionalBlocks.lastPayment')}`, value: LastPayment },
    ];

    const poolPlanBar = (isSubActive: boolean) => {
      if (isSubActive) {
        return [
          {
            label: `${t('additionalBlocks.availableBalance')}`,
            value: balance,
          },
          { children: subInfo },
          { children: SpecialPay },
        ] as IBlockItem[];
      }

      return [{ children: subInfo }] as IBlockItem[];
    };

    if (isAllowedForAdmin) {
      if (isMonthlyPlan) {
        if (isFleetPlan) {
          monthlyPlanBar.push({ children: autoChargeBlock });
        }

        return insertElement<IBlockItem>(monthlyPlanBar, { children: Status }, 4);
      }

      if (isCustomPlan) {
        return insertElement<IBlockItem>(customPlanBar, { children: Status }, 3);
      }

      if (isPoolPlan) {
        return insertElement<IBlockItem>(
          poolPlanBar(Boolean(account?.subscriptionInfo)),
          { children: Status },
          1,
        );
      }

      if (isCoopPlan) {
        return insertElement<IBlockItem>(coopPlanBar, { children: Status }, 5);
      }

      return [];
    }

    if (isCustomPlan) {
      return customPlanBar;
    }

    if (isMonthlyPlan) {
      if (isFleetPlan) {
        monthlyPlanBar.push({ children: autoChargeBlock });
      }

      return monthlyPlanBar;
    }

    if (isPoolPlan) {
      return poolPlanBar(Boolean(account?.subscriptionInfo));
    }

    if (isCoopPlan) {
      return coopPlanBar;
    }

    return [];
  }, [
    t,
    balance,
    SpecialPay,
    bonus,
    discount,
    LastPayment,
    account?.id,
    account?.subscriptionInfo,
    isAllowedForAdmin,
    isCustomPlan,
    isMonthlyPlan,
    isPoolPlan,
    isCoopPlan,
    subInfo,
    isFleetPlan,
    Status,
    autoChargeBlock,
  ]);

  const getGridSizeXs = (index: number) => {
    if (!IS_MOBILE) {
      return true;
    }

    if (isCustomPlan) {
      return index % 2 ? 9 : 3;
    }

    if (isMonthlyPlan) {
      return index % 2 ? 8 : 4;
    }
  };

  return (
    <Grid container sx={styles.wrapper}>
      {!account ? (
        <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress size={28} color="inherit" />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              width: '100%',
              flexDirection: { xs: 'column', lg: 'row' },
            }}>
            <Grid
              container
              sx={styles.topBlocks}
              wrap={IS_MOBILE ? 'wrap' : 'nowrap'}
              rowSpacing={{ xs: 1.5, sm: 0 }}
              gap={{ sm: 6 }}>
              <Grid item xs={6} lg={3} sx={{ display: 'flex' }}>
                <Box sx={styles.infoPlan}>
                  <Box>
                    <Typography noWrap>
                      <span style={{ fontWeight: 'bold' }}>
                        {t('additionalBlocks.dateCreated')}
                      </span>
                      :{' '}
                      {account?.accountPlan.createdAt &&
                        `${format(new Date(account?.accountPlan.createdAt), dateFormat)}`}
                    </Typography>
                    <Typography noWrap>
                      <span style={{ fontWeight: 'bold' }}>{t('plan')}</span>:{' '}
                      <Typography ml={0.5}>{plan}</Typography>
                    </Typography>
                    <Typography noWrap>{(isMonthlyPlan || isPoolPlan) && billingDate}</Typography>
                  </Box>
                  {!(IS_MOBILE && !isAllowedForAdmin) && <Box>{getReports}</Box>}
                </Box>
                {!IS_MOBILE && (
                  <Box
                    sx={{
                      width: '1px',
                      height: '3.5rem',
                      backgroundColor: ({ palette }: any) =>
                        palette.mode === 'light' ? '#DDDDDD' : 'white',
                    }}
                  />
                )}
              </Grid>
              {topBlocks.map(({ label, value, Icon, children }: IBlockItem, index) => {
                if (children || label || value || Icon) {
                  return (
                    <Grid
                      item
                      xs={getGridSizeXs(index)}
                      md
                      width={IS_MOBILE && isPoolPlan && index === 1 ? '100%' : undefined}
                      key={label}>
                      <BlockItem
                        key={label}
                        Icon={Icon}
                        label={label}
                        value={value}
                        children={children}
                      />
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Box>

          <TransactionsModal ref={transactionsModal} isAdmin={isAllowedForAdmin} />
          <GetReportModal
            title={`${t('transactionsModal.titleGetUsage')}`}
            modalRef={usageReportsModal}
            toggleModal={toggleUsageReportsModal}
            reportType={ReportType.Usages}
          />

          <GetReportModal
            title={`${t('additionalBlocks.salesByCard')}`}
            modalRef={salesByCardModal}
            toggleModal={toggleSalesByCardModal}
            reportType={ReportType.SalesByCard}
          />
        </>
      )}
    </Grid>
  );
};

export default AdditionalBlocks;
