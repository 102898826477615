import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { getAccount } from '@store/accounts/action-creators';

export const mapStateToProps = (state: IState) =>
  ({
    subInfo: state.accounts.item.result?.subscriptionInfo,
    accountId: state.accounts.item.result?.id,
  } as const);

export const mapDispatchToProps = { getAccount };

export type ISubModalStateToProps = ReturnType<typeof mapStateToProps>;

export type ISubModalDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
