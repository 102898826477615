/* eslint-disable @typescript-eslint/default-param-last */
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import TYPES from './action-types';

export interface IForgotState {
  isConfirmed: IReducerState<boolean>;
  isSendEmail: IReducerState<boolean>;
  verifiedData: IReducerState<{ email: string; oobCode: string } | null>;
}

const initState: IForgotState = {
  isConfirmed: {
    result: false,
    isFetching: false,
    error: null,
  },
  isSendEmail: {
    result: false,
    isFetching: false,
    error: null,
  },
  verifiedData: { result: null, isFetching: false, error: null },
};

/**
 * Forgot reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>) => {
  switch (type) {
    case TYPES.FORGOT_SEND_EMAIL:
      return {
        ...state,
        isSendEmail: { ...state.isSendEmail, isFetching: true },
      };

    case TYPES.FORGOT_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isSendEmail: { ...state.isSendEmail, result: true },
      };

    case TYPES.FORGOT_SEND_EMAIL_ERROR:
      return {
        ...state,
        isSendEmail: { ...state.isSendEmail, error: payload.message },
      };

    case TYPES.FORGOT_VERIFY:
      return {
        ...state,
        verifiedData: { result: null, error: null, isFetching: true },
      };

    case TYPES.FORGOT_VERIFY_SUCCESS:
      return {
        ...state,
        verifiedData: { result: payload, isFetching: false, error: null },
      };

    case TYPES.FORGOT_VERIFY_ERROR:
      return {
        ...state,
        verifiedData: { result: null, error: payload.message, isFetching: false },
      };

    case TYPES.FORGOT_CONFIRM:
      return {
        ...state,
        isConfirmed: {
          ...state.isConfirmed,
          isFetching: true,
        },
      };

    case TYPES.FORGOT_CONFIRM_SUCCESS:
      return {
        ...state,
        isConfirmed: { ...state.isConfirmed, result: true },
      };

    case TYPES.FORGOT_CONFIRM_ERROR:
      return {
        ...state,
        isConfirmed: { ...state.isConfirmed, error: payload.message },
      };

    case TYPES.CLEAR_STORE:
      return { ...initState };

    default:
      return state;
  }
};
