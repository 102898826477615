import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '@components/dropdown';
import {
  IToDepositDispatchToProps,
  IToDepositStateToProps,
} from '@components/to-deposite/index.props';
import depositDropDown from '@constants/pay-params/deposite';
import formatMoney from '@helpers/format-money';
import { ICard } from '@interfaces/cards/i-card';
import { IDeposit } from '@interfaces/deposits/i-deposit';
import styles from './styles';

type IProps = IToDepositStateToProps & IToDepositDispatchToProps & { id: string; card?: ICard };

const ToDeposite: FC<IProps> = ({
  id,
  card,
  deposit,
  depositsList,
  isFetchingCards,
  isDeleteFetching,
  isFetchingAccountUpdate,
  updateNewDeposit,
  userRoles,
  listenTransaction,
}) => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (card) {
      if (card.active) {
        setValue(deposit[card.id]);
      } else {
        setValue(0);
      }
    } else if (deposit[id] || deposit[id] === 0) {
      setValue(deposit[id]);
    } else {
      setValue(0);
      updateNewDeposit(id, 'account', 0, 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card, deposit]);

  const isTransaction = listenTransaction?.length || isDeleteFetching;

  /**
   * On click menu item
   */
  const onClickItem = useCallback(
    (v: IDeposit) => {
      if (card) {
        updateNewDeposit(card.id, 'card', v.amount, v.bonus);
      } else {
        updateNewDeposit(id, 'account', v.amount, v.bonus);
      }
    },
    [id, card, updateNewDeposit],
  );

  const sxStyle = {
    ...styles.box,
    ...((card && !card?.active) || isFetchingAccountUpdate || isFetchingCards
      ? styles.disabled
      : ({} as any)),
    ...(Boolean(isTransaction) && styles.disabled),
  };

  const hintDisabled = !isDeleteFetching
    ? // eslint-disable-next-line unicorn/no-nested-ternary
      card && !card?.active
      ? t('toDepositCell.hintDisabledFalse')
      : t('toDepositCell.hintDisabledTrue')
    : t('toDepositCell.fetchingRemoveTransaction');

  const renderItem = (item: IDeposit) =>
    item.amount
      ? `${formatMoney(item.amount)} ${
          item?.bonus ? `(${formatMoney(item?.bonus)} ${t('bonus').toLowerCase()})` : ''
        }`
      : t('none');

  return (
    <Box display="flex" alignItems="center">
      <Box sx={sxStyle} bgcolor={({ palette }) => (palette.mode === 'light' ? 'white' : '#1F1E1E')}>
        <Typography
          color={
            (card && !card?.active) || isFetchingAccountUpdate || isFetchingCards
              ? 'text.disabled'
              : ({ palette }) => (palette.mode === 'light' ? '#3C4977' : '#607EB5')
          }
          fontSize="13px"
          {...(value && { fontWeight: 'bold' })}>
          {value ? formatMoney(value) : t('add').toUpperCase()}
        </Typography>
      </Box>

      <Dropdown<IDeposit>
        id={id}
        isDisabled={
          Boolean(isTransaction) ||
          (card && !card?.active) ||
          isFetchingAccountUpdate ||
          isFetchingCards
        }
        renderItem={(item) => renderItem(item)}
        hint={hintDisabled}
        handleClickItem={onClickItem}
        data={depositDropDown(depositsList)}
        userRoles={userRoles}
      />
    </Box>
  );
};

export default ToDeposite;
