/* eslint-disable @typescript-eslint/default-param-last */
import { IDeposit } from '@interfaces/deposits/i-deposit';
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import { ITableList } from '@interfaces/redux/i-table-list';
import TYPES from './action-types';

export interface IDepositsState {
  list: IReducerState<IDeposit[]> & ITableList;
  item: {
    deposit: Record<string, number>;
    customBonus: Record<string, number>;
    forEntity: 'account' | 'card' | null;
  };
}

const initState: IDepositsState = {
  list: {
    result: [],
    isFetching: false,
    error: null,
    searchValue: '',
    pagination: { total: 0, page: 1, size: 10 },
    sorting: {},
    dates: { startDate: null, endDate: null },
  },
  item: {
    deposit: {},
    customBonus: {},
    forEntity: null,
  },
};

/**
 * Deposits reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>) => {
  switch (type) {
    // list
    case TYPES.GET_DEPOSITS_LIST:
      return { ...state, list: { ...state.list, isFetching: true } };

    case TYPES.GET_DEPOSITS_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          result: payload.list,
          isFetching: false,
        },
      };

    case TYPES.GET_DEPOSITS_LIST_ERROR:
      return {
        ...state,
        list: { ...state.list, isFetching: false, error: payload.message },
      };

    // additional
    case TYPES.UPDATE_NEW_DEPOSIT:
      return {
        ...state,
        item: {
          deposit: {
            ...state.item.deposit,
            [payload.id]: payload.value,
          },
          customBonus: {
            ...state.item.customBonus,
            [payload.id]: payload.customBonus,
          },
          forEntity: payload.forEntity,
        },
      };

    case TYPES.CLEAR_NEW_DEPOSIT:
      return { ...state, item: { deposit: {}, customBonus: {}, forEntity: null } };

    default:
      return state;
  }
};
