import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import bannerBlack from '@assets/images/icons/banner-background-black.svg';
import bannerWhite from '@assets/images/icons/banner-background.svg';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  wrapper: {
    position: 'relative',
    width: '100%',
    // maxWidth: '32%',
    maxWidth: {
      xs: '100%',
      sm: '36%',
      md: '32%',
    },
    height: { xs: '13rem', sm: '100%' },
    color: 'white',
    // padding: '30px 60px',
    padding: {
      xs: '20px 25px',
      md: '30px 60px',
    },
    fontWeight: '400',
    display: 'flex',
    flexDirection: 'column',
    background: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : '#1F1E1E'),
    zIndex: 1,

    ['@media (max-width:1350px)']: {
      // padding: '25px 30px',
      // maxWidth: '40%',
    },
  } as SxProps<Theme>,

  banner: {
    width: '100%',
    height: '100%',
    backgroundImage: ({ palette }) =>
      palette.mode === 'light' ? `url(${bannerWhite})` : `url(${bannerBlack})`,
    position: 'absolute',
    top: 0,
    rigth: 0,
    left: 0,
    bottom: 0,
    zIndex: -1,
  } as SxProps<Theme>,

  infoHeader: {
    lineHeight: '35px',
    marginTop: {
      xs: '2.5rem',
      sm: 0,
    },
  } as SxProps<Theme>,
  infoManage: {
    fontSize: {
      xs: '18px',
      sm: '21px',
    },
    lineHeight: '28px',
  } as SxProps<Theme>,
  // bottomInfo: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   fontSize: '14px',
  //   lineHeight: '16px',
  //   zIndex: 1,
  // } as SxProps<Theme>,

  itemInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '18px',

    '& > svg': {
      marginRight: '10px',
    },
  } as SxProps<Theme>,
};

export default styles as IStyles<typeof styles>;
