/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable  @typescript-eslint/no-unnecessary-type-assertion */
import { Autocomplete, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExportToExcel, { ExcelReportOptions } from '@components/create-excel/create-excel';
import BasicDateRangePicker from '@components/date-range-picker';
import { TitledBlock } from '@components/index';
import generateExcelOptions from '@helpers/generate-excel-options';
import ReportType from 'src/enums/type-reports';
import SaveMode from '../../../enums/save-mode';
import { ICardUpdateDispatchToProps, ICardUpdateStateToProps } from './index.props';
import styles from './styles';

type IProps = ICardUpdateStateToProps & ICardUpdateDispatchToProps;

/**
 * CardsUpdate
 * @constructor
 */
const ReportsAllClients: FC<IProps> = ({ updateTransactionListDatesWithoutRefetch, dates }) => {
  const { t } = useTranslation();
  const [currentReport, setCurrentReport] = useState<string>('');
  const [isClearDates, setIsClearDates] = useState<boolean>(false);
  const [excelOptions, setExcelOptions] = useState<ExcelReportOptions | null>(null);
  const options = Object.entries(ReportType).map(([id, label]) => ({ id, label }));

  useEffect(() => {
    if (currentReport) {
      setExcelOptions(generateExcelOptions(currentReport as ReportType, dates));
    }
  }, [currentReport, dates]);

  const isConditionForExcelBtn =
    dates?.startDate &&
    dates?.endDate &&
    Date.parse(dates?.endDate) >= Date.parse(dates?.startDate) &&
    currentReport;

  const handleClearExcel = () => {
    setIsClearDates(true);
    setTimeout(() => setIsClearDates(false), 0);
  };

  return (
    <TitledBlock title={t('reportsAllClients.title')}>
      <Box sx={styles.box}>
        <Typography color="text.primary" sx={{ fontWeight: 'bold' }}>
          {t('reportsAllClients.description')}
        </Typography>
        <Box my={'auto'}>
          <Box sx={{ width: '90%', margin: '0 auto', flexWrap: 'wrap' }}>
            <BasicDateRangePicker
              sxRow={styles.sxRow}
              sxContainer={styles.sxContainer}
              titleFrom="from"
              onChange={updateTransactionListDatesWithoutRefetch}
              isClearDates={isClearDates}
            />
          </Box>
          <Box sx={styles.boxRunReport}>
            <Box sx={{ width: '30%' }}>
              <Autocomplete
                sx={styles.sxAutocomplete}
                options={options}
                disableClearable
                onInputChange={(_, newValue) => setCurrentReport(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"
                    sx={styles.sxAutocompleteTextField}
                    placeholder={`${t('reportsAllClients.placeholder')}`}
                    variant="standard"
                    inputProps={{
                      paddingLeft: '5px',
                      input: {
                        textAlign: 'center',
                        paddingLeft: '7px',

                        '&::placeholder': {
                          color: 'rgba(0, 0, 0, 0.87)',
                          opacity: 1,
                        },
                      },
                      ...params.inputProps,
                      readOnly: true,
                      '&:placeholder': {
                        color: '#3C4977',
                      },
                    }}
                  />
                )}
              />
            </Box>

            <ExportToExcel
              disabled={!isConditionForExcelBtn && !excelOptions}
              titleBtn={'runReport'}
              options={excelOptions!}
              saveMode={SaveMode.OnClick}
              onFinish={handleClearExcel}
            />
          </Box>
        </Box>
      </Box>
    </TitledBlock>
  );
};

export default ReportsAllClients;
