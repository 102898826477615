import IState from '@interfaces/redux/i-state';
import {
  applyBonusCard,
  getBonusCardBalance,
  bonusCardClear,
} from '@store/bonus-cards/action-creators';

export const mapStateToProps = (state: IState) =>
  ({
    balance: state.bonusCards.balance,
    applyCard: state.bonusCards.applyCard,
  } as const);

export const mapDispatchToProps = {
  getBonusCardBalance,
  applyBonusCard,
  bonusCardClear,
};
