import type { SagaIterator } from '@redux-saga/core';
import { fork, delay, put, select, takeLatest, call, takeEvery } from 'redux-saga/effects';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import { onUpdateEssenceSuccess, onUpdateListFork } from '@helpers/sagas';
import type { IAction } from '@interfaces/redux/i-action';
import notistack from '@services/notistack-service';
import RoleType from 'src/enums/role-type';
import i18n from '../../i18n';
import { awaitUserToken } from '../tenant/sagas';
import {
  addCardError,
  addCardsError,
  addCardsSuccess,
  addCardSuccess,
  deleteCardError,
  deleteCardSuccess,
  getCardsList,
  getCardsListError,
  getCardsListSuccess,
  nayaxCardsSyncError,
  nayaxCardsSyncSuccess,
  setPaymentTypeSuccess,
  transferAnotherCardResponse,
  updateCardError,
  updateCardSuccess,
} from './action-creators';
import TYPES from './action-types';
import { getCardsListApi, nayaxCardsSyncApi, updateCardApi, transferAnotherCardApi } from './api';
import type { ICardsState } from './reducer';

/**
 * Get cards list
 */
function* getCards(): SagaIterator {
  try {
    yield call(awaitUserToken);
    yield delay(500);
    const accountId: string | undefined = yield select((state) => state.accounts.item.result?.id);
    const userRoles: RoleType[] = yield select((state) => state.tenant.claims?.roles);
    const pagination: ICardsState['list']['pagination'] = yield select(
      (state) => state.cards.list.pagination,
    );
    const search: string = yield select((state) => state.cards.list.searchValue);
    const sorting = yield select((state) => state.cards.list?.sorting);
    const [sort, isDesc] = Object.entries(sorting ?? {})?.[0] ?? [];
    let result = {
      data: [],
      currentPage: 1,
      count: 0,
      size: 1000,
      lastPage: 1,
      nayaxWarningMessage: null,
    };
    const isAllowedForAdmin = isAllowedForUser([RoleType.Admin], userRoles);

    if (accountId || isAllowedForAdmin) {
      result = yield call(getCardsListApi, {
        page: pagination?.page ?? 1,
        size: pagination.size,
        order: 1,
        relations: ['account'],
        withAttached: !accountId,
        accountId,
        ...(sort !== 'undefined' && { sort, order: isDesc ? 'Descending' : 'Ascending' }),
        ...(search && { search: `%${search}%` }),
        ...(accountId && { size: 1000, sort: 'name', order: 'Ascending' }),
      });
    }

    if (result.nayaxWarningMessage) {
      notistack.warning(result.nayaxWarningMessage);
    }

    const isCurrentPageMoreThenLastPage =
      Number(result.lastPage) && Number(pagination.page) > Number(result.lastPage);

    yield put(
      getCardsListSuccess(result.data, {
        page: isCurrentPageMoreThenLastPage ? 1 : Number(result.currentPage),
        total: result.count,
        size: pagination.size,
      }),
    );

    if (isCurrentPageMoreThenLastPage) {
      yield put(getCardsList());
    }
  } catch (e) {
    yield put(getCardsListError(e.message));
  }
}

/**
 * Update card
 */
function* updateCard({ payload: { id, newData, successCallback } }: IAction<TYPES>): SagaIterator {
  try {
    const cards: ICardsState['list']['result'] = yield select((state) => state.cards.list.result);
    const result = yield call(updateCardApi, id, newData);

    yield put(updateCardSuccess(result));
    successCallback?.();
    const cardName = cards.find((v) => v.id === id)?.name;

    notistack.success(
      `${i18n.t('card')} #${String(cardName ?? id)} ${i18n.t('successfullyUpdated')}`,
    );
  } catch (e) {
    yield put(updateCardError(e.message));
  }
}

/**
 * Delete card
 */
function* deleteCard({ payload: { id, successCallback } }: IAction<TYPES>): SagaIterator {
  try {
    yield call(updateCardApi, id, { accountId: null, transactions: [] });

    yield put(getCardsList());
    yield put(deleteCardSuccess());
    successCallback?.();
  } catch (e) {
    yield put(deleteCardError(e.message));
  }
}

/**
 * Add card
 */
function* addCard({ payload: { successCallback } }: IAction<TYPES>): SagaIterator {
  try {
    yield delay(1000);
    yield put(getCardsList());
    yield put(addCardSuccess());
    successCallback?.();
  } catch (e) {
    yield put(addCardError(e.message));
  }
}

/**
 * Add cards
 */
function* addCards({ payload: { ids, successCallback } }: IAction<TYPES>): SagaIterator {
  try {
    const accountId: string | undefined = yield select((state) => state.accounts.item.result?.id);

    if (!accountId) {
      throw new Error('account id is undefined');
    }

    for (const id of ids) {
      yield call(updateCardApi, id, { accountId, transactions: [], active: true });
    }

    yield put(getCardsList());
    yield put(addCardsSuccess());
    successCallback?.();
  } catch (e) {
    yield put(addCardsError(e.message));
  }
}

/**
 * Nayax cards sync
 */
function* nayaxCardsSync(): SagaIterator {
  try {
    yield call(nayaxCardsSyncApi);

    yield put(nayaxCardsSyncSuccess());
    notistack.success(i18n.t('sagaCard.cardSuccessUpdate'));
  } catch (e) {
    yield put(nayaxCardsSyncError(e.message));
  }
}

/**
 * Set payment type with additional conditions
 */
function* setPaymentType({ payload: { type } }: IAction<TYPES>): SagaIterator {
  yield put(setPaymentTypeSuccess(type));
}

/**
 * Set transfer another card
 */
function* transferAnotherCard({ payload }: any) {
  try {
    yield call(transferAnotherCardApi, payload);
    yield put(transferAnotherCardResponse(TYPES.TRANSFER_CARD_SUCCESS));
  } catch (e) {
    yield put(transferAnotherCardResponse(TYPES.TRANSFER_CARD_ERROR));
  }
}

/**
 * On update card success
 */
function* onUpdateCard(): SagaIterator {
  yield call(onUpdateEssenceSuccess, 'cards', TYPES.UPDATE_CARD_SUCCESS, getCardsList);
}

/**
 * On update pagination
 */
function* onUpdatePagination(): SagaIterator {
  yield call(
    onUpdateListFork,
    'cards',
    'pagination',
    TYPES.UPDATE_CARDS_LIST_PAGINATION,
    getCardsList,
  );
}

/**
 * On update search
 */
function* onUpdateSearch(): SagaIterator {
  yield call(onUpdateListFork, 'cards', 'searchValue', TYPES.UPDATE_CARDS_SEARCH, getCardsList);
}

/**
 * On update sorting
 */
function* onUpdateSorting(): SagaIterator {
  yield call(onUpdateListFork, 'cards', 'sorting', TYPES.UPDATE_CARDS_SORTING, getCardsList);
}

function* onTransferAnotherCard(): SagaIterator {
  yield takeEvery(TYPES.TRANSFER_ANOTHER_CARD, transferAnotherCard);
}

export default [
  takeLatest(TYPES.GET_CARDS_LIST, getCards),
  takeLatest(TYPES.UPDATE_CARD, updateCard),
  takeLatest(TYPES.DELETE_CARD, deleteCard),
  takeLatest(TYPES.ADD_CARD, addCard),
  takeLatest(TYPES.ADD_CARDS, addCards),
  takeLatest(TYPES.NAYAX_CARDS_SYNC, nayaxCardsSync),
  takeLatest(TYPES.SET_PAYMENT_TYPE, setPaymentType),
  fork(onUpdateCard),
  fork(onUpdatePagination),
  fork(onUpdateSearch),
  fork(onUpdateSorting),
  fork(onTransferAnotherCard),
];
