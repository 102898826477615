import { ClickAwayListener, Fade, Paper, Popper } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { FC, MouseEventHandler, useCallback, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
// import { Modal, SmallButton } from '@components/index';
// import { IModalForward } from '@components/modal';
// import IS_MOBILE from '@constants/is-mobile';
import RoleType from '../../../enums/role-type';
import styles from './styles';

type MenuItem = {
  translationKey: string;
  onClick?: MouseEventHandler<HTMLSpanElement> | undefined;
  values?: Record<string, string>;
  sxProps?: SxProps<Theme>;
};

type Props = {
  logout: any;
  initials: string;
  accountName: string;
  role?: RoleType;
};
const UserMenu: FC<Props> = ({ logout, initials, accountName, role }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  //todo uncomment
  // const modalAboutRef = useRef<IModalForward | null>(null);
  //
  // const toggleAboutModal = useCallback(() => modalAboutRef.current?.toggleModal(), []);

  const handleClick: any = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setIsOpen(!isOpen);
    },
    [isOpen],
  );

  const menuItems: MenuItem[] = [
    {
      translationKey: role ? `roles.${role}` : '',
      values: { accountName, deviceLimit: role ? `roles.${role}` : '' },
      sxProps: {
        cursor: 'default',
        fontWeight: 550,
        '&:hover': {
          background: 'white',
        },
      },
    },
    // {
    //   translationKey: 'userMenu.help',
    //   onClick: () => {
    //     window.open('https://www.google.com.ua/', '_blank');
    //   },
    // },
    // {
    //   translationKey: 'userMenu.about',
    //   onClick: toggleAboutModal,
    // },
    {
      translationKey: 'userMenu.signOut',
      onClick: logout,
    },
  ];

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Box>
        <Popper
          open={isOpen}
          anchorEl={anchorEl}
          transition
          placement="bottom"
          sx={{ py: 2, px: 1, zIndex: 9999 }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200}>
              <Paper sx={styles.paper}>
                {menuItems.map(({ onClick, translationKey, values, sxProps }) => {
                  let sx = { ...styles.textPopup };

                  if (sxProps) {
                    sx = { ...sx, ...sxProps };
                  }

                  return (
                    <Typography key={translationKey} sx={sx} onClick={onClick}>
                      <Trans t={t} i18nKey={translationKey} values={values} />
                    </Typography>
                  );
                })}
              </Paper>
            </Fade>
          )}
        </Popper>

        <Box display={'flex'} sx={styles.pointer} alignItems={'center'} onClick={handleClick}>
          <Box sx={styles.icon}>
            <Typography fontSize={'20px'} variant="body1" whiteSpace={'nowrap'}>
              {initials}
            </Typography>
          </Box>
        </Box>
        {/*<Modal*/}
        {/*  ref={modalAboutRef}*/}
        {/*  height={'auto'}*/}
        {/*  customStyle={IS_MOBILE ? { maxHeight: '90%', overflow: 'auto' } : { left: '65%' }}*/}
        {/*  width={IS_MOBILE ? undefined : '780px'}>*/}
        {/*  <SmallButton onClick={toggleAboutModal} variant="outlined" sx={{ width: '180px' }}>*/}
        {/*    {t('cancel')}*/}
        {/*  </SmallButton>*/}
        {/*</Modal>*/}
      </Box>
    </ClickAwayListener>
  );
};

export default UserMenu;
