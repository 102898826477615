import { request } from '@helpers/request';

/**
 * Get subscription prices
 */
export const getSubscriptionPricesApi = () => request('subscription/prices');

type SubscriptionPayload = {
  // eslint-disable-next-line camelcase
  payment_method: string;
  priceId: string;
};

/**
 * Create subscription
 */
export const createSubscription = (accountId: string, body: SubscriptionPayload) =>
  request(`subscription/${accountId}`, undefined, body, 'POST');

export const cancelSubscription = (accountId: string) =>
  request(`subscription/${accountId}`, undefined, undefined, 'DELETE');

export const updateSubscription = (accountId: string, body: Partial<SubscriptionPayload>) =>
  request(`subscription/${accountId}`, undefined, body, 'PATCH');
