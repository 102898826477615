import { request } from '@helpers/request';
import { ICard } from '@interfaces/cards/i-card';

/**
 * Get card's list
 */
export const getCardsListApi = (params?: Record<string, any>) => request(`cards/`, params);

/**
 * Create card
 */
export const createCardApi = (body: Record<string, any>, accountId?: number) =>
  request(`cards/${String(accountId)}`, undefined, body, 'POST');

/**
 * Update card
 */
export const updateCardApi = (id: string, body?: Partial<ICard> | Record<string, any>) =>
  request(`cards/${id}`, undefined, body, 'PATCH');

/**
 * Delete card
 */
export const deleteCardApi = (id: string, accountId?: number) =>
  request(`cards/${String(accountId)}/${id}`, undefined, undefined, 'DELETE');

/**
 * Nayax cards sync
 */
export const nayaxCardsSyncApi = () => request(`nayax/cards/synchronize`);

/**
 * Transfer another card
 */
export const transferAnotherCardApi = (data: any) =>
  request(`cards/transfer`, undefined, data, 'PUT');
