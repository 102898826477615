import { FirebaseApp, FirebaseOptions } from '@firebase/app';
import { UserCredential } from '@firebase/auth';
import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { request, requestWithoutAuth } from '@helpers/request';
import { timeout } from '@helpers/timeout';

export const getTenant = (
  tenantIdOrEmail: string,
  params: Record<string, string | number | undefined | any>,
) => requestWithoutAuth(`tenants/${tenantIdOrEmail}`, params);

/**
 * Login api request
 *
 * @return {Promise.<String>}
 */
export const loginUser = async (
  email: string,
  password: string,
  withPersistence = true,
): Promise<UserCredential['user'] | Error> => {
  const auth = getAuth();

  try {
    if (withPersistence) {
      await setPersistence(auth, browserLocalPersistence);
    }

    const userCredential = await signInWithEmailAndPassword(auth, email, password);

    return userCredential.user;
  } catch (error) {
    throw new Error(error.message);
  }
};

/**
 * Init firebase
 */
export const runFirebase = async (
  apiKey: string,
  projectId: string,
  withTimeout = true,
): Promise<FirebaseApp> => {
  if (withTimeout) {
    await timeout(1500);
  }

  const firebaseConfig: FirebaseOptions = {
    apiKey,
    projectId,
  };

  return initializeApp(firebaseConfig);
};

/**
 * Test connection
 */
export const getAuthInfo = (email: string) => request(`auth-info/${email}`);
