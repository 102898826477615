import { IStyles } from '@interfaces/styles/i-styles';

const spaceBetween = 'space-between';

const styles = {
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'normal',
    justifyContent: spaceBetween,
    overflowY: 'auto',
    overflowX: 'hidden',
    px: {
      xs: 2,
      sm: 4,
    },
    py: 2.5,
  },

  paymentElementWrapper: {
    width: '100%',
    paddingTop: 3,
  },

  savePaymentMethodWrapper: {
    marginTop: { xs: 1.5, sm: 3 },
    display: 'flex',
    alignItems: 'center',
    justifyContent: spaceBetween,
  },

  buttonsWrapper: {
    mt: '30px',
    gap: '30px',
    display: 'flex',
    flexDirection: { xs: 'column-reverse', sm: 'row' },
    alignItems: 'center',
    justifyContent: spaceBetween,
    width: '100%',
  },

  button: {
    width: {
      xs: '100%',
      sm: '45%',
    },
    mt: 0,
  },
};

export default styles as IStyles<typeof styles>;
