enum CARDS_ACTION_TYPES {
  GET_ACCOUNTS_LIST = 'GET_ACCOUNTS_LIST',
  GET_ACCOUNTS_LIST_SUCCESS = 'GET_ACCOUNTS_LIST_SUCCESS',
  GET_ACCOUNTS_LIST_ERROR = 'GET_ACCOUNTS_LIST_ERROR',
  UPDATE_ACCOUNTS_LIST_PAGINATION = 'UPDATE_ACCOUNTS_LIST_PAGINATION',
  UPDATE_ACCOUNTS_SEARCH = 'UPDATE_ACCOUNTS_SEARCH',
  UPDATE_ACCOUNTS_SORTING = 'UPDATE_ACCOUNTS_SORTING',
  UPDATE_ACCOUNTS_SEARCH_BY_LOCATION = 'UPDATE_ACCOUNTS_SEARCH_BY_LOCATION',

  GET_ACCOUNT = 'GET_ACCOUNT',
  GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR',
  CLEAR_ACCOUNT = 'CLEAR_ACCOUNT',

  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
  UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR',

  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR',

  ADD_ACCOUNT = 'ADD_ACCOUNT',
  ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS',
  ADD_ACCOUNT_ERROR = 'ADD_ACCOUNT_ERROR',

  UPDATE_AUTO_CHARGE = 'UPDATE_AUTO_CHARGE',
  UPDATE_AUTO_CHARGE_SUCCESS = 'UPDATE_AUTO_CHARGE_SUCCESS',
}

export default CARDS_ACTION_TYPES;
