import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { DRAWER_WIDTH } from '@constants/geometry';

/**
 * Styled SideBar
 */
const StyledSideBar = styled(MuiDrawer, { shouldForwardProp: (prop: string) => prop !== 'isOpen' })(
  ({ theme: { transitions, breakpoints, palette }, isOpen }) => ({
    '& .MuiDrawer-paper': {
      backgroundColor: palette.mode === 'light' ? palette.primary.main : palette.background.default,
      position: 'relative',
      whiteSpace: 'nowrap',
      width: DRAWER_WIDTH,
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
      ...(!isOpen && {
        overflowX: 'hidden',
        transition: transitions.create('width', {
          easing: transitions.easing.sharp,
          duration: transitions.duration.leavingScreen,
        }),
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      }),

      height: '72px',
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& svg': {
        maxWidth: 30,
        '& [data-fill="theme"]': {
          fill: palette.mode === 'light' ? 'white' : palette.grey[300],
        },
        '& [data-stroke="theme"]': {
          stroke: palette.mode === 'light' ? 'white' : palette.grey[300],
        },
        [breakpoints.up('sm')]: {
          maxWidth: 40,
        },
      },
    },
  }),
);

export default StyledSideBar;
