import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SmallButton } from '..';
import styles from './styles';

export interface IPrivacyPolicy {
  cancelBtn: any;
}

/**
 * Loading Button
 * @constructor
 */
const PrivacyPolicy: FC<IPrivacyPolicy> = ({ cancelBtn }) => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.wrapper}>
      <Typography component="div" textAlign={'left'} color="#3C4977" fontSize={20}>
        {t('loginBanner.privacyPolicy')}
      </Typography>
      <Box textAlign={'left'}>
        <Typography mt={2}>{t('privacyPolicy.description')}</Typography>
        <Typography fontWeight={'bold'} my={1.25}>
          {t('privacyPolicy.infoCollectionAndUse_h')}
        </Typography>
        <Typography>{t('privacyPolicy.infoCollectionAndUse_desc')}</Typography>

        <Typography fontWeight={'bold'} my={1.25}>
          {t('privacyPolicy.paymentInformation_h')}
        </Typography>
        <Typography>{t('privacyPolicy.paymentInformation_desc')}</Typography>

        <Typography fontWeight={'bold'} my={1.25}>
          {t('privacyPolicy.infoSharingAndSiclosure_h')}
        </Typography>
        <Typography>{t('privacyPolicy.infoSharingAndSiclosure_desc')}</Typography>

        <Typography fontWeight={'bold'} my={1.25}>
          {t('privacyPolicy.security_h')}
        </Typography>
        <Typography>{t('privacyPolicy.security_desc')}</Typography>

        <Typography fontWeight={'bold'} my={1.25}>
          {t('privacyPolicy.changesPrivacyPolicy_h')}
        </Typography>
        <Typography>{t('privacyPolicy.changesPrivacyPolicy_desc')}</Typography>

        <Typography fontWeight={'bold'} my={1.5}>
          {t('privacyPolicy.contactUs_h')}
        </Typography>
        <Typography>{t('privacyPolicy.contactUs_desc')}</Typography>

        <Typography mt={1.25}>{t('privacyPolicy.desc_about_privacy')}</Typography>
      </Box>
      <SmallButton onClick={cancelBtn} variant="outlined" sx={{ width: '180px' }}>
        {t('cancel')}
      </SmallButton>
    </Box>
  );
};

export default PrivacyPolicy;
