import IState from '@interfaces/redux/i-state';

const mapStateToProps = (state: IState) =>
  ({
    settings: state.settings.result?.settings,
  } as const);

export type ISettingGatewayStateToProps = ReturnType<typeof mapStateToProps>;

export { mapStateToProps };
