/* eslint-disable @typescript-eslint/default-param-last */
import TRANSACTION_TIME_KEY from '@constants/transaction-time-key';
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import { ITableList } from '@interfaces/redux/i-table-list';
import { ITransaction } from '@interfaces/transactions/i-transaction';
import TYPES from './action-types';

export interface ITransactionsState {
  list: IReducerState<ITransaction[]> & ITableList;
  update: IReducerState<string | undefined>;
  delete: IReducerState<string | undefined>;
  add: IReducerState<string | undefined>;
}

const initState: ITransactionsState = {
  list: {
    result: [],
    isFetching: false,
    error: null,
    searchValue: '',
    pagination: { total: 0, page: 1, size: 15 },
    sorting: {
      [TRANSACTION_TIME_KEY]: true,
    },
    dates: { startDate: null, endDate: null },
    selectedCardId: undefined,
  },
  update: {
    result: undefined, // loadingRowId
    isFetching: false,
    error: null,
  },
  delete: {
    result: undefined, // loadingRowId
    isFetching: false,
    error: null,
  },
  add: {
    result: undefined, // id
    isFetching: false,
    error: null,
  },
};

/**
 * Transactions reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>) => {
  switch (type) {
    // list
    case TYPES.GET_TRANSACTIONS_LIST:
      return { ...state, list: { ...state.list, error: null, isFetching: true } };

    case TYPES.GET_TRANSACTIONS_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          result: payload.list,
          pagination: payload.pagination,
          isFetching: false,
          error: null,
          selectedCardId: payload.cardId,
        },
      };

    case TYPES.GET_TRANSACTIONS_LIST_ERROR:
      return { ...state, list: { ...state.list, isFetching: false, error: payload.message } };

    case TYPES.CLEAR_TRANSACTIONS_LIST:
      return { ...state, list: { ...initState.list } };

    case TYPES.UPDATE_TRANSACTIONS_LIST_PAGINATION:
      return {
        ...state,
        list: {
          ...state.list,
          pagination: {
            ...state.list.pagination,
            ...payload.pagination,
            ...(payload.pagination.size &&
              payload.pagination.size !== state.list.pagination.size && { page: 1 }),
          },
          selectedCardId: payload.cardId,
        },
      };

    case TYPES.UPDATE_TRANSACTIONS_LIST_SORTING:
      return {
        ...state,
        list: {
          ...state.list,
          sorting: { [payload.name]: payload.desc },
          selectedCardId: payload.cardId,
        },
      };

    case TYPES.UPDATE_TRANSACTIONS_LIST_DATES:
      return {
        ...state,
        list: {
          ...state.list,
          dates: { startDate: payload.startDate, endDate: payload.endDate },
          selectedCardId: payload.cardId,
        },
      };

    case TYPES.UPDATE_TRANSACTIONS_LIST_DATES_WITHOUT_REFETCH:
      return {
        ...state,
        list: {
          ...state.list,
          dates: { startDate: payload.startDate, endDate: payload.endDate },
        },
      };

    // update
    case TYPES.UPDATE_TRANSACTION:
      return {
        ...state,
        update: { ...state.update, error: null, isFetching: true, result: payload.id },
      };

    case TYPES.UPDATE_TRANSACTION_SUCCESS:
      return { ...state, update: { ...initState.update } };

    case TYPES.UPDATE_TRANSACTION_ERROR:
      return {
        ...state,
        update: { ...state.update, isFetching: false, error: payload.message, result: undefined },
      };

    // delete
    case TYPES.DELETE_TRANSACTION:
      return {
        ...state,
        delete: { ...state.delete, error: null, isFetching: true, result: payload.id },
      };

    case TYPES.DELETE_TRANSACTION_SUCCESS:
      return { ...state, delete: { ...initState.delete } };

    case TYPES.DELETE_TRANSACTION_ERROR:
      return {
        ...state,
        delete: { ...state.delete, isFetching: false, error: payload.message, result: undefined },
      };

    // add
    case TYPES.ADD_TRANSACTION:
      return {
        ...state,
        add: { ...state.add, error: null, isFetching: true, result: payload.id },
      };

    case TYPES.ADD_TRANSACTIONS:
      return { ...state, add: { ...state.add, error: null, isFetching: true } };

    case TYPES.ADD_TRANSACTION_SUCCESS:
    case TYPES.ADD_TRANSACTIONS_SUCCESS:
      return { ...state, add: { ...initState.add } };

    case TYPES.ADD_TRANSACTION_ERROR:
    case TYPES.ADD_TRANSACTIONS_ERROR:
      return {
        ...state,
        add: { ...state.add, isFetching: false, error: payload.message, result: undefined },
      };

    case TYPES.CLEAR_DATES:
      return {
        ...state,
        list: {
          ...state.list,
          dates: { startDate: null, endDate: null },
        },
      };

    default:
      return state;
  }
};
