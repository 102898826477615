import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  rowEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rowBetween: {
    display: {
      xs: 'flex',
    },
    gap: 2,
    justifyContent: 'space-between',
  },
  buttonsWidth: {
    width: {
      xs: 'auto',
    },
  },
  contactSubTitle: {
    marginTop: '24px',
    paddingLeft: '24px',
  },
  fetchingInitBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  infoForCreateClint: {
    fontSize: '12px',
    margin: '20px 0 0',
  },
};

export default styles as IStyles<typeof styles>;
