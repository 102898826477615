import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  box: {
    width: 300,
    boxShadow: 3,
    borderRadius: 0,
    p: 2,
    bgcolor: 'background.paper',
  },
};

export default styles as IStyles<typeof styles>;
