import type { SagaIterator } from '@redux-saga/core';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { ISettings } from '@interfaces/auth/i-settings';
import type { IAction } from '@interfaces/redux/i-action';
import { runFirebase } from '@store/tenant/api';
import {
  forgotConfirmError,
  forgotConfirmSuccess,
  forgotSendEmailError,
  forgotSendEmailSuccess,
  forgotVerifyError,
  forgotVerifySuccess,
} from './action-creators';
import TYPES from './action-types';
import { forgotConfirmApi, forgotPassword, forgotVerifyApi } from './api';

/**
 * Forgot user password
 */
function* forgot({ payload }: IAction<TYPES>): SagaIterator {
  try {
    const { email } = payload ?? {};

    yield call(forgotPassword, email);

    yield put(forgotSendEmailSuccess());
  } catch (e) {
    yield put(forgotSendEmailError(e.message));
  }
}

/**
 * Forgot verify actionCode
 */
function* forgotVerify({ payload }: IAction<TYPES>): SagaIterator {
  yield delay(2000);
  const { firebaseApiKey, firebaseProjectId }: ISettings = yield select(
    (state) => state.settings.result?.settings,
  );

  yield call(runFirebase, firebaseApiKey, firebaseProjectId);

  const { token } = payload ?? {};
  try {
    const { email, oobCode } = yield call(forgotVerifyApi, token);

    yield put(forgotVerifySuccess(email, oobCode));
  } catch (e) {
    yield put(forgotVerifyError(e));
  }
}

/**
 * Forgot confirm user password
 */
function* forgotConfirm({ payload: { actionCode, password } }: IAction<TYPES>): SagaIterator {
  // const { actionCode, password } = payload ?? {};
  try {
    yield call(forgotConfirmApi, actionCode, password);
    yield put(forgotConfirmSuccess());
  } catch (e) {
    yield put(forgotConfirmError(e.message));
  }
}

export default [
  takeLatest(TYPES.FORGOT_SEND_EMAIL, forgot),
  takeLatest(TYPES.FORGOT_VERIFY, forgotVerify),
  takeLatest(TYPES.FORGOT_CONFIRM, forgotConfirm),
];
