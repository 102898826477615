import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import IS_MOBILE from '@constants/is-mobile';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  wrapper: {
    height: IS_MOBILE ? window.innerHeight : '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ({ palette }) => (palette.mode === 'light' ? 'white' : palette.grey[900]),
    color: ({ palette }) => (palette.mode === 'light' ? palette.grey[900] : 'white'),
  } as SxProps<Theme>,
};

export default styles as IStyles<typeof styles>;
