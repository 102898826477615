/* eslint-disable @typescript-eslint/default-param-last */
import { ICard } from '@interfaces/cards/i-card';
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import { ITableList } from '@interfaces/redux/i-table-list';
import TYPES from './action-types';

export interface INewCardsState {
  list: IReducerState<ICard[]> & ITableList;
}

const initState: INewCardsState = {
  list: {
    result: [],
    isFetching: false,
    error: null,
    searchValue: '',
    pagination: { total: 0, page: 1, size: 100 },
  },
};

/**
 * New cards reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>) => {
  switch (type) {
    // list
    case TYPES.GET_CARDS_LIST:
      return { ...state, list: { ...state.list, error: null, isFetching: true } };

    case TYPES.GET_CARDS_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          result: payload.list,
          pagination: payload.pagination,
          isFetching: false,
          error: null,
        },
      };

    case TYPES.GET_CARDS_LIST_ERROR:
      return { ...state, list: { ...state.list, isFetching: false, error: payload.message } };

    case TYPES.UPDATE_CARDS_SEARCH:
      return { ...state, list: { ...state.list, searchValue: payload.value } };

    case TYPES.CLEAR_CARDS_SEARCH:
      return { ...state, list: { ...state.list, result: [] } };

    default:
      return state;
  }
};
