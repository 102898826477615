import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TitledBlock } from '@components/index';
import SmallButton from '@components/small-button';
import { PaymentDetailsDialogType } from '@pages/accounts/account/payment-details';

type Props = {
  handleCancel: any;
  handleSubmit: any;
  type: PaymentDetailsDialogType;
};
const PaymentDetailsDialog: FC<Props> = ({ handleCancel, handleSubmit, type }) => {
  const { t } = useTranslation();

  return (
    <TitledBlock title={t('warning')}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
        <Typography fontSize={20} color="text.secondary" marginBottom={5} mt={2}>
          {t(`paymentDetailsDialog.${type as string}`)}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <SmallButton
            sx={{ marginTop: 0, width: '40%' }}
            variant="outlined"
            color="primary"
            onClick={() => handleCancel()}>
            {t('cancel')}
          </SmallButton>
          <SmallButton
            sx={{ marginTop: 0, width: '40%' }}
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}>
            {t('ok')}
          </SmallButton>
        </Box>
      </Box>
    </TitledBlock>
  );
};

export default PaymentDetailsDialog;
