import React, { FC, ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { ISettings } from '@interfaces/auth/i-settings';
import RoutePath from '../../enums/route-path';
import type { ISettingGatewayStateToProps } from './index.props';

type IProps = ISettingGatewayStateToProps & {
  children: ReactElement<any, any> | null;
  requiredSettingKeys: (keyof ISettings)[];
};

const tenantHasSettings = (
  settings: ISettings | null,
  requiredSettingKeys: (keyof ISettings)[],
): boolean => {
  if (!settings) {
    return false;
  }

  for (const key of requiredSettingKeys) {
    if (!settings[key]) {
      return false;
    }
  }

  return true;
};

/**
 * Component for implementation settings feature.
 * Give access to some pages witch depend on setting.
 */
const SettingGateway: FC<IProps> = ({ settings, requiredSettingKeys, children }) => {
  if (!tenantHasSettings(settings, requiredSettingKeys)) {
    return <Redirect to={RoutePath.Administration} />;
  }

  return children;
};

export default SettingGateway;
