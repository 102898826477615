import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CardsListMobile, CloudCover } from '@components/index';
import { IModalForward } from '@components/modal';
import StripeElements from '@components/stripe-elements/index.store';
import SubscriptionRenewalModal from '@components/subscription-renewal-modal';
import IS_MOBILE from '@constants/is-mobile';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import isOver45Days from '@helpers/is-over-45-days';
import PaymentDetails from '@pages/accounts/account/payment-details/index.store';
import RoleType from '../../../enums/role-type';
import RoutePath from '../../../enums/route-path';
import AdditionalBlocks from './additional-blocks/index.store';
import CardsAutocomplete from './cards-autocomplete/index.store';
import CardsTable from './cards-table/index.store';
import UserForm from './user-form/index.store';
import type { IAccountDispatchToProps, IAccountStateToProps } from './index.props';
import styles from './styles';

type IProps = IAccountStateToProps & IAccountDispatchToProps;

/**
 * Account page
 * @constructor
 */
const Account: FC<IProps> = ({
  account,
  getAccount,
  clearAccount,
  clearCardsList,
  clearNewDeposit,
  depositList,
  tenantLastPaymentDay,
  getDepositsList,
  userRoles,
  id,
  isShowInactive,
  toggleShowInactive,
}) => {
  const { t } = useTranslation();
  const { code } = useParams<{ code?: string }>();
  const isAllowedForAdmin = isAllowedForUser([RoleType.Admin], userRoles as RoleType[]);
  const isAllowedForCustomer = isAllowedForUser([RoleType.Customer], userRoles as RoleType[]);
  const history = useHistory();

  const tableWrapperRef = useRef<HTMLDivElement | null>(null);
  const subscriptionRenewalRef = useRef<IModalForward | null>(null);

  /**
   * Get current account
   */
  useEffect(() => {
    if (!code) {
      return;
    }

    clearCardsList();

    if (code && id) {
      getAccount(isAllowedForAdmin ? code : id);

      if (!depositList?.length) {
        getDepositsList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCardsList, getAccount, id, isAllowedForAdmin, code]);

  /**
   * Redirecting the account to his page if he is not an admin
   */
  useEffect(() => {
    if (isAllowedForCustomer && code !== id.toString()) {
      history.push(`${RoutePath.AccountsEdit}/${id}`);
    }
  }, [history, id, isAllowedForCustomer, code]);

  /**
   * Clear account on unmount
   */
  useEffect(
    () => () => {
      if (!code) {
        return;
      }

      clearAccount();
    },
    [clearAccount, code],
  );

  /**
   * Clear selected deposit values on change user
   */
  useEffect(() => () => clearNewDeposit(), [clearNewDeposit]);

  /**
   * Open subscriptionRenewal modal
   */
  useEffect(() => {
    if (!id) {
      return;
    }

    if (isOver45Days(tenantLastPaymentDay ?? '')) {
      subscriptionRenewalRef.current?.toggleModal();
    }
  }, [id, tenantLastPaymentDay]);

  /**
   * Default values for current form
   */
  const defaultFields = code && account ? account : { active: true, plan: t('custom') };

  const renderCards = useMemo(
    () => (
      <Box>
        {IS_MOBILE && isAllowedForCustomer ? (
          <CardsListMobile account={account} />
        ) : (
          <CardsTable account={account} tableHeadSx={styles.tableHeadSx} />
        )}
      </Box>
    ),
    [account, isAllowedForCustomer],
  );

  return (
    <Grid container sx={styles.wrapper}>
      {Boolean(code) && <AdditionalBlocks active={account?.active} />}

      <Grid container sx={{ ...styles.wrapper, flexWrap: 'nowrap' }}>
        {isAllowedForAdmin && (
          <Grid item md={3} xs={12} sx={styles.gridForm} mt="17px">
            <CloudCover>
              <UserForm
                type={code ? 'update' : 'add'}
                defaultFields={defaultFields}
                accountId={account?.id}
              />
              {code && (
                <StripeElements
                  useGlobalStripe={false}
                  children={<PaymentDetails showTitleBlock isGlobalPD={false} />}
                />
              )}
            </CloudCover>
          </Grid>
        )}

        <Grid
          height="calc(100% - 99px)"
          sx={(props) => ({
            ...styles.rightBox,
            [props.breakpoints.up('md')]: {
              paddingLeft: isAllowedForAdmin ? '17px' : '0',
              overflowX: 'auto',
            },
          })}
          mt="17px"
          item
          md={isAllowedForAdmin ? 9 : 0}
          xs={12}>
          {Boolean(code) && (
            <Box ref={tableWrapperRef} sx={{ height: { sm: '100%' } }}>
              <Box sx={styles.boxCardsTable}>
                {isAllowedForAdmin && (
                  <Box sx={styles.autocompleteInactiveWrapper}>
                    <Box width={'84%'}>
                      <CardsAutocomplete />
                    </Box>
                    <Box sx={styles.inactive}>
                      <Typography sx={styles.title}>
                        {' '}
                        {t('additionalBlocks.showInactive')}
                      </Typography>
                      <Box>
                        <Switch
                          checked={isShowInactive}
                          sx={styles.switchActive}
                          onChange={toggleShowInactive}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                {Boolean(account?.id) && renderCards}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <SubscriptionRenewalModal modalRef={subscriptionRenewalRef} />
    </Grid>
  );
};

export default Account;
