import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import MaUTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TableOptions, usePagination, useSortBy, useTable } from 'react-table';
import TRANSACTION_TIME_KEY from '@constants/transaction-time-key';
import isMoreThenAMonthAgo from '@helpers/is-more-then-a-month-ago';
import { IPagination } from '@interfaces/redux/i-pagination';
import { ITransaction } from '@interfaces/transactions/i-transaction';
import TableType from 'src/enums/table-type';
import PlanType from '../../../enums/plan-type';
import TablePagination from './table-pagination';
import styles from './styles';

interface ITable extends TableOptions<any, any> {
  withPagination?: boolean;
  hiddenHeader?: boolean;
  stickyHeader?: boolean;
  fetchingRowId?: string;
  tableContainerSx?: SxProps<Theme>;
  tableHeaderSx?: SxProps<Theme>;
  tableRowSx?: SxProps<Theme>;
  isFetching?: boolean;
  onItemClick?: (id: string, item: any) => () => void;
  onChangeSorting?: (name?: string, desc?: boolean, cardId?: string) => void;
  onChangePagination?: (pagination: Partial<IPagination>, cardId?: string) => void;
  pagination?: IPagination;
  listenTransactions?: null | ITransaction[];
  tableType?: string;
  cardIdForTransactions?: string;
  sortByDescSettlement?: boolean;
  disableSortBy?: boolean;
  isFetchingAccountUpdate?: boolean;
  isAccountActive?: boolean;
  coopThresholdAmount?: number;
  coopThresholdColour?: string;
}

/**
 * Table
 * @constructor
 */
const Table: FC<ITable> = ({
  onItemClick,
  hiddenHeader,
  fetchingRowId,
  isFetching,
  tableContainerSx,
  tableHeaderSx,
  tableRowSx,
  onChangePagination,
  onChangeSorting,
  pagination,
  stickyHeader = true,
  withPagination = true,
  listenTransactions,
  tableType,
  cardIdForTransactions,
  sortByDescSettlement,
  isFetchingAccountUpdate,
  isAccountActive,
  disableSortBy,
  coopThresholdAmount,
  coopThresholdColour,
  ...options
}) => {
  const { t } = useTranslation();
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageSize, sortBy },
  } = useTable(
    {
      ...options,
      autoResetRowState: true,
      disableMultiSort: true,
      autoResetSortBy: false,
      manualPagination: true,
      manualSortBy: true,
      listenTransactions,
      disableSortBy,
      initialState: { pageSize: 15 },
      ...(!withPagination && { initialState: { pageSize: 100 } }),
    },
    useSortBy,
    usePagination,
  );

  const isTableMain: boolean = tableType === TableType.MainTable;
  const isHistoryTableMain: boolean = tableType === TableType.History;

  /**
   * Page change event
   */
  const onPageChange = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      gotoPage(newPage);
      onChangePagination?.({ page: newPage + 1 }, cardIdForTransactions);
    },
    [gotoPage, onChangePagination, cardIdForTransactions],
  );

  /**
   * Update store on change pagination size
   */
  useEffect(
    () => onChangePagination?.({ size: pageSize }, cardIdForTransactions),
    [onChangePagination, pageSize, cardIdForTransactions],
  );

  useEffect(() => {
    if (sortByDescSettlement) {
      onChangeSorting?.(TRANSACTION_TIME_KEY, true, cardIdForTransactions);
    }
    // eslint-disable-next-line
  }, [sortByDescSettlement]);

  /**
   * Update store on change sorting
   */
  useEffect(() => {
    const [sorting] = sortBy;

    if (sorting) {
      onChangeSorting?.(sorting?.id, sorting?.desc, cardIdForTransactions);
      onChangePagination?.({ page: 1 }, cardIdForTransactions);
    }
    // eslint-disable-next-line
  }, [onChangeSorting, sortBy, cardIdForTransactions]);

  /**
   * Dynamic style table
   */
  const stylesHead = {
    ...styles.head,
    ...(tableHeaderSx ?? ({} as any)),
    ...(disableSortBy && {
      '& .MuiTableSortLabel-root': {
        opacity: '0 !important',
        cursor: 'unset !important',
      },
    }),
  };

  const styleContainer = {
    ...styles.container,
    ...(!options.data.length && isFetching && styles.overflowHidden),
    ...(tableContainerSx ?? ({} as any)),
    ...(isTableMain && { px: 3.2 }),
  };

  const stylesCell = (table?: string) => {
    if (table === TableType.Transfer) {
      return {
        maxWidth: '120px',
        minWidth: '100px',
        width: '120px',
      };
    }

    return {
      maxWidth: '250px',
      minWidth: '120px',
    };
  };

  return (
    <>
      <TableContainer sx={styleContainer}>
        {(fetchingRowId || isFetching) && <Box sx={styles.blocker} />}
        <MaUTable {...getTableProps()} stickyHeader={stickyHeader}>
          {!hiddenHeader && (
            <TableHead sx={stylesHead}>
              {headerGroups.map((headerGroup) => {
                const rowProps = headerGroup.getHeaderGroupProps();

                return (
                  <TableRow {...rowProps} key={rowProps.key}>
                    {headerGroup.headers.map((column) => {
                      const cellProps = column.getHeaderProps(column.getSortByToggleProps());
                      const isSortDisabled = column.id === 'actions';

                      return (
                        <TableCell
                          {...cellProps}
                          key={cellProps.key}
                          title={`Toggle sort by ${column.id}`}
                          {...(isSortDisabled && { onClick: () => null })}>
                          {column.render('Header')}

                          {!isSortDisabled && (
                            <TableSortLabel
                              active={column.isSorted}
                              direction={column.isSorted && column.isSortedDesc ? 'desc' : 'asc'}
                            />
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>
          )}

          {!options.data.length && (
            <TableBody sx={styles.loaderBody}>
              <Box component="tr" sx={isFetching ? styles.loaderBox : styles.noDataBox}>
                {isFetching ? (
                  <CircularProgress />
                ) : (
                  <Typography color="text.primary" noWrap textAlign="center">
                    {t('noData')}
                  </Typography>
                )}
              </Box>
            </TableBody>
          )}

          <TableBody sx={hiddenHeader ? styles.hiddenHeaderBody : styles.body}>
            {page.map((row) => {
              prepareRow(row);
              let countStyles = {};

              if (isTableMain) {
                const planCredit: any = row.original?.accountPlan;
                const isCoopPlan = planCredit?.plan.name === PlanType.Coop;

                countStyles = {
                  td: {
                    ...(planCredit?.toPay &&
                      (isMoreThenAMonthAgo(planCredit?.billingDate)
                        ? styles.creditPendingMonth
                        : styles.creditPendingWeeks)),
                    ...(isCoopPlan &&
                      planCredit?.availableBalance <= coopThresholdAmount && {
                        color: coopThresholdColour,
                      }),
                    py: '11px',
                    height: '43px',
                  },
                };
              }

              if (isHistoryTableMain) {
                countStyles = {
                  td: {
                    pl: '17px',
                    height: '43px',

                    '&:last-child': {
                      pl: '17px',
                    },
                  },
                };
              }

              if (tableType === TableType.Transfer) {
                countStyles = {
                  td: {
                    py: '0px',
                    pl: '24px',
                    height: '43px',

                    '&:last-child': {
                      px: '0px',
                      maxWidth: '115px',
                    },
                  },
                };
              }

              const rowProps = row.getRowProps();
              const isTransaction = listenTransactions?.find(
                ({ cardId }: { cardId: string }) => cardId === row.original.id,
              );

              const sxStyleRow = {
                ...tableRowSx,
                ...styles.row,
                ...((!row.original?.active || isFetchingAccountUpdate || !isAccountActive) &&
                  styles.active),
                ...(isTransaction && styles.active),
                ...countStyles,
              };

              return (
                <TableRow
                  {...rowProps}
                  key={row?.original?.id ? String(row.original.id) : rowProps.key}
                  sx={sxStyleRow}
                  onClick={onItemClick?.(String(row.original?.id), row.original)}>
                  {row.cells.map((cell, index) => {
                    const cellProps = cell.getCellProps();
                    const title = row.cells?.[index]?.column.Header;

                    return (
                      <TableCell
                        {...cellProps}
                        key={cellProps.key}
                        style={{
                          ...stylesCell(tableType),
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          position: 'relative',
                        }}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          style={{
                            ...stylesCell(tableType),
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}>
                          {hiddenHeader && typeof title === 'string' && (
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              noWrap
                              position="absolute"
                              top={10}>
                              {title}
                            </Typography>
                          )}
                          {cell.render('Cell')}
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
      </TableContainer>

      <Box sx={{ width: '100%', height: '5px' }}>
        {isFetching && Boolean(options.data.length) && <LinearProgress />}
      </Box>
      {withPagination && (
        <Box>
          <Box sx={{ marginRight: '4rem' }}>
            <TablePagination
              onPageChange={onPageChange}
              gotoPage={gotoPage}
              pageIndex={(pagination?.page ?? 1) - 1}
              count={pagination?.total ?? 0}
              setPageSize={setPageSize}
              pageSize={pagination?.size ?? 15}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Table;
