import * as jwt from 'jsonwebtoken';
import { configs } from '@helpers/get-env';

export const jwtVerify = <T>(
  token: string,
  options?: (jwt.VerifyOptions & { complete?: false | undefined }) | undefined,
  secretOrPublicKey?: jwt.Secret,
): T => {
  try {
    const secretKey = secretOrPublicKey ?? (configs.REACT_APP_JWT_SECRET as string);
    const payload = jwt.verify(token, secretKey, options) as T;

    if (!payload) {
      return {} as T;
    }

    return payload;
  } catch (err) {
    return {} as T;
  }
};

export const jwtSign = (
  payload: string | Record<string, unknown>,
  options?: jwt.SignOptions,
  secretOrPublicKey?: jwt.Secret,
): string => {
  const secretKey = secretOrPublicKey ?? (configs.REACT_APP_JWT_SECRET as string);

  return jwt.sign(payload, secretKey, options);
};
