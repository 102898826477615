import getSummary from '@helpers/get-summary';
import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import {
  addPayments,
  createCreditIntegration,
  getTaxRateInfo,
} from '@store/payments/action-creators';
import { addTransactions } from '@store/transactions/action-creators';

export const mapStateToProps = (state: IState) =>
  ({
    deposit: getSummary(Object.values(state.deposits.item.deposit)),
    bonus: getSummary(Object.values(state.deposits.item.customBonus)),
    isFetching: state.transactions.add.isFetching || state.payments.add.isFetching,
    accountEmail: state.accounts.item.result?.email,
    taxRateInfo: state.payments.tax.result,
    isFetchingTax: state.payments.tax.isFetching,
    taxRateInfoError: state.payments.tax.error,
    account: state.accounts.item.result,
  } as const);

export const mapDispatchToProps = {
  addTransactions,
  addPayments,
  getTaxRateInfo,
  createCreditIntegration,
};

export type ICheckoutDialogStateToProps = ReturnType<typeof mapStateToProps>;

export type ICheckoutDialogDispatchToProps = ReplacePropertiesReturnType<
  typeof mapDispatchToProps,
  void
>;
