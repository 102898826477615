import getSummary from '@helpers/get-summary';
import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { setPaymentType } from '@store/cards/action-creators';
import { addPayments, getTaxRateInfo } from '@store/payments/action-creators';

export const mapStateToProps = (state: IState) =>
  ({
    accountId: state.accounts.item.result?.id,
    paymentType: state.cards.paymentType,
    noDeposit: getSummary(Object.values(state.deposits.item.deposit)) === 0,
    isWaitingPayment: state.payments.listen.result,
    rolesTenant: state.tenant.claims?.roles,
    isDisplayStripe: state.settings.result?.settings.displayStripe,
    taxRateInfo: state.payments.tax.result,
    statusPay: state.payments.statusPay,
    isDeleteFetching: state.payments.delete.isFetching,
    isEmailExist: Boolean(state.accounts.item.result?.email),
  } as const);

export const mapDispatchToProps = {
  setPaymentType,
  addPayments,
  getTaxRateInfo,
};

export type ICardsPayStateToProps = ReturnType<typeof mapStateToProps>;

export type ICardsPayDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
