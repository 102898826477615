import Box from '@mui/material/Box';
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ColorModeSwitcher from '@components/color-mode-switcher';
import DynamicIcon from '@helpers/dynamis-icon';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import RoleType from '../../enums/role-type';
import RoutePath from '../../enums/route-path';
import { ListItems, mainList, mainListAdmin } from './list-items';
import StyledSideBar from './styled-side-bar';
import UserMenu from './user-menu';
import type { ISidebarDispatchToProps, ISidebarStateToProps } from './index.props';
import styles from './styles';

type IProps = ISidebarDispatchToProps & ISidebarStateToProps;

/**
 * SideBar
 * @constructor
 */
const SideBar: FC<IProps> = ({ logout, accountId, userRoles, initials, loginAccountName }) => {
  const history = useHistory();
  const isAllowedForAdmin = isAllowedForUser([RoleType.Admin], userRoles as RoleType[]);

  // eslint-disable-next-line prefer-destructuring
  const domain = document.location.hostname.split('.')[0];
  /**
   * Redirect to home page on click logo
   */
  const onClickLogo = useCallback(() => {
    if (isAllowedForAdmin) {
      history.push('/');
    } else {
      history.push(`${RoutePath.AccountsEdit}/${accountId}`);
    }
  }, [accountId, history, isAllowedForAdmin]);

  return (
    <StyledSideBar variant="permanent" isOpen={false} sx={styles.sidebar}>
      <Box sx={styles.backSidebar} />
      <Box sx={styles.frontSidebar}>
        <Box sx={styles.logo} onClick={onClickLogo}>
          <DynamicIcon domain={domain} name={'sidebar'} />
        </Box>
        <ListItems
          list={isAllowedForAdmin ? mainListAdmin : mainList(accountId)}
          userRoles={userRoles}
        />
      </Box>

      <Box component="footer" sx={styles.footer}>
        <ColorModeSwitcher />
        <UserMenu
          logout={logout}
          initials={initials}
          accountName={loginAccountName}
          role={userRoles ? userRoles[0] : undefined}
        />
      </Box>
    </StyledSideBar>
  );
};

export default SideBar;
