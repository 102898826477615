import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';

export const mapStateToProps = (state: IState) =>
  ({
    subInfo: state.accounts.item.result?.subscriptionInfo,
    cardLast4: state.paymentMethod.item.result?.cardLast4,
    prices: state.subscriptions.subscriptionPrices.result,
    email: state.accounts.item.result?.email,
    accountId: state.accounts.item.result?.id,
  } as const);

export const mapDispatchToProps = {};

export type ISubInfoStateToProps = ReturnType<typeof mapStateToProps>;

export type ISubInfoDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
