import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  box: {
    width: 60,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px 0 0 5px',
    borderColor: 'primary.main',
    borderWidth: 1,
    borderStyle: 'solid',
    boxShadow: 1,
  } as SxProps<Theme>,
  disabled: {
    borderColor: 'action.disabledBackground',
    boxShadow: 0,
    '& p': {
      color: '#C4C4C4',
    },
  },
  button: {
    pl: 0,
    pr: 0,
    minWidth: 38,
    py: '5px',
    borderRadius: '0 5px 5px 0',
    '& span': { ml: 0, mr: 0 },
  },

  btnCustomAmount: {
    margin: 0,
    padding: 0,
    width: '100%',
    button: {
      margin: 0,
      width: '100%',
      paddingTop: '9px',
      paddingBottom: '9px',
      borderRadius: '0px',
    },
  },

  boxCustomAmount: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  inputAmount: {
    width: '154px',
    fontWeight: 'bold',
    input: {
      padding: '8px',
    },
  },
};

export default styles as IStyles<typeof styles>;
