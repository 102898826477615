import Typography from '@mui/material/Typography';
import { zonedTimeToUtc } from 'date-fns-tz';
import React, { FC } from 'react';
import { CellProps } from 'react-table';
import formatDate from '@helpers/format-date';

/**
 * Date cell renderer
 * @constructor
 */
const DateCell: FC<CellProps<any>> = ({ value }) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const valueTime = zonedTimeToUtc(value, timezone);

  return (
    <Typography fontSize="inherit" noWrap>
      {formatDate(valueTime.toISOString())}
    </Typography>
  );
};

export default DateCell;
