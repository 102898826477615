enum CountyCode {
  Canada = 'ca',
  France = 'fr',
  Germany = 'de',
  Israel = 'il',
  Italy = 'it',
  Mexico = 'mx',
  Spain = 'es',
  Ukraine = 'ua',
  UnitedKingdom = 'gb',
  UnitedState = 'us',
}

export default CountyCode;
