import { Stripe, StripeElements } from '@stripe/stripe-js';
import PAYMENT_TYPE from '@interfaces/cards/payment-type';
import { IPayment } from '@interfaces/payments/i-payment';
import { IAction } from '@interfaces/redux/i-action';
import { IPagination } from '@interfaces/redux/i-pagination';
import { ITaxRate } from '@interfaces/tax-rate/i-tax-rate';
import { ITransaction } from '@interfaces/transactions/i-transaction';
import { CARDS_PAY_DIALOG } from '@pages/accounts/account/cards-pay';
import PlanType from '../../enums/plan-type';
import TYPES from './action-types';
import { IPaymentsState } from './reducer';

/**
 * Get payments list
 */
export const getPaymentsList = (cardId?: string): IAction<TYPES> => ({
  type: TYPES.GET_PAYMENTS_LIST,
  payload: { cardId },
});

/**
 * Get payments list success
 */
export const getPaymentsListSuccess = (
  list: IPaymentsState['list']['result'],
  pagination?: IPagination,
): IAction<TYPES> => ({
  type: TYPES.GET_PAYMENTS_LIST_SUCCESS,
  payload: { list, pagination },
});

/**
 * Get payments list error
 */
export const getPaymentsListError = (message: string): IAction<TYPES> => ({
  type: TYPES.GET_PAYMENTS_LIST_ERROR,
  payload: { message },
});

/**
 * Clear payments list
 */
export const clearPaymentsList = (): IAction<TYPES> => ({
  type: TYPES.CLEAR_PAYMENTS_LIST,
  payload: {},
});

/**
 * Update payments list pagination
 */
export const updatePaymentsListPagination = (pagination: Partial<IPagination>): IAction<TYPES> => ({
  type: TYPES.UPDATE_PAYMENTS_LIST_PAGINATION,
  payload: { pagination },
});

/**
 * Update payments list sorting
 */
export const updatePaymentsListSorting = (name?: string, desc?: boolean): IAction<TYPES> => ({
  type: TYPES.UPDATE_PAYMENTS_LIST_SORTING,
  payload: { name, desc },
});

/**
 * Update payments list dates
 */
export const updatePaymentsListDates = (
  startDate: string | null,
  endDate: string | null,
): IAction<TYPES> => ({
  type: TYPES.UPDATE_PAYMENTS_LIST_DATES,
  payload: { startDate, endDate },
});

/**
 * Update payment
 */
export const updatePayment = (
  id: string,
  newData: Partial<IPayment>,
  successCallback?: () => void,
): IAction<TYPES> => ({
  type: TYPES.UPDATE_PAYMENT,
  payload: { id, newData, successCallback },
});

/**
 * Update payment success
 */
export const updatePaymentSuccess = (item: IPayment): IAction<TYPES> => ({
  type: TYPES.UPDATE_PAYMENT_SUCCESS,
  payload: { item },
});

/**
 * Update payment error
 */
export const updatePaymentError = (message: string): IAction<TYPES> => ({
  type: TYPES.UPDATE_PAYMENT_ERROR,
  payload: { message },
});

/**
 * Delete payment
 */
export const deletePayment = (): IAction<TYPES> => ({
  type: TYPES.DELETE_PAYMENT,
  payload: {},
});

/**
 * Delete payment success
 */
export const deletePaymentSuccess = (): IAction<TYPES> => ({
  type: TYPES.DELETE_PAYMENT_SUCCESS,
  payload: {},
});

/**
 * Delete payment error
 */
export const deletePaymentError = (message: string): IAction<TYPES> => ({
  type: TYPES.DELETE_PAYMENT_ERROR,
  payload: { message },
});

/**
 * Add payments
 */
export const addPayments = (
  typePayment?: PAYMENT_TYPE,
  callback?: (modalType: CARDS_PAY_DIALOG) => void,
  accountPlanName?: string | undefined,
  toPayValue?: string | number | undefined,
  discount?: string | number | null,
): IAction<TYPES> => ({
  type: TYPES.ADD_PAYMENTS,
  payload: { typePayment, callback, accountPlanName, toPayValue, discount },
});

/**
 * Add payments success
 */
export const addPaymentsSuccess = (): IAction<TYPES> => ({
  type: TYPES.ADD_PAYMENTS_SUCCESS,
  payload: {},
});

export const confirmPayment = (
  useDefaultPaymentMethod: boolean,
  stripe: Stripe,
  elements: StripeElements,
  isSavePaymentMethod: boolean,
  setDialogType: any,
  toPayValue: string | number,
  discount: string | number | null | undefined,
  setToPayValue: any,
  setMessage: any,
): IAction<TYPES> => ({
  type: TYPES.CONFIRM_PAYMENT,
  payload: {
    useDefaultPaymentMethod,
    stripe,
    elements,
    isSavePaymentMethod,
    setDialogType,
    toPayValue,
    discount,
    setToPayValue,
    setMessage,
  },
});

/**
 * Add payments error
 */
export const addPaymentsError = (message: string): IAction<TYPES> => ({
  type: TYPES.ADD_PAYMENTS_ERROR,
  payload: { message },
});

/**
 * Start listen last payment
 */
export const listenLastPaymentStart = (): IAction<TYPES> => ({
  type: TYPES.LISTEN_LAST_PAYMENT_START,
  payload: {},
});

export const callListenLastPayment = (): IAction<TYPES> => ({
  type: TYPES.CALL_LISTEN_LAST_PAYMENT,
  payload: {},
});

export const setTypeListenerPay = (type: string): IAction<TYPES> => ({
  type: TYPES.SET_TYPE_LISTENER,
  payload: { type },
});

/**
 * Listen last payment success
 */
export const listenLastPaymentSuccess = (
  value: boolean,
  transactions?: ITransaction[],
): IAction<TYPES> => ({
  type: TYPES.LISTEN_LAST_PAYMENT_SUCCESS,
  payload: { value, transactions },
});

/**
 * Listen last payment error
 */
export const listenLastPaymentError = (message: string): IAction<TYPES> => ({
  type: TYPES.LISTEN_LAST_PAYMENT_ERROR,
  payload: { message },
});

/**
 * Stop listen last payment
 */
export const listenLastPaymentStop = (): IAction<TYPES> => ({
  type: TYPES.LISTEN_LAST_PAYMENT_STOP,
  payload: {},
});

/**
 * Get tax rate info
 */
export const getTaxRateInfo = (): IAction<TYPES> => ({
  type: TYPES.GET_TAX_INFO,
  payload: {},
});

/**
 * Get tax rate info success
 */
export const getTaxRateInfoSuccess = (payload: ITaxRate): IAction<TYPES> => ({
  type: TYPES.GET_TAX_INFO_SUCCESS,
  payload,
});

/**
 * Get tax rate info error
 */
export const getTaxRateInfoError = (message: string): IAction<TYPES> => ({
  type: TYPES.GET_TAX_INFO_ERROR,
  payload: { message },
});

/**
 * Update status pay for credit card
 */
export const updateStatusPayCredit = (status: string | null): IAction<TYPES> => ({
  type: TYPES.UPDATE_STATUS_PAY_CREDIT,
  payload: { status },
});

export const clearClientSecret = (): IAction<TYPES> => ({
  type: TYPES.CLEAR_CLIENT_SECRET,
  payload: {},
});

export const createCreditIntegration = (accountPlanName: PlanType): IAction<TYPES> => ({
  type: TYPES.CREATE_CREDIT_INTEGRATION,
  payload: { accountPlanName },
});

export const createCreditIntegrationSuccess = (
  creditIntegrationData: IPaymentsState['creditIntegrationData']['result'],
): IAction<TYPES> => ({
  type: TYPES.CREATE_CREDIT_INTEGRATION_SUCCESS,
  payload: { creditIntegrationData },
});
