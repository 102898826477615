import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as Icon } from '@assets/images/icons/error.svg';
import { SmallButton } from '@components/index';
import PAYMENT_TYPE from '@interfaces/cards/payment-type';
import styles from './styles';

interface IErrorDialog {
  type: PAYMENT_TYPE;
  close: () => void;
}

/**
 * ErrorDialog
 * @constructor
 */
const ErrorDialog: FC<IErrorDialog> = ({ type, close }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ ...styles.container, height: type === PAYMENT_TYPE.CREDIT_CARD ? '70%' : '370px' }}>
      <Box sx={styles.body}>
        <Typography fontSize={20} color="text.secondary" textAlign="center" marginBottom={5} mt={2}>
          {t('error')}
        </Typography>
        <Box sx={styles.icon}>
          <Icon />
        </Box>
        <Typography fontSize={20} color="action.active" textAlign="center" marginTop={6}>
          {type === PAYMENT_TYPE.CREDIT_CARD ? (
            t('errorDialog.wentWrong')
          ) : (
            <Trans
              t={t}
              i18nKey="errorDialog.paymentRejected"
              values={{
                deviceLimit: 'errorDialog.paymentRejected',
              }}
            />
          )}
        </Typography>
      </Box>

      <Box sx={styles.footerButton}>
        <SmallButton variant="outlined" color="primary" onClick={close} sx={styles.button}>
          {t('close')}
        </SmallButton>
      </Box>
    </Box>
  );
};

export default ErrorDialog;
