import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  container: {
    pt: 2,
    px: 4,
    pb: 3,
  },
  table: {
    mt: 0.1,
    overflowY: 'auto',
    maxHeight: 'calc(100% - 85px)',

    '& table td': {
      py: 1,
    },

    '& table th': {
      pl: 2,
    },
  },
};

export default styles as IStyles<typeof styles>;
