import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import {
  clearTransactionList,
  getTransactionList,
  updateTransactionListDates,
  updateTransactionListPagination,
  updateTransactionListSorting,
} from '@store/transactions/action-creators';

export const mapStateToProps = ({ transactions: { list }, accounts: { item } }: IState) =>
  ({
    dates: list.dates,
    transactions: list.result,
    pagination: list.pagination,
    isFetching: list.isFetching,
    account: item.result,
  } as const);

export const mapDispatchToProps = {
  clearTransactionList,
  getTransactionList,
  updateTransactionListPagination,
  updateTransactionListSorting,
  updateTransactionListDates,
};

export type ITransactionsModalStateToProps = ReturnType<typeof mapStateToProps>;

export type ITransactionsModalDispatchToProps = ReplacePropertiesReturnType<
  typeof mapDispatchToProps,
  void
>;
