import { Link as LinkMui } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import React, { FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { LoadingButton, LoginBanner, LoginFooter } from '@components/index';
import defaultContactUrl from '@constants/default-contact-url';
import DynamicIcon from '@helpers/dynamis-icon';
import { configs } from '@helpers/get-env';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import RoleType from '../../enums/role-type';
import RoutePath from '../../enums/route-path';
import type { ILoginDispatchToProps, ILoginStateToProps } from './index.props';
import styles from './styles';

type IProps = ILoginStateToProps & ILoginDispatchToProps;

/**
 * Login page
 * @constructor
 */
const Login: FC<IProps> = ({
  login,
  isAuth,
  error,
  isFetching,
  userRoles,
  userId,
  enableUserPortal,
  contactRedirect,
}) => {
  const { t } = useTranslation();
  const isAllowedForAdmin = isAllowedForUser([RoleType.Admin], userRoles as RoleType[]);
  const location = useLocation<any>();
  const prevUrl = location?.state?.prevUrl;

  const [domain] = document.location.hostname.split('.');

  /**
   * Submit form
   */
  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);

      login(data.get('email'), data.get('password'));
    },
    [login],
  );

  if (isAuth && domain !== configs.REACT_APP_LOGIN_SUBDOMAIN) {
    if (prevUrl) {
      return <Redirect to={prevUrl} />;
    }

    return <Redirect to={isAllowedForAdmin ? '/' : `${RoutePath.AccountsEdit}/${userId}`} />;
  }

  return (
    <Box component="main" sx={styles.wrapper}>
      <LoginBanner />
      <Container maxWidth="xs" sx={styles.container}>
        <Paper elevation={6} sx={{ maxWidth: '450px' }}>
          <Box sx={styles.box} padding={2}>
            <DynamicIcon domain={domain} height="150px" />
            <Box sx={styles.infoDescribe}>Sign in by entering the information below</Box>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={`${t('email')}`}
                name="email"
                error={Boolean(error)}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={`${t('password')}`}
                type="password"
                id="password"
                autoComplete="current-password"
                error={Boolean(error)}
                helperText={error}
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={styles.submit}
                isFetching={isFetching}>
                {t('login.signIn')}
              </LoadingButton>
              {enableUserPortal && (
                <Box sx={styles.linkWrapper}>
                  <Link to={RoutePath.Forgot}>{t('login.forgotPassword')}</Link>
                </Box>
              )}
              <Box sx={styles.infoDescribe}>
                <Trans
                  t={t}
                  i18nKey="login.description"
                  components={[
                    // eslint-disable-next-line react/jsx-key
                    <LinkMui href={contactRedirect ?? defaultContactUrl} target="_blank">
                      {t('support')}
                    </LinkMui>,
                  ]}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <LoginFooter />
      </Box>
    </Box>
  );
};

export default Login;
