import { ILocation } from '@interfaces/locations/i-location';
import { IAction } from '@interfaces/redux/i-action';
import TYPES from './action-types';

/**
 * Get locations list
 */
export const getLocationsList = (): IAction<TYPES> => ({
  type: TYPES.GET_LOCATIONS_LIST,
  payload: {},
});

/**
 * Get locations list success
 */
export const getLocationsListSuccess = (list: ILocation[]): IAction<TYPES> => ({
  type: TYPES.GET_LOCATIONS_LIST_SUCCESS,
  payload: { list },
});

/**
 * Get locations list error
 */
export const getLocationsListError = (message: string): IAction<TYPES> => ({
  type: TYPES.GET_LOCATIONS_LIST_ERROR,
  payload: { message },
});

/**
 * Clear locations list
 */
export const clearLocationsList = (): IAction<TYPES> => ({
  type: TYPES.CLEAR_LOCATIONS_LIST,
  payload: {},
});
