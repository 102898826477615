import Box from '@mui/material/Box';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/index';
import LoadingButton from '@components/loading-button';
import { IModalForward } from '@components/modal';
import ResultDialog from '@components/result-dialog';
import StripeElements from '@components/stripe-elements/index.store';
import SubscriptionInfo from '@pages/accounts/account/subscription/info/index.store';
import {
  ISubModalDispatchToProps,
  ISubModalStateToProps,
} from '@pages/accounts/account/subscription/modal/index.props';

type IProps = ISubModalStateToProps & ISubModalDispatchToProps;

export enum SubscriptionDialog {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
const SubscriptionModal: FC<IProps> = ({ subInfo, accountId, getAccount }) => {
  const modalRef = useRef<IModalForward | null>(null);
  const [dialogType, setDialogType] = useState<SubscriptionDialog>(SubscriptionDialog.INFO);
  const [isFetchingModal, setIsFetchingModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const toggleModal = useCallback(() => {
    modalRef.current?.toggleModal();
  }, []);

  const btnRenderForRole = useMemo(
    () => (
      <LoadingButton
        color="primary"
        variant="contained"
        sx={{
          fontSize: 13,
          textTransform: 'none',
          whiteSpace: 'nowrap',
          mr: '2px',
          maxHeight: '30px',
          width: { xs: '100%', sm: 'auto' },
        }}
        onClick={toggleModal}>
        {subInfo ? t('subscriptionInfo') : t('startSubscription')}
      </LoadingButton>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subInfo],
  );

  const handleCloseDialog = useCallback(() => {
    toggleModal();
    getAccount(accountId as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dialog = useMemo(() => {
    switch (dialogType) {
      case SubscriptionDialog.SUCCESS:
        return (
          <ResultDialog
            type="success"
            description={{ i18nKey: 'successDialog.createSubscription' }}
            close={handleCloseDialog}
          />
        );

      case SubscriptionDialog.ERROR:
        return (
          <ResultDialog
            type="error"
            description={{ i18nKey: 'errorDialog.wentWrong' }}
            close={handleCloseDialog}
          />
        );

      case SubscriptionDialog.INFO:
      default:
        return (
          <StripeElements
            useGlobalStripe={false}
            children={
              <SubscriptionInfo
                close={toggleModal}
                setDialogType={setDialogType}
                isFetchingModal={isFetchingModal}
                setIsFetchingModal={setIsFetchingModal}
              />
            }
          />
        );
    }
  }, [dialogType, handleCloseDialog, toggleModal, isFetchingModal]);

  return (
    <Box display="flex">
      {btnRenderForRole}
      <Modal
        ref={modalRef}
        width={{ xs: '100%', sm: 455 }}
        customStyle={{
          height: '350px',
        }}
        isLoad={isFetchingModal}
        // onClose={resetModalDialogType}
      >
        {dialog}
      </Modal>
    </Box>
  );
};

export default SubscriptionModal;
