import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { updateTransactionListDatesWithoutRefetch } from '@store/transactions/action-creators';

export const mapStateToProps = ({ transactions: { list } }: IState) =>
  ({
    dates: list?.dates,
  } as const);

export const mapDispatchToProps = {
  updateTransactionListDatesWithoutRefetch,
};

export type ICardUpdateStateToProps = ReturnType<typeof mapStateToProps>;

export type ICardUpdateDispatchToProps = ReplacePropertiesReturnType<
  typeof mapDispatchToProps,
  void
>;
