enum DEPOSITS_ACTION_TYPES {
  GET_DEPOSITS_LIST = 'GET_DEPOSITS_LIST',
  GET_DEPOSITS_LIST_SUCCESS = 'GET_DEPOSITS_LIST_SUCCESS',
  GET_DEPOSITS_LIST_ERROR = 'GET_DEPOSITS_LIST_ERROR',

  UPDATE_NEW_DEPOSIT = 'UPDATE_NEW_DEPOSIT',
  CLEAR_NEW_DEPOSIT = 'CLEAR_NEW_DEPOSIT',
}

export default DEPOSITS_ACTION_TYPES;
