import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { ReactComponent as Icon } from '@assets/images/icons/error.svg';
import styles from './styles';

interface IErrorModal {
  title?: string;
  description?: string;
}

/**
 * ErrorModal
 * @constructor
 */
const ErrorModal: FC<IErrorModal> = ({ title = 'Error', description }) => (
  <Box sx={styles.container}>
    <Box sx={styles.body}>
      <Typography fontSize={22} color="text.secondary" textAlign="center" marginBottom={3}>
        {title}
      </Typography>
      <Box sx={styles.icon} mt={5}>
        <Icon />
      </Box>
      <Typography fontSize={18} color="#BA1212" textAlign="center" marginTop={5}>
        {description}
      </Typography>
    </Box>
  </Box>
);

export default ErrorModal;
