import { IAction } from '@interfaces/redux/i-action';
import TYPES from './action-types';

/**
 * Get bonus card balance
 */
export const getBonusCardBalance = (payload: any): IAction<TYPES> => ({
  type: TYPES.GET_BONUS_CARD_BALANCE,
  payload,
});

/**
 * Get bonus card balance success
 */
export const getBonusCardBalanceSuccess = (res: any): IAction<TYPES> => ({
  type: TYPES.GET_BONUS_CARD_BALANCE_SUCCESS,
  payload: res,
});

/**
 * Get bonus card balance error
 */
export const getBonusCardBalanceError = (message: string): IAction<TYPES> => ({
  type: TYPES.GET_BONUS_CARD_BALANCE_ERROR,
  payload: { message },
});

/**
 * Use bonus card
 */
export const applyBonusCard = (payload: any): IAction<TYPES> => ({
  type: TYPES.APPLY_BONUS_CARD,
  payload,
});

/**
 * Use bonus card success
 */
export const applyBonusCardSuccess = (res: any): IAction<TYPES> => ({
  type: TYPES.APPLY_BONUS_CARD_SUCCESS,
  payload: res,
});

/**
 * Use bonus card error
 */
export const applyBonusCardError = (message: string): IAction<TYPES> => ({
  type: TYPES.APPLY_BONUS_CARD_ERROR,
  payload: { message },
});

/**
 * Get bonus card balance clear state
 */
export const bonusCardClear = (): IAction<TYPES> => ({
  type: TYPES.BONUS_CARD_CLEAR_STATE,
  payload: {},
});
