import crypto from 'crypto';

const algorithm: any = process.env.REACT_APP_ALGORITHM;
const password: any = process.env.REACT_APP_PASSWORD;

interface IEnvConfigs {
  REACT_APP_API_URL: string;
  REACT_APP_ID: string;
  REACT_APP_JWT_SECRET: string;
  REACT_APP_LOGIN_SUBDOMAIN: string;
}
const configs = process.env.REACT_APP_MODE === 'local' ? process.env : ({} as IEnvConfigs);

function decrypt(text: any) {
  const decipher = crypto.createDecipher(algorithm, password);
  let dec = decipher.update(text, 'hex', 'utf8');

  dec += decipher.final('utf8');

  return dec;
}

const getEnv = async (): Promise<boolean> => {
  const { env } = await fetch('/env').then((res) => res.json());

  Object.assign(configs, JSON.parse(decrypt(env)));

  return true;
};

export { getEnv, configs };
