import { Theme, alpha } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import IS_MOBILE from '@constants/is-mobile';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  wrapper: {
    background: ({ palette }) => (palette.mode === 'light' ? '#F5F5F5' : '#000'),
    height: '100%',
    flexDirection: { xs: 'column', md: 'row' },
    flexWrap: { xs: 'nowrap', md: 'wrap' },
  } as SxProps<Theme>,
  rightBox: () =>
    ({
      position: 'relative',
      paddingLeft: 0,
      background: ({ palette }) => (palette.mode === 'light' ? '#F5F5F5' : '#000'),
      mt: '5px',
    } as SxProps<Theme>),
  overlay: ({ breakpoints, palette }: Theme) =>
    ({
      position: 'absolute',
      zIndex: 100,
      top: -10,
      right: -10,
      bottom: -10,
      left: -10,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      backgroundColor:
        palette.mode === 'light'
          ? 'rgba(217, 217, 217, 0.35)'
          : alpha(palette.background.paper, palette.action.activatedOpacity),
      borderRadius: 1,
      cursor: 'wait',

      [breakpoints.up('md')]: {
        alignItems: 'center',
        left: 14,
      },
    } as SxProps<Theme>),
  overlayInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'background.paper',
    borderRadius: 2,
    boxShadow: 3,
    p: 4,
    color: 'text.secondary',
  },
  button: {
    mt: 2,
  },

  tableHeadSx: {
    th: {
      pt: 1,
      pb: 1,
      pl: 3,
      fontWeight: 'bold',
      borderWidth: 0,
      whiteSpace: 'nowrap',
      backgroundColor: ({ palette }) =>
        `${palette.mode === 'light' ? '#fff' : '#1F1E1E'}!important`,
      borderBottom: '1.5px solid #DDDDDD',
      color: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),

      '&:last-child': {
        textAlign: 'end',
        p: 0,
      },
    },
  } as SxProps<Theme>,

  boxCardsTable: {
    padding: IS_MOBILE ? 0 : '16px 27px 27px',
    background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#1F1E1E'),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);',
  } as SxProps<Theme>,

  gridForm: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#1F1E1E'),
    maxWidth: '468px',
    minWidth: '418px',
    height: 'calc(100% - 99px)',
    zIndex: 1,
  } as SxProps<Theme>,

  autocompleteInactiveWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    mb: 4,
  },

  inactive: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '15%',
    border: ({ palette }: any) =>
      palette.mode === 'light' ? '1px solid #DDDDDD' : '1px solid transparent',
    borderRadius: '5px',
    height: '44px',
    minWidth: '200px',
  },

  title: {
    fontSize: '14px',
    textWrap: 'nowrap',
    color: ({ palette }: any) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),

    '& p': {
      color: ({ palette }: any) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),
    },
  } as SxProps<Theme>,

  switchActive: {
    height: 33,
    '& .MuiSwitch-thumb': {
      width: 16,
      height: 16,
    },

    '& .MuiSwitch-track': {
      width: 33,
      height: 12,
    },

    '& .Mui-checked': {
      top: 1,
    },
  },
};

export default styles as IStyles<typeof styles>;
