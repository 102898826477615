import { Autocomplete, CircularProgress, Paper, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style';
import stylesTopPanel from './style-top-panel';

interface ICustomAutocomplete<T> {
  id: string;
  label?: string;
  optionValueKey: string;
  handleCustomOpen: any;
  handleCustomClose?: any;
  sxProps?: SxProps<any>;
  InputProps?: any;
  defaultValue?: T;
  size?: 'small' | 'medium';
  dataLocations?: any[];
  isBorder?: boolean;
  getIdItem?: any;
  conditionForUser?: boolean;
  searchAutocomplete?: boolean;
}

function CustomAutocomplete<T>({
  id,
  label = '',
  optionValueKey,
  handleCustomOpen,
  // eslint-disable-next-line
  handleCustomClose = () => {},
  sxProps,
  defaultValue,
  size = 'medium',
  InputProps,
  dataLocations = [],
  isBorder = true,
  getIdItem,
  // conditionForUser,
  searchAutocomplete,
}: PropsWithChildren<ICustomAutocomplete<T>>) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const [changeTextField, setChangeTextField] = useState<any>();
  const isLoading = isOpen && dataLocations.length === 0;

  const handleOpen = () => {
    handleCustomOpen();
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    handleCustomClose();
  };

  const isOptionEqualToValue = (option: T, value: T): boolean =>
    option[optionValueKey as keyof T] === value[optionValueKey as keyof T];

  const handleChangeSelect = (e: any, value: any) => {
    const info = {
      ...e,
      target: {
        ...e.target,
        name: changeTextField?.target.name,
        value: value?.id,
      },
    };

    InputProps && InputProps.onChange(info);
    getIdItem && getIdItem(value?.id);
  };

  return (
    <Autocomplete
      id={id}
      sx={{
        ...styles,
        ...(searchAutocomplete && stylesTopPanel),
        ...sxProps,
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: !isBorder ? 'none' : '',
        },
        boxShadow: 'none',
      }}
      size={size}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      isOptionEqualToValue={(option, value) => isOptionEqualToValue(option, value)}
      getOptionLabel={(option) => option[optionValueKey as keyof T] as unknown as string}
      options={[{ id: 1, name: 'Not Set' }, ...dataLocations]}
      loading={isLoading}
      onChange={handleChangeSelect}
      defaultValue={defaultValue ? defaultValue : { id: 1, name: 'Not Set' }}
      disabled={dataLocations.length === 0}
      renderInput={(params) => (
        <Paper
          sx={{
            borderRadius: searchAutocomplete ? '12px !important' : '4px !important',
            background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#1F1E1E'),
            ...(!searchAutocomplete && { boxShadow: 'none' }),
          }}>
          <TextField
            {...params}
            id={id}
            name={id}
            label={label}
            variant="outlined"
            size={size}
            onClick={(e: any) => setChangeTextField(e)}
            onChange={(e: any) => setChangeTextField(e)}
            placeholder={`${t('customAutocomplete.placeholder_locations')}`}
            sx={{
              '& fieldset': {
                ...(searchAutocomplete && { border: 'none' }),
              },
            }}
            InputProps={{
              ...InputProps,
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </Paper>
      )}
    />
  );
}

export default CustomAutocomplete;
