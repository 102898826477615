import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { ReactComponent as Icon } from '@assets/images/icons/success.svg';
import styles from './styles';

interface ISuccessModal {
  title?: string;
  description?: string;
}

/**
 * SuccessModal
 * @constructor
 */
const SuccessModal: FC<ISuccessModal> = ({ title = 'Success', description = 'Success' }) => (
  <Box sx={styles.container}>
    <Box sx={styles.body}>
      <Typography fontSize={22} color="text.secondary" textAlign="center" marginBottom={4}>
        {title}
      </Typography>
      <Box sx={styles.icon}>
        <Icon />
      </Box>
      <Typography fontSize={18} color="#787878" textAlign="center" marginTop={4.5}>
        {description}
      </Typography>
    </Box>
  </Box>
);

export default SuccessModal;
