import { Component, ErrorInfo, ReactNode } from 'react';

class ErrorBoundary extends Component {
  /**
   * Handle app errors and send to Sentry
   */
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error, errorInfo);
  }

  render(): ReactNode {
    const { children } = this.props;

    return children;
  }
}

export default ErrorBoundary;
