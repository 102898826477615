import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { FC } from 'react';
import CustomAutocomplete from '@components/custom-autocomplete';
import { TableSearch } from '@components/index';
import styles from './styles';

interface ITableTopPane {
  searchValue: string;
  updateSearch: (value: string) => void;
  isShowLocation?: boolean;
  handleOpenLocation?: any;
  dataLocations?: any[];
  handleGetIdLocation?: any;
}

/**
 * TableTopPane
 * @constructor
 */
const TableTopPane: FC<ITableTopPane> = ({
  searchValue,
  updateSearch,
  isShowLocation = false,
  children,
  handleOpenLocation,
  dataLocations = [],
  handleGetIdLocation,
}) => (
  <Box sx={styles.row}>
    <Grid
      container
      justifyContent={children || isShowLocation ? 'space-between' : 'flex-start'}
      alignItems="center">
      <Grid item md={4} xs={12} order={{ xs: 2, md: 1 }} sx={styles.search}>
        <TableSearch value={searchValue} onChange={updateSearch} />
      </Grid>
      {isShowLocation && dataLocations.length > 1 && (
        <Grid
          item
          md={2}
          xs={12}
          order={{ xs: 2, md: 1 }}
          marginTop={{ xs: 2, md: 0 }}
          marginLeft={{ md: 2 }}>
          <CustomAutocomplete
            id="locations"
            optionValueKey="name"
            handleCustomOpen={handleOpenLocation}
            dataLocations={dataLocations}
            isBorder={false}
            getIdItem={handleGetIdLocation}
            searchAutocomplete
          />
        </Grid>
      )}
      {children && (
        <Grid
          item
          xs={1}
          md={1}
          ml={1}
          order={{ xs: 1, md: 2 }}
          marginLeft={{ xs: 'auto' }}
          minWidth={70}
          maxHeight={56}>
          {children}
        </Grid>
      )}
    </Grid>
  </Box>
);

export default TableTopPane;
