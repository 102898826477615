import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as Icon } from '@assets/images/icons/success.svg';
import { SmallButton, Timer } from '@components/index';
import PAYMENT_TYPE from '@interfaces/cards/payment-type';
import styles from './styles';

interface ISuccessDialog {
  type: PAYMENT_TYPE;
  close: () => void;
}

/**
 * SuccessDialog
 * @constructor
 */
const SuccessDialog: FC<ISuccessDialog> = ({ type, close }) => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.body}>
        <Typography fontSize={20} color="text.secondary" textAlign="center" marginBottom={5} mt={2}>
          {t('success')}
        </Typography>
        <Box sx={styles.icon}>
          <Icon />
        </Box>
        <Typography fontSize={20} color="action.active" textAlign="center" marginTop={6}>
          {type === PAYMENT_TYPE.CREDIT_CARD ? (
            t('successDialog.paymentRequestSuccess')
          ) : (
            <Trans
              t={t}
              i18nKey="successDialog.paymentSuccess"
              values={{
                deviceLimit: 'successDialog.paymentSuccess',
              }}
            />
          )}
        </Typography>
      </Box>

      <Box sx={styles.footerButton}>
        <SmallButton
          variant="outlined"
          color="primary"
          onClick={close}
          sx={styles.button}
          startIcon={<Timer duration={5000} onFinish={close} />}>
          {t('close')}
        </SmallButton>
      </Box>
    </Box>
  );
};

export default SuccessDialog;
