import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import {
  applyBonusCard,
  bonusCardClear,
  getBonusCardBalance,
} from '@store/bonus-cards/action-creators';

export const mapStateToProps = (state: IState) =>
  ({
    balance: state.bonusCards.balance,
    applyCard: state.bonusCards.applyCard,
    isShowInactive: state.cards.isShowInactive,
  } as const);

export const mapDispatchToProps = {
  applyBonusCard,
  getBonusCardBalance,
  bonusCardClear,
};

export type ICardTransferStateToProps = ReturnType<typeof mapStateToProps>;

export type ICardTransferDispatchToProps = ReplacePropertiesReturnType<
  typeof mapDispatchToProps,
  void
>;
