import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  bottomInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    lineHeight: '16px',
    zIndex: 1,
    background: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : '#1F1E1E'),
    height: '30px',
    alignItems: 'center',
    padding: '5px',
    color: 'white',
  } as SxProps<Theme>,
};

export default styles as IStyles<typeof styles>;
