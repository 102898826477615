/* eslint-disable @typescript-eslint/default-param-last */
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import { ISubPrice } from '@interfaces/sub-price/i-sub-price';
import TYPES from './action-types';

export interface ISubscriptionsState {
  subscriptionPrices: IReducerState<ISubPrice[]>;
}

const initState: ISubscriptionsState = {
  subscriptionPrices: {
    result: [],
    error: null,
    isFetching: false,
  },
};

/**
 * Subscription reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>) => {
  switch (type) {
    case TYPES.GET_SUBSCRIPTION_PRICES:
      return {
        ...state,
        subscriptionPrices: { ...initState.subscriptionPrices, isFetching: true },
      };

    case TYPES.GET_SUBSCRIPTION_PRICES_SUCCESS:
      return { ...state, subscriptionPrices: { result: payload, isFetching: false, error: null } };

    case TYPES.GET_SUBSCRIPTION_PRICES_ERROR:
      return {
        ...state,
        subscriptionPrices: { ...initState.subscriptionPrices, error: payload.message },
      };

    default:
      return state;
  }
};
