import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  container: ({ breakpoints }: Theme) =>
    ({
      mb: -4,
      boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.25)',

      [breakpoints.up('md')]: {
        mb: 0,
      },
    } as SxProps<Theme>),
};

export default styles as IStyles<typeof styles>;
