import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import IS_MOBILE from '@constants/is-mobile';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    flexGrow: 1,
    height: IS_MOBILE ? window.innerHeight : '100vh',
    overflow: 'auto',
    background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#1F1E1E'),
  } as SxProps<Theme>,

  container: {
    display: 'flex',
    width: '68%',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'center',
  } as SxProps<Theme>,

  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#000000'),

    '& svg path[data-fill="theme"]': {
      fill: ({ palette }) => palette.primary.main,
    },
  } as SxProps<Theme>,

  company: (({ breakpoints }) => ({
    ml: 1,
    [breakpoints.only('xs')]: {
      svg: {
        maxWidth: 160,
      },
    },
  })) as SxProps<Theme>,
  submit: {
    mt: 3,
    mb: 2,
  },
  linkWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    a: {
      color: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : '#83BCEA'),
      '&:active': {
        color: ({ palette }) => (palette.mode === 'light' ? '#000' : '#FFF'),
      } as SxProps<Theme>,
    } as SxProps<Theme>,
  },

  infoDescribe: {
    fontSize: '14px',
    width: '100%',
    marginTop: '1rem',
    color: '#606367',
    fontWeight: '400',
  } as SxProps<Theme>,
};

export default styles as IStyles<typeof styles>;
