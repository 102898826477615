/* eslint-disable react-hooks/exhaustive-deps */
import { SnackbarProvider } from 'notistack';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, RouteProps, Redirect, useHistory } from 'react-router-dom';
import { ErrorBoundary } from '@components/index';
import Loader from '@components/main-loader/loader';
import { ColorModeProvider } from '@context/color-mode-context';
import funcUpdateDomain from '@helpers/domain-update';
import { configs, getEnv } from '@helpers/get-env';
import { SnackbarUtilsConfigurator } from '@services/notistack-service';
import RoutePath from './enums/route-path';
import SiteRoutes from './routes';

/**
 * Main application container
 */
const App: FC = () => {
  const [isEnvCreated, setIsEnvCreated] = useState(false);
  const isAuth = useSelector((state: any) => state.tenant.isFetching);
  const isClaims = useSelector((state: any) => Boolean(state.tenant.claims));
  const isSettings = useSelector((state: any) => state.settings.isFetching);
  const [domain] = document.location.hostname.split('.');
  const history = useHistory();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    funcUpdateDomain();

    getEnv()
      .then((res) => {
        setIsEnvCreated(res);
        console.log(isEnvCreated);
      })
      .catch((err) => console.log(err));
  }, []);

  const renderRedirect = useMemo(() => {
    if (domain === configs.REACT_APP_LOGIN_SUBDOMAIN) {
      history.push(RoutePath.Login);
    }

    if (isClaims) {
      return <Redirect from="/" to={RoutePath.Accounts} />;
    }

    return <Redirect from="/" to={RoutePath.Login} />;
  }, [domain, isClaims]);

  return (
    <ColorModeProvider>
      <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <ErrorBoundary>
          {isAuth || isSettings ? (
            <Loader />
          ) : (
            SiteRoutes.routes.length > 0 && (
              <Switch>
                {SiteRoutes.routes.map((item: RouteProps) => (
                  <Route
                    key={item?.path?.toString()}
                    exact={item?.exact ?? true}
                    path={item.path}
                    component={item.component}
                  />
                ))}
                {renderRedirect}
              </Switch>
            )
          )}
          <SnackbarUtilsConfigurator />
        </ErrorBoundary>
      </SnackbarProvider>
    </ColorModeProvider>
  );
};

export default App;
