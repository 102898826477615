import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    label: {
      '&[data-shrink="false"]': {
        transform: 'translate(14px,10px) scale(1)',
      },
    },
    '& .MuiFormHelperText-contained': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      transform: 'translate(0, 100%)',
    },
    width: '100%',
    maxWidth: '375px',
    marginTop: '17px',
  },
  input: {
    input: { padding: 1, width: '100%' },
    pr: '7px',
  },
  button: {
    whiteSpace: 'nowrap',
    marginTop: 0,
    padding: '9.5px 37px',
    my: '7px',
  },
  balance: {
    margin: '20px 0',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
  },
  successfulBox: {
    margin: '25px',
    p: {
      p: {
        textAlign: 'center',
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
  },
  error: {
    marginTop: '2px',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#FF2929',
    textAlign: 'center',
    height: '19px',
  },
};

export default styles as IStyles<typeof styles>;
