import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
  button: {
    width: '110px',
    height: '31px',
    mt: 0,
  },
};

export default styles as IStyles<typeof styles>;
