import { IStyles } from '@interfaces/styles/i-styles';

const initStyles = {
  display: 'flex',
  alignItems: 'center',
  minHeight: 282, // @todo mocked height
};

const styles = {
  box: {
    ...initStyles,
    py: 6,
    flexDirection: 'column',
  },
  loaderBox: {
    ...initStyles,
    justifyContent: 'center',
    width: '100%',
  },
};

export default styles as IStyles<typeof styles>;
