/* eslint-disable @typescript-eslint/default-param-last */
import { ICard } from '@interfaces/cards/i-card';
import PAYMENT_TYPE from '@interfaces/cards/payment-type';
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import { ITableList } from '@interfaces/redux/i-table-list';
import TYPES from './action-types';

export interface ICardsState {
  isShowInactive: boolean;
  paymentType: PAYMENT_TYPE;
  list: IReducerState<ICard[]> & ITableList;
  update: IReducerState<string | undefined>;
  delete: IReducerState<string | undefined>;
  add: IReducerState<string | undefined>;
  nayaxSync: IReducerState<undefined>;
  transfer: IReducerState<undefined>;
}

const initState: ICardsState = {
  isShowInactive: true,
  paymentType: PAYMENT_TYPE.AT_COUNTER,
  list: {
    result: [],
    isFetching: false,
    error: null,
    searchValue: '',
    pagination: { total: 0, page: 1, size: 100 },
    sorting: {},
  },
  update: {
    result: undefined, // loadingRowId
    isFetching: false,
    error: null,
  },
  delete: {
    result: undefined, // loadingRowId
    isFetching: false,
    error: null,
  },
  add: {
    result: undefined, // id
    isFetching: false,
    error: null,
  },
  nayaxSync: {
    result: undefined,
    isFetching: false,
    error: null,
  },
  transfer: {
    result: undefined,
    isFetching: false,
    error: null,
  },
};

/**
 * Cards reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>) => {
  switch (type) {
    // list
    case TYPES.GET_CARDS_LIST:
      return { ...state, list: { ...state.list, error: null, isFetching: true } };

    case TYPES.GET_CARDS_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          result: payload.list,
          pagination: payload.pagination,
          isFetching: false,
          error: null,
        },
      };

    case TYPES.GET_CARDS_LIST_ERROR:
      return { ...state, list: { ...state.list, isFetching: false, error: payload.message } };

    case TYPES.CLEAR_CARDS_LIST:
      return { ...state, list: { ...initState.list } };

    case TYPES.UPDATE_CARDS_LIST_PAGINATION:
      return {
        ...state,
        list: {
          ...state.list,
          pagination: {
            ...state.list.pagination,
            ...payload.pagination,
            ...(payload.pagination.size &&
              payload.pagination.size !== state.list.pagination.size && { page: 1 }),
          },
        },
      };

    case TYPES.UPDATE_CARDS_SEARCH:
      return { ...state, list: { ...state.list, searchValue: payload.value } };

    case TYPES.UPDATE_CARDS_SORTING:
      return { ...state, list: { ...state.list, sorting: { [payload.name]: payload.desc } } };

    // update
    case TYPES.UPDATE_CARD:
      return {
        ...state,
        update: { ...state.update, error: null, isFetching: true, result: payload.id },
      };

    case TYPES.UPDATE_CARD_SUCCESS:
      return { ...state, update: { ...initState.update } };

    case TYPES.UPDATE_CARD_ERROR:
      return {
        ...state,
        update: { ...state.update, isFetching: false, error: payload.message, result: undefined },
      };

    // delete
    case TYPES.DELETE_CARD:
      return {
        ...state,
        delete: { ...state.delete, error: null, isFetching: true, result: payload.id },
      };

    case TYPES.DELETE_CARD_SUCCESS:
      return { ...state, delete: { ...initState.delete } };

    case TYPES.DELETE_CARD_ERROR:
      return {
        ...state,
        delete: { ...state.delete, isFetching: false, error: payload.message, result: undefined },
      };

    // add
    case TYPES.ADD_CARD:
      return {
        ...state,
        add: { ...state.add, error: null, isFetching: true, result: payload.id },
      };

    case TYPES.ADD_CARDS:
      return { ...state, add: { ...state.add, error: null, isFetching: true } };

    case TYPES.ADD_CARD_SUCCESS:
    case TYPES.ADD_CARDS_SUCCESS:
      return { ...state, add: { ...initState.add } };

    case TYPES.ADD_CARD_ERROR:
    case TYPES.ADD_CARDS_ERROR:
      return {
        ...state,
        add: { ...state.add, isFetching: false, error: payload.message, result: undefined },
      };

    // sync
    case TYPES.NAYAX_CARDS_SYNC:
      return {
        ...state,
        nayaxSync: { ...state.nayaxSync, error: null, isFetching: true },
      };

    case TYPES.NAYAX_CARDS_SYNC_SUCCESS:
      return { ...state, nayaxSync: { ...initState.nayaxSync } };

    case TYPES.NAYAX_CARDS_SYNC_ERROR:
      return {
        ...state,
        nayaxSync: { ...state.nayaxSync, isFetching: false, error: payload.message },
      };

    // transfer card
    case TYPES.TRANSFER_ANOTHER_CARD:
      return {
        ...state,
        transfer: { ...state.transfer, isFetching: true },
      };

    case TYPES.TRANSFER_ANOTHER_CARD_RESPONSE:
      return {
        ...state,
        transfer: {
          ...state.transfer,
          isFetching: null,
          error: payload.type === TYPES.TRANSFER_CARD_ERROR,
        },
      };

    case TYPES.TOGGLE_SHOW_INACTIVE:
      return { ...state, isShowInactive: !state.isShowInactive };

    case TYPES.SET_PAYMENT_TYPE_SUCCESS:
      return { ...state, paymentType: payload.type };

    default:
      return state;
  }
};
