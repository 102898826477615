const initWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
};

const styles = {
  button: {
    margin: 0,
    width: '100%',
  },
  formWrapper: {
    ...initWrapperStyles,
    justifyContent: 'space-between',
  },
  buttonsWrapper: {
    ...initWrapperStyles,
    justifyContent: 'end',
    width: '50%',
  },
};

export default styles;
