import Grid from '@mui/material/Grid';
import React, { FC } from 'react';
import { CloudCover } from '@components/index';
import styles from './styles';

/**
 * Administration page GridItem
 * @constructor
 */
const GridItem: FC = ({ children }) => (
  <Grid item xs={12} sm={12} md={5.8} lg={5.885} xl={5.885} sx={styles.container}>
    <CloudCover>{children}</CloudCover>
  </Grid>
);

export default GridItem;
