import { Theme } from '@mui/material/styles';
import React from 'react';

const styles = {
  textarea: ({ palette, custom }: Theme) =>
    ({
      padding: 10,
      width: '100%',
      border: 'unset',
      resize: 'none',
      outline: 'unset',
      backgroundColor: palette.background.default,
      color: custom.simple,
      borderRadius: 0,
    } as React.CSSProperties),
};

export default styles;
