import Box from '@mui/material/Box';
import { GridProps } from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { FC, ReactNode } from 'react';
import { ISvg } from '@interfaces/components/i-svg';
import styles from './styles';

export interface IBlockItem extends GridProps {
  Icon?: ISvg;
  value?: ReactNode;
  label?: string;
}

/**
 * BlockItem
 * @constructor
 */
const BlockItem: FC<IBlockItem> = ({ value, label, children }) => (
  <Box sx={styles.wrapper}>
    {value && label ? (
      <Box sx={styles.box}>
        <Typography sx={styles.label} variant="caption" noWrap>
          {label}
        </Typography>
        <Typography sx={styles.value} variant="caption" noWrap>
          {value}
        </Typography>
      </Box>
    ) : (
      children
    )}
  </Box>
);

export default BlockItem;
