import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import { persistStore } from 'redux-persist';
import type { Persistor } from 'redux-persist/es/types';
import createSagaMiddleware from 'redux-saga';
import type { SagaMiddleware } from 'redux-saga';
import TENANT_ACTION_TYPES from '@store/tenant/action-types';
import allReducers from './reducers';
import SagaManager from './saga-manager';

const reduxDevTools = (window as any)?.__REDUX_DEVTOOLS_EXTENSION__?.() ?? compose;

interface IStore {
  store: Store;
  persistor: Persistor;
}

const configureStore = (): IStore => {
  const sagaMiddleware: SagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];

  const appReducer = combineReducers({ ...allReducers });
  const rootReducer = (state: any, action: any) => {
    // set initial state to all reducers when logout
    if (action.type === TENANT_ACTION_TYPES.LOGOUT) {
      const { settings, forgot } = state;

      state = { settings, forgot };
    }

    return appReducer(state, action);
  };

  const store: Store = createStore(
    rootReducer,
    compose(applyMiddleware(...middlewares), reduxDevTools),
  );
  const persistor = persistStore(store, null);

  sagaMiddleware.run(SagaManager);

  return { store, persistor };
};

export default configureStore;
